import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {Done} from '@material-ui/icons';
import {AlunoDto} from '../../../api/types';
import {handleErr} from '../handleErr';
import {DEFAULT_TIMEOUT} from '../../../base/constants';

export const studentUpdate = (id: string, alunoDto: AlunoDto): ThunkAction<Promise<AlunoDto | undefined>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<AlunoDto | undefined> => {
        dispatch(notify(
            'A atualizar informação. Por favor aguarde...',
            'primary',
            undefined,
            'bc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        let result: AlunoDto | undefined;
        try {
            result = await gesAvaliaApi.studentUpdate(id, alunoDto);
            dispatch(dismiss());
            dispatch(notify(
                'Aluno atualizado com sucesso!',
                'success',
                Done,
                'tc',
                true,
            ));
            setTimeout(() => dispatch(dismiss()), DEFAULT_TIMEOUT);
        } catch (err) {
            dispatch(handleErr(err));
        }
        return result;
    };
};
