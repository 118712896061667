import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const switchStyle: Styles<Theme, Record<string, unknown>, string> = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};
