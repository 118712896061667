import {ChangeEvent, ReactElement} from 'react';
import {TextField} from '@material-ui/core';
import {DataTypeProvider} from '@devexpress/dx-react-grid';

export const NumberEditor = (props: DataTypeProvider.ValueEditorProps): ReactElement =>
    <TextField
        type={'number'}
        onChange={(event: ChangeEvent<{ name?: string; value: unknown }>): void => props.onValueChange(event.target.value)}
        style={{width: '100%'}}
        value={props.value ?? ''}
    />
;
