import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {ParametroDto} from '../../../../../api/types';
import {Profile} from '../Profile';

class CreateContainer extends Component<BaseProps> {
    public render(): ReactNode {
        return (
            <Profile
                title={'Registar novo parâmetro'}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | number | Array<string>>>): Promise<void> => {
        const parametroDto: ParametroDto = extractObjectFromResult<string | boolean | number | Array<string>, ParametroDto>(result);
        parametroDto.percentagem = parseFloat(parametroDto.percentagem?.toString() ?? '0');

        if (this.props.session &&
            await this.props.parameter?.create?.(parametroDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(CreateContainer);
