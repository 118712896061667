import {Route} from '../types';
import {EmojiPeopleOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {Create, Edit, List, Student} from '../../components/pages/Student';
import {ReactElement} from 'react';
import {StudentPaths} from './studentPaths';

export const studentPaths: Array<Route<BaseProps>> = [
    {
        key: StudentPaths.HOME,
        name: 'Alunos',
        path: '/students',
        icon: EmojiPeopleOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Student/>,
    },
    {
        key: StudentPaths.LIST,
        parent: StudentPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: StudentPaths.CREATE,
        parent: StudentPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: StudentPaths.EDIT,
        parent: StudentPaths.HOME,
        path: '/edit/:studentId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

