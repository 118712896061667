import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {StudentEvaluationsPaths} from './studentEvaluationsPaths';
import {Manage} from '../../components/pages/StudentEvaluations';

export const studentEvaluationsPaths: Array<Route<BaseProps>> = [
    {
        key: StudentEvaluationsPaths.MANAGE,
        parent: StudentEvaluationsPaths.HOME,
        path: '/manage',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Manage,
    },
];

