import {FormItem} from '../../../../organisms';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome da turma',
        helperText: 'Introduza o nome da turma',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 9,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 3,
    },
    {
        type: 'select',
        id: 'ano',
        label: 'Ano escolar',
        helperText: 'Selecione o ano escolar',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 3,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'anoLetivo',
        label: 'Ano letivo',
        helperText: 'Selecione o ano letivo',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 3,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'escola',
        label: 'Escola',
        helperText: 'Selecione a escola',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 3,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'shuttle',
        id: 'disciplinas',
        label: 'Disciplinas',
        helperText: 'Selecione as disciplinas associadas à turma',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        gridWidth: 12,
    },
    {
        type: 'shuttle',
        id: 'professores',
        label: 'Professores',
        helperText: 'Selecione os professores associados à turma',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        gridWidth: 12,
    },
];

