import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const tableListStyle: Styles<Theme, Record<string, unknown>, string> = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: 300,
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
    root: {
        '&': {
            backgroundColor: '#FFFFFF',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '&, & fieldset': {
            borderColor: '#999',
            color: '#999',
        },
        '&:hover, &:focus, &$focused, &:hover fieldset, &:focus fieldset, &$focused fieldset': {
            borderColor: '#666 !important',
            color: '#666',
        },
    },
    focused: {},
};
