import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../base';
import {FormBuilder, FormItem, Item} from '../../organisms/Builder';
import {resetRequestFormDefinition} from './reset-request.form.definition';
import {RotateLeftOutlined} from '@material-ui/icons';
import _ from 'lodash';
import {ResetDto} from '../../../api/types';
import {generateLink} from '../../../router';
import {SignInPaths} from '../../../router/paths/signInPaths';
import {ResetPaths} from '../../../router/paths/resetPaths';

class ResetRequestContainer extends Component<BaseProps> {
    private _formDefinition: Array<FormItem> = _.cloneDeep(resetRequestFormDefinition);

    public render(): ReactNode {
        return (
            <FormBuilder
                title={'Recuperar acesso'}
                autoComplete={'off'}
                onSubmitButtonIcon={<RotateLeftOutlined/>}
                items={this._formDefinition}
                onSubmit={this._onSubmit}
                onSubmitButtonText={'Solicitar código'}
                onCancel={this._onCancel}
                onCancelButtonText={'Cancelar'}
            />
        );
    }

    private _onSubmit = async (result: Array<Item>): Promise<void> => {
        const resetDto: ResetDto = extractObjectFromResult<string, ResetDto>(result);
        await this.props.reset?.request?.(resetDto);
        this.props.history.push(generateLink(ResetPaths.CONFIRM));
    };

    private _onCancel = (): void => {
        this.props.history.push(generateLink(SignInPaths.HOME));
    };
}

export default withAll(ResetRequestContainer);

