import {IconButton, InputAdornment, TextField, Tooltip} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {ChangeEvent, Component, ReactElement} from 'react';
import {ItemProps, NumberState} from '../types';

class Number<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends Component<ItemProps<T>, NumberState> {
    public shouldComponentUpdate(nextProps: Readonly<ItemProps<T>>, nextState: Readonly<NumberState>): boolean {
        return nextProps.item.state !== this.props.item.state
            || nextState !== this.state;
    }

    public render(): ReactElement {
        return (
            <TextField
                id={this.props.item.id}
                inputRef={this.props.item.innerRef}
                label={this.props.item.label}
                disabled={this.props.item.disabled}
                required={this.props.item.required}
                margin={this.props.item.margin}
                variant={this.props.item.variant}
                type={'number'}
                fullWidth={this.props.item.fullWidth}
                helperText={this.props.item.helperText}
                value={this.props.item.state ?? 0}
                placeholder={this.props.item.placeholderText}
                aria-invalid={this.props.item.ariaInvalid}
                InputLabelProps={{
                    shrink: this.props.item.shrink,
                }}
                InputProps={{
                    endAdornment: this.props.item.informationalText ?
                        <InputAdornment position={'end'}>
                            <Tooltip title={this.props.item.informationalText}>
                                <IconButton style={{padding: 2, marginRight: -2}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                        : undefined,
                }}
                onChange={this._onChangeHandler}
            />
        );
    }

    private _onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | undefined => {
        this.setState({
            value: parseInt(event.target.value, 10),
        });
        return this.props.onChange(this.props.item.id, parseInt(event.target.value, 10) as T);
    };
}

export default Number;
