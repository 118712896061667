import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {handleErr} from '../handleErr';

export const userResendConfirmationEmail = (id: string): ThunkAction<Promise<void>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<void> => {
        dispatch(notify(
            'A realizar operação. Por favor aguarde...',
            'primary',
            undefined,
            'bc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        try {
            await gesAvaliaApi.userResendConfirmationEmail(id);
            dispatch(dismiss());
        } catch (err) {
            dispatch(handleErr(err));
        }
    };
};
