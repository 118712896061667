import {
    dangerCardHeader,
    grayColor,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    successCardHeader,
    warningCardHeader,
} from '../material-dashboard-react';
import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const cardIconStyle: Styles<Theme, Record<string, unknown>, string> = {
    cardIcon: {
        '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader': {
            borderRadius: '3px',
            backgroundColor: grayColor[0],
            padding: '15px',
            marginTop: '-20px',
            marginRight: '15px',
            float: 'left',
        },
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
};
