import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {EscolaDto} from '../../../../../api/types';
import {routes} from '../../../../../api/constants';
import {withStore} from '../../../../../redux';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | number | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | boolean | number | Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const escolaDtos: Array<EscolaDto> = await props.school?.list?.() ?? [];

            const schoolOptions: Array<SelectItem> = escolaDtos.map(escola => {
                return {
                    key: `${routes.schools}/${escola.id ?? 0}`,
                    value: escola.nome ?? '',
                };
            });

            setSelectOptions([
                {
                    id: 'escola',
                    options: schoolOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.studentProfile]);


    let _formDefinition: Array<FormItem<string | boolean | number | Array<string>>> = mapObjectToForm<string | boolean | number | Array<string>, Record<string, string | boolean | number | Array<string>>>(props.studentProfile ?? {}, _.cloneDeep(profileFormDefinition));

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<string | boolean | number | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

