import {Column} from '../types';
import {isSelectable, Selectable} from '../selectors';

const isEmpty = (cell: string | number | boolean | Record<string, unknown> | null | Selectable): boolean => {
    // eslint-disable-next-line no-extra-parens
    return !cell || (isSelectable(cell) && (cell.key === '' && cell.value === '')) || (typeof cell === 'object' && Object.keys(cell).length === 0) || cell === '';
};

export const validateExistingRows = (rows: { [key: number]: { [key: string]: string | number | boolean | Record<string, unknown> | null | Selectable } }, columns: Array<Column>): { [key: number]: boolean } => Object.entries(rows).reduce(
    (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columns.some(
            column => column.required && !!row[column.name] && isEmpty(row[column.name]),
        ),
    }), {},
);

export const validateNewRows = (rows: { [key: number]: { [key: string]: string | number | boolean | Record<string, unknown> | null | Selectable } }, columns: Array<Column>): { [key: number]: boolean } => Object.entries(rows).reduce(
    (acc, [rowId, row]) => ({
        ...acc,
        [rowId]: columns.some(
            column => column.required && isEmpty(row[column.name]),
        ),
    }), {},
);
