import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, mapObjectToForm, objectDiff, withAll} from '../../../base';
import {FormBuilder, FormItem, Item} from '../../organisms/Builder';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {UserDto} from '../../../api/types';

class ProfileContainer extends Component<BaseProps> {
    private _formDefinition: Array<FormItem> = mapObjectToForm(this.props.session?.user ?? {}, _.cloneDeep(profileFormDefinition));

    public render(): ReactNode {
        return (
            <FormBuilder
                title={'Edite o seu perfil'}
                autoComplete={'on'}
                onSubmit={this._onSubmit}
                onSubmitButtonText={'Guardar'}
                onSubmitButtonIcon={<Save/>}
                onCancelButtonText={'Cancelar'}
                onCancelButtonIcon={<Cancel/>}
                onCancel={this._onCancel}
                items={this._formDefinition}
            />
        );
    }

    private _onSubmit = async (result: Array<Item>): Promise<void> => {
        const userDto: UserDto = extractObjectFromResult<string | boolean | Array<string> | null, UserDto>(result);
        const userUpdateDto: UserDto = this.props.session?.user ? objectDiff<string | boolean | Array<string> | null, UserDto, UserDto>(this.props.session?.user, userDto) : userDto;

        if (
            userUpdateDto.newPassword &&
            userUpdateDto.password &&
            this.props.session?.user.id &&
            await this.props.user?.update?.(this.props.session.user.id, userUpdateDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(ProfileContainer);

