import {Route} from '../types';
import {ClassOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {Class, Create, Edit, List} from '../../components/pages/Class';
import {ClassStudents} from '../../components/pages/ClassStudents';
import {ReactElement} from 'react';
import {ClassPaths} from './classPaths';
import {ClassStudentsPaths} from './classStudentsPaths';
import {ClassParametersPaths} from './classParametersPaths';
import {ClassParameters} from '../../components/pages/ClassParameters';

export const classPaths: Array<Route<BaseProps>> = [
    {
        key: ClassPaths.HOME,
        name: 'Turmas',
        path: '/class',
        icon: ClassOutlined,
        role: Roles.ROLE_USER,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Class/>,
    },
    {
        key: ClassPaths.LIST,
        parent: ClassPaths.HOME,
        path: '/',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: ClassPaths.CREATE,
        parent: ClassPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: ClassPaths.EDIT,
        parent: ClassPaths.HOME,
        path: '/edit/:classId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
    {
        key: ClassStudentsPaths.HOME,
        parent: ClassPaths.HOME,
        path: '/view/:classId/students',
        role: Roles.ROLE_USER,
        exact: false,
        hidden: true,
        component: (): ReactElement => <ClassStudents/>,
    },
    {
        key: ClassParametersPaths.HOME,
        parent: ClassPaths.HOME,
        path: '/view/:classId/parameters',
        role: Roles.ROLE_USER,
        exact: false,
        hidden: true,
        component: (): ReactElement => <ClassParameters/>,
    },
];

