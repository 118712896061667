import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {Profile} from '../Profile';
import {LockOption} from '../../../../../api/enums';

class SetContainer extends Component<BaseProps> {
    public render(): ReactNode {
        return (
            <Profile
                title={'Bloquear notas para o período indicado'}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item>): Promise<void> => {
        const lockDetails: { startDate: string; endDate: string; lockOption: string } = extractObjectFromResult<string, { startDate: string; endDate: string; lockOption: string }>(result);

        if (this.props.session &&
            await this.props.lock?.evaluations?.(lockDetails.startDate, lockDetails.endDate, LockOption[lockDetails.lockOption as keyof typeof LockOption])
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(SetContainer);
