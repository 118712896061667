import {FormItem} from '../../organisms/Builder';

export const signInFormDefinition: Array<FormItem> = [
    {
        type: 'text',
        id: 'username',
        label: 'Utilizador',
        state: '',
        helperText: 'Introduza o seu nome de utilizador',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
    {
        type: 'simple-password',
        id: 'password',
        label: 'Palavra-passe',
        state: '',
        helperText: 'Introduza a sua palavra passe',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
];

