import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {EditOutlined} from '@material-ui/icons';
import {ClassParametersPaths} from '../../../../../router/paths/classParametersPaths';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['nome', 'percentagem', 'grupoDisciplinar'];

    public state: Readonly<ListState> = {
        listHeader: ['NOME', 'PERCENTAGEM', 'GRUPO'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setClassTeacherParameterList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.parameters !== this.state.parameters) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={false}
                dialogTitle={''}
                dialogContent={''}
                tableHeaderColor={'primary'}
                title={'Lista de parâmetros da turma'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onReturn={this._onReturn}
                onActionMessage={'Editar parâmetros'}
                onActionIcon={<EditOutlined/>}
                onAction={this._manage}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setClassTeacherParameterList = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const result = await Promise.all([
                this.props.class?.teacherParameterList?.(this.props.match.params.classId),
                this.props.group?.list?.(),
            ]);

            this.setState({
                parameters: result[0]?.map(v => {
                    v.grupoDisciplinar = result[1]?.find(g => g.id && v.grupoDisciplinar?.includes(g.id))?.numero?.toString() ?? 'S/G';
                    return v;
                }) ?? [],
            });
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.parameters?.map(value => {
            const data: { [key: string]: string | number | boolean } = value as { [key: string]: number | string | boolean };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });
            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _manage = (): void => {
        this.props.history.push(generateLink(ClassParametersPaths.MANAGE, {classId: this.props.match.params.classId}));
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };

    private _onReturn = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(ListContainer);
