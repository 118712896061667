import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const searchInputStyle: Styles<Theme, Record<string, unknown>, string> = {
    root: {
        '&': {
            backgroundColor: '#FFFFFF',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '&, & fieldset': {
            borderColor: '#999',
            color: '#999',
        },
        '&:hover, &:focus, &$focused, &:hover fieldset, &:focus fieldset, &$focused fieldset': {
            borderColor: '#666 !important',
            color: '#666',
        },
    },
    focused: {},
};
