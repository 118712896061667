import {ReactElement} from 'react';
import {DataTypeProvider, DataTypeProviderProps} from '@devexpress/dx-react-grid';
import {NumberFormatter} from '../formatters/NumberFormatter';
import {NumberEditor} from '../editors';

export const NumberTypeProvider = (props: DataTypeProviderProps): ReactElement =>
    <DataTypeProvider
        formatterComponent={NumberFormatter}
        editorComponent={NumberEditor}
        {...props}
    />;
