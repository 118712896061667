import {FunctionComponent, PropsWithChildren} from 'react';
import {typographyStyle} from '../../../styles';
import {withStyles} from '@material-ui/core';
import {TypographyProps} from './types';


const Success: FunctionComponent<TypographyProps> = (props: PropsWithChildren<TypographyProps>) => {
    const {classes, children} = props;
    return (
        <div className={classes.defaultFontStyle + ' ' + classes.successText}>
            {children}
        </div>
    );
};
export default withStyles(typographyStyle, {name: 'MuiCTSuccess'})(Success);
