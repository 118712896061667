import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import classNames from 'classnames';
import {NavLink, withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {sidebarStyle} from '../../../styles';
import {SidebarProps} from './types';
import {AdminNavbarLinks} from '../Navbars';
import {generateLink, Route} from '../../../router';
import {withStyles} from '@material-ui/core';
import {BaseProps} from '../../../base';

const Sidebar: FunctionComponent<SidebarProps> = (props: PropsWithChildren<SidebarProps>) => {
    const {classes} = props;

    const activeRoute = (routeName: string): boolean => {
        return props.location.pathname.startsWith(routeName);
    };

    const {color, logo, image, logoText, routes} = props;
    const links: ReactElement =
        <List dense component="div" role="list" className={classes.list}>
            {routes.map((prop: Route<BaseProps>, key: number) => {
                const listItemClasses = classNames({
                    [' ' + classes[color]]: activeRoute(prop.path),
                });
                const whiteFontClasses = classNames({
                    [' ' + classes.whiteFont]: activeRoute(prop.path),
                });
                return (
                    <NavLink
                        to={generateLink(prop.key)}
                        className={' ' + classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof prop.icon === 'string' ?
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive,
                                    })}
                                >
                                    {prop.icon}
                                </Icon>
                                : prop.icon &&
                                <prop.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive,
                                    })}
                                />
                            }
                            <ListItemText
                                primary={props.rtlActive ? prop.rtlName : prop.name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: props.rtlActive,
                                })}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>;
    const brand: ReactElement =
        <div className={classes.logo}>
            <a
                href="https://ebscarmo.gesavalia.com"
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive,
                })}
                rel={'noreferrer'}
                target={'_blank'}
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img}/>
                </div>
                {logoText} {props.version}
            </a>
        </div>;
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? 'left' : 'right'}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <PerfectScrollbar>
                            <AdminNavbarLinks/>
                            {links}
                        </PerfectScrollbar>
                    </div>
                    {image !== undefined ?
                        <div
                            className={classes.background}
                            style={{backgroundImage: 'url(' + image + ')'}}
                        />
                        : null}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? 'right' : 'left'}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive,
                        }),
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        <PerfectScrollbar>
                            {links}
                        </PerfectScrollbar>
                    </div>
                    {image !== undefined ?
                        <div
                            className={classes.background}
                            style={{backgroundImage: 'url(' + image + ')'}}
                        />
                        : null}
                </Drawer>
            </Hidden>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(sidebarStyle, {name: 'MuiCTSidebar'})(withRouter(Sidebar));
