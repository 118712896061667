import {FormItem} from '../../organisms';

export const resetRequestFormDefinition: Array<FormItem> = [
    {
        type: 'text',
        id: 'username',
        label: 'Utilizador',
        state: '',
        helperText: 'Introduza o seu nome de utilizador',
        informationalText: 'Introduza o seu nome de utilizador na plataforma para receber um código de reposição de palavra passe. Esse código será enviado para o endereço de email associado ao seu utilizador num prazo máximo de 10 minutos.',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
];

