export type SelectItem = {
    key: string | number;
    value: string;
    active?: boolean;
    new?: boolean;
};


export function isSelectItem(object?: Array<string | Record<string, unknown>> | string | Record<string, unknown> | null): object is SelectItem {
    return !Array.isArray(object) && typeof object !== 'string' && !!object && 'key' in object;
}
