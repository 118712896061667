import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms/Builder';
import {DisciplinaDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadDiscipline();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar disciplina'}
                disciplineProfile={this.state.discipline}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | number | Array<string>>>): Promise<void> => {
        const disciplinaDto: DisciplinaDto = extractObjectFromResult<string | boolean | number | Array<string>, DisciplinaDto>(result);

        if (this.props.session &&
            this.props.match.params.disciplineId &&
            await this.props.discipline?.update?.(this.props.match.params.disciplineId, disciplinaDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadDiscipline = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.disciplineId) {
            const discipline: DisciplinaDto | undefined = await this.props.discipline?.get?.(this.props.match.params.disciplineId);
            this.setState({discipline});
        }
    };
}

export default withAll(EditContainer);
