import {grayColor, whiteColor} from '../material-dashboard-react';
import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const formStyle: Styles<Theme, Record<string, unknown>, string> = {
    cardTitleWhite: {
        color: whiteColor,
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: 300,
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: grayColor[1],
            fontWeight: '400',
            lineHeight: '1',
        },
    },
};
