import {Font} from '@react-pdf/renderer';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoRegular from '../../../resources/fonts/roboto/Roboto-Regular.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoLight from '../../../resources/fonts/roboto/Roboto-Light.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoItalic from '../../../resources/fonts/roboto/Roboto-Italic.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoBold from '../../../resources/fonts/roboto/Roboto-Bold.ttf';

export const registerFonts = (): void => {
    Font.register({
        family: 'Roboto',
        format: 'truetype',
        fonts: [
            {src: RobotoRegular, fontWeight: '300'},
            {src: RobotoLight, fontWeight: '200'},
            {src: RobotoItalic, fontStyle: 'italic', fontWeight: '300'},
            {src: RobotoBold, fontWeight: '700'},
        ],
    });
};
