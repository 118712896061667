import {Paths, paths, Route} from '../../router';
import {BaseProps} from '../types';
import {Roles} from '../enums';

export function getAuthorizedPaths(roles?: Array<string>, parent?: Paths): Array<Route<BaseProps>> {
    if (roles) {
        return paths.filter(path => path.role && roles.includes(Roles[path.role]) && (parent ? path.parent === parent : true));
    } else {
        return paths.filter(path => path.role === Roles.ROLE_ANONYMOUS && (parent ? path.parent === parent : true));
    }
}
