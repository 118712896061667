import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem} from '../../../../organisms/Builder';
import {mapObjectToForm} from '../../../../../base';
import {ProfileProps} from '../../types';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | boolean | Array<string>>>): ReactElement => {
    const _formDefinition: Array<FormItem<string | boolean | Array<string>>> = mapObjectToForm<string | boolean | Array<string>, Record<string, string | boolean | Array<string>>>(props.cycle ?? {}, _.cloneDeep(profileFormDefinition));

    return (
        <FormBuilder<string | boolean | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default ProfileContainer;

