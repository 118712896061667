import {FormItem} from '../../organisms/Builder';

export const profileFormDefinition: Array<FormItem> = [
    {
        type: 'text',
        id: 'username',
        label: 'Nome de utilizador',
        helperText: 'Não é possível mudar o seu nome de utilizador',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        disabled: true,
    },
    {
        type: 'text',
        id: 'email',
        label: 'Endereço de email',
        helperText: 'Não é possível mudar o seu endereço de email',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        disabled: true,
    },
    {
        type: 'simple-password',
        id: 'password',
        label: 'Palavra-passe',
        helperText: 'Para mudar a sua palavra-passe, deve introduzir primeiro a palavra-passe atual',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
    {
        type: 'password',
        id: 'newPassword',
        label: 'Palavra-passe',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
];

