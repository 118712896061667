import {FunctionComponent} from 'react';
import {BrowserRouter, Redirect, Route as RouterRoute, Switch} from 'react-router-dom';
import {Route} from './types';
import {generateRoute} from './paths';
import Template from '../components/templates/Template';
import {BaseProps, getAuthorizedPaths, getDefaultRoute} from '../base';
import {PageNotFound} from '../components/pages/PageNotFound';
import {StoreProps, withStore} from '../redux';

const Router: FunctionComponent<StoreProps> = (props: StoreProps) => {
    return (
        <BrowserRouter>
            <Template>
                <Switch>
                    <RouterRoute exact path={'/'}>
                        <Redirect to={generateRoute(getDefaultRoute(props.session?.user.roles))}/>
                    </RouterRoute>
                    {getAuthorizedPaths(props.session?.user.roles)
                        .filter((route: Route<BaseProps>) => route.parent === undefined)
                        .map((route: Route<BaseProps>, key: number) => {
                            return (
                                <RouterRoute key={key}
                                             exact={route.exact}
                                             path={generateRoute(route.key)}
                                             component={route.component}/>
                            );
                        })}
                    <RouterRoute path={'*'} component={PageNotFound}/>
                </Switch>
            </Template>
        </BrowserRouter>
    );
};

export default withStore(Router);
