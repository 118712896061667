import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {EscolaDto} from '../../../../../api/types';
import {mapOptionsToForm} from '../../../../../base';
import {withStore} from '../../../../../redux';


const ProfileContainer: FunctionComponent<ProfileProps> = (props: PropsWithChildren<ProfileProps>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.school?.list?.(),
            ]);

            const schoolOptions: Array<SelectItem> = results?.[0]?.map((school: EscolaDto) => {
                return {
                    key: `${school.id ?? 0}`,
                    value: school.nome ?? '',
                };
            }) ?? [];
            setSelectOptions([
                {
                    id: 'escola',
                    options: schoolOptions,
                },
            ]);
        }
    };
    useEffect(() => {
        _setSelectItems();
    }, [props.session]);

    let _formDefinition: Array<FormItem> = _.cloneDeep(profileFormDefinition);

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

