import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {List, Manage} from '../../components/pages/ClassParameters';
import {ClassParametersPaths} from './classParametersPaths';

export const classParametersPaths: Array<Route<BaseProps>> = [
    {
        key: ClassParametersPaths.LIST,
        parent: ClassParametersPaths.HOME,
        path: '/',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: ClassParametersPaths.MANAGE,
        parent: ClassParametersPaths.HOME,
        path: '/manage',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Manage,
    },
];

