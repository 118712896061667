import {ChangeEvent, PropsWithChildren, ReactElement} from 'react';
import {ItemProps, SelectItem} from '../types';
import {
    Checkbox as CheckboxItem,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Typography,
} from '@material-ui/core';

const Checkbox = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement => {
    const _onChangeHandler = (key: string) => (_event: ChangeEvent<Record<string, unknown>>, checked: boolean): void | undefined => {
        if (checked && Array.isArray(props.item.state)) {
            props.item.state.push(key);
        }
        return Array.isArray(props.item.state) ?
            props.onChange?.(props.item.id, checked ? props.item.state as T :
                props.item.state.filter(value => value !== key) as T) :
            undefined;
    };

    return (
        <FormControl
            style={{marginTop: 20, marginBottom: 20}}
            component={'fieldset'}
            disabled={props.item.disabled}
            required={props.item.required}
            variant={props.item.variant}
        >
            <FormLabel style={{marginBottom: 10, marginLeft: 15}} component={'legend'}>
                <Typography style={{fontSize: '0.8rem'}}
                            component={'label'}>
                    {props.item.label}
                </Typography>
            </FormLabel>
            <FormGroup
                aria-label={props.item.label}
                row={props.item.checkBoxOptions?.row}
                ref={props.item.innerRef}
            >
                {
                    props.item.options?.map((selectItem: SelectItem) => {
                        return (
                            <FormControlLabel
                                key={selectItem.key}
                                checked={Array.isArray(props.item.state) && !!selectItem.key && props.item.state.includes(selectItem.key)}
                                value={selectItem.key}
                                control={<CheckboxItem/>}
                                label={selectItem.value}
                                labelPlacement="end"
                                onChange={_onChangeHandler(selectItem.key.toString())}
                            />
                        );
                    })
                }
            </FormGroup>
        </FormControl>
    );
};

export default Checkbox;
