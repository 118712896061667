import {Route} from '../types';
import {DateRangeOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {SchoolYearPaths} from './schoolYearPaths';
import {Create, Edit, List, SchoolYear} from '../../components/pages/SchoolYear';
import {ReactElement} from 'react';

export const schoolYearPaths: Array<Route<BaseProps>> = [
    {
        key: SchoolYearPaths.HOME,
        name: 'Anos Letivos',
        path: '/school-years',
        icon: DateRangeOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <SchoolYear/>,
    },
    {
        key: SchoolYearPaths.LIST,
        parent: SchoolYearPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: SchoolYearPaths.CREATE,
        parent: SchoolYearPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: SchoolYearPaths.EDIT,
        parent: SchoolYearPaths.HOME,
        path: '/edit/:schoolYearId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

