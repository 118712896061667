import {IconButton, InputAdornment, TextField} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {InfoOutlined} from '@material-ui/icons';
import {ChangeEvent, Component, ReactElement} from 'react';
import {TOOLTIP_DURATION} from '../consts';
import {EmailState, ItemProps} from '../types';


class Email<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends Component<ItemProps<T>, EmailState> {
    public state: Readonly<EmailState> = {
        error: false,
        tooltip: false,
        tooltipMessage: '',
    };

    public render(): ReactElement {
        return (
            <Tooltip open={this.state.tooltip} title={this.state.tooltipMessage} disableFocusListener={true}
                     disableHoverListener={true} disableTouchListener={true} arrow={true}>
                <TextField
                    id={this.props.item.id}
                    label={this.props.item.label}
                    disabled={this.props.item.disabled}
                    required={this.props.item.required}
                    error={this.state.error}
                    margin={this.props.item.margin}
                    variant={this.props.item.variant}
                    type={this.props.item.type}
                    fullWidth={this.props.item.fullWidth}
                    helperText={this.props.item.helperText}
                    value={this.props.item.state ?? ''}
                    aria-invalid={this.props.item.ariaInvalid}
                    placeholder={this.props.item.placeholderText}
                    inputRef={this.props.item.innerRef}
                    InputLabelProps={{
                        shrink: this.props.item.shrink,
                    }}
                    onChange={this._onChangeHandler}
                    onBlur={this._validateEmail}
                    InputProps={{
                        endAdornment: this.props.item.informationalText ?
                            <InputAdornment position={'end'}>
                                <Tooltip title={this.props.item.informationalText}>
                                    <IconButton style={{padding: 2, marginRight: -2}}
                                                aria-label={'informational-text'}
                                    >
                                        <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            : undefined,
                    }}
                />
            </Tooltip>
        );
    }

    private _onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | undefined => {
        return this.props.onChange(this.props.item.id, event.target.value as T);
    };

    private _validateEmail = (): void => {
        const regExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!this.props.item.state || typeof this.props.item.state !== 'string' || !regExp.test(this.props.item.state)) {
            this._setError('O endereço de email introduzido não é válido.');
            this._timeoutTooltip();
        } else {
            this._clearError();
        }
    };

    private _timeoutTooltip = (): void => {
        setTimeout(() => {
            this.setState({
                tooltip: false,
                tooltipMessage: '',
            });
        }, TOOLTIP_DURATION);
    };

    private _setError = (text: string): void => {
        this.setState({
            error: true,
            tooltip: true,
            tooltipMessage: text,
        });
    };

    private _clearError = (): void => {
        this.setState({
            error: false,
            tooltip: false,
            tooltipMessage: '',
        });
    };
}

export default Email;
