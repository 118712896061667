import {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import {SnackbarProps} from './types';
import {snackbarContentStyle} from '../../../styles';
import {SvgIconComponent} from '@material-ui/icons';
import {withStyles} from '@material-ui/core';

const SnackbarContent: FunctionComponent<SnackbarProps> = (props: PropsWithChildren<SnackbarProps>) => {
    const {classes, message, color, close, rtlActive} = props;
    const Icon: SvgIconComponent | undefined = props.icon;
    let action: ReactNode = [];
    const messageClasses = classNames({
        [classes.iconMessage]: Icon !== undefined,
    });
    if (close !== undefined) {
        action = [
            <IconButton
                className={classes.iconButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={props.closeNotification}
            >
                <Close className={classes.close}/>
            </IconButton>,
        ];
    }
    return (
        <Snack
            message={
                <div>
                    {Icon !== undefined ? <Icon className={classes.icon}/> : null}
                    <span className={messageClasses}>{message}</span>
                </div>
            }
            action={action}
            classes={{
                root: classes.root + ' ' + (color ? classes[color] : ''),
                message: classes.message,
                action: classNames({[classes.actionRTL]: rtlActive}),
            }}
        />
    );
};

export default withStyles(snackbarContentStyle, {name: 'MuiCTSnackbarContent'})(SnackbarContent);
