import {FormItem} from '../../../../organisms';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome do professor',
        helperText: 'Introduza o nome do professor',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'select',
        id: 'utilizador',
        label: 'Utilizador',
        helperText: 'Selecione o utilizador associado',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
];

