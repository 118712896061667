import {IconButton, Tooltip, withStyles} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {FunctionComponent, PropsWithChildren} from 'react';
import {textStyle} from '../../../styles';
import {TextProps} from './types';


const Text: FunctionComponent<TextProps> = (props: PropsWithChildren<TextProps>) => {
    const {classes, label, value} = props;

    return (
        <div className={classes.container}>
            <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                    <span>
                        {label}
                    </span>
                </legend>
                <p className={classes.innerText}>
                    {value}
                    {
                        props.informationalText &&
                        <Tooltip title={props.informationalText}>
                            <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                        aria-label={'informational-text'}
                            >
                                <InfoOutlined style={{fontSize: '1.0rem'}}/>
                            </IconButton>
                        </Tooltip>
                    }
                </p>
            </fieldset>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(textStyle, {name: 'MuiCTText'})(Text);
