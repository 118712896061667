import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {routes} from '../../../../../api/constants';
import {withStore} from '../../../../../redux';
import {AnoDto, AnoLetivoDto, DisciplinaDto, EscolaDto, ProfessorDto} from '../../../../../api/types';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | Array<string> | number>> = (props: PropsWithChildren<ProfileProps<string | boolean | Array<string> | number>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.school?.list?.(),
                props.year?.list?.(),
                props.schoolYear?.list?.(),
                props.discipline?.list?.(),
                props.teacher?.list?.(),
                props.group?.list?.(),
            ]);

            const schoolOptions: Array<SelectItem> = results?.[0]?.map((school: EscolaDto) => {
                return {
                    key: `${routes.schools}/${school.id ?? 0}`,
                    value: school.nome ?? '',
                };
            }) ?? [];

            const yearOptions: Array<SelectItem> = results?.[1]?.map((year: AnoDto) => {
                return {
                    key: `${routes.years}/${year.id ?? 0}`,
                    value: year.nome ?? '',
                };
            }) ?? [];

            const schoolYearOptions: Array<SelectItem> = results?.[2]?.map((schoolYear: AnoLetivoDto) => {
                return {
                    key: `${routes.schoolYears}/${schoolYear.id ?? 0}`,
                    value: schoolYear.nome ?? '',
                };
            }) ?? [];

            const disciplineOptions: Array<SelectItem> = results?.[3]?.map((discipline: DisciplinaDto) => {
                const grupoDisciplinar = results[5]?.find(
                    g => g.id && discipline.grupoDisciplinar?.includes(g.id),
                );
                return {
                    key: `${routes.disciplines}/${discipline.id ?? 0}`,
                    value: `${discipline.nome} [${grupoDisciplinar?.numero}]`,
                };
            }) ?? [];

            const teacherOptions: Array<SelectItem> = results?.[4]?.map((teacher: ProfessorDto) => {
                return {
                    key: `${routes.teachers}/${teacher.id ?? 0}`,
                    value: teacher.nome ?? '',
                };
            }) ?? [];

            setSelectOptions([
                {
                    id: 'escola',
                    options: schoolOptions,
                },
                {
                    id: 'ano',
                    options: yearOptions,
                },
                {
                    id: 'anoLetivo',
                    options: schoolYearOptions,
                },
                {
                    id: 'disciplinas',
                    options: disciplineOptions,
                },
                {
                    id: 'professores',
                    options: teacherOptions,
                },
            ]);
        }
    };


    useEffect(() => {
        _setSelectItems();
    }, [props.classProfile]);


    let _formDefinition: Array<FormItem<string | boolean | Array<string> | number>> = mapObjectToForm<string | boolean | Array<string> | number, Record<string, string | boolean | Array<string> | number>>(props.classProfile ?? {}, _.cloneDeep(profileFormDefinition));

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });


    return (
        <FormBuilder<string | boolean | Array<string> | number>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

