import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {ProfilePaths} from './profilePaths';
import {Profile} from '../../components/pages/Profile';

export const profilePaths: Array<Route<BaseProps>> = [
    {
        key: ProfilePaths.HOME,
        path: '/profile',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Profile,
    },
];

