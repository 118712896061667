import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {ResetPaths} from './resetPaths';
import {ResetRequest} from '../../components/pages/ResetRequest';
import {ResetConfirm} from '../../components/pages/ResetConfirm';

export const resetPaths: Array<Route<BaseProps>> = [
    {
        key: ResetPaths.REQUEST,
        path: '/reset-request',
        role: Roles.ROLE_ANONYMOUS,
        exact: true,
        hidden: true,
        component: ResetRequest,
    },
    {
        key: ResetPaths.CONFIRM,
        path: '/reset-confirm',
        role: Roles.ROLE_ANONYMOUS,
        exact: true,
        hidden: true,
        component: ResetConfirm,
    },
];

