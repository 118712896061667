export enum Hour {
    '00h00' = '00:00',
    '00h30' = '00:30',
    '01h00' = '01:00',
    '01h30' = '01:30',
    '02h00' = '02:00',
    '02h30' = '02:30',
    '03h00' = '03:00',
    '03h30' = '03:30',
    '04h00' = '04:00',
    '04h30' = '04:30',
    '05h00' = '05:00',
    '05h30' = '05:30',
    '06h00' = '06:00',
    '06h30' = '06:30',
    '07h00' = '07:00',
    '07h30' = '07:30',
    '08h00' = '08:00',
    '08h30' = '08:30',
    '09h00' = '09:00',
    '09h30' = '09:30',
    '10h00' = '10:00',
    '10h30' = '10:30',
    '11h00' = '11:00',
    '11h30' = '11:30',
    '12h00' = '12:00',
    '12h30' = '12:30',
    '13h00' = '13:00',
    '13h30' = '13:30',
    '14h00' = '14:00',
    '14h30' = '14:30',
    '15h00' = '15:00',
    '15h30' = '15:30',
    '16h00' = '16:00',
    '16h30' = '16:30',
    '17h00' = '17:00',
    '17h30' = '17:30',
    '18h00' = '18:00',
    '18h30' = '18:30',
    '19h00' = '19:00',
    '19h30' = '19:30',
    '20h00' = '20:00',
    '20h30' = '20:30',
    '21h00' = '21:00',
    '21h30' = '21:30',
    '22h00' = '22:00',
    '22h30' = '22:30',
    '23h00' = '23:00',
    '23h30' = '23:30',
    '24h00' = '24:00',
}
