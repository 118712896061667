import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {Done} from '@material-ui/icons';
import {handleErr} from '../handleErr';
import {DEFAULT_TIMEOUT} from '../../../base/constants';

export const studentDelete = (id: string): ThunkAction<Promise<string | undefined>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<string | undefined> => {
        dispatch(notify(
            'A remover informação. Por favor aguarde...',
            'primary',
            undefined,
            'bc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        let result: string | undefined;
        try {
            await gesAvaliaApi.studentDelete(id);
            dispatch(dismiss());
            dispatch(notify(
                'Aluno removido com sucesso!',
                'success',
                Done,
                'tc',
                true,
            ));
            setTimeout(() => dispatch(dismiss()), DEFAULT_TIMEOUT);
            result = id;
        } catch (err) {
            dispatch(handleErr(err));
        }
        return result;
    };
};
