import {generatePath} from 'react-router-dom';
import {BaseProps} from '../../base';
import {Paths, Route} from '../types';
import {DEFAULT_PATH} from '../constants';
import {dashboardPaths} from './dashboard';
import {profilePaths} from './profile';
import {signInPaths} from './signIn';
import {schoolPaths} from './school';
import {yearPaths} from './year';
import {cyclePaths} from './cycle';
import {schoolYearPaths} from './schoolYear';
import {periodPaths} from './period';
import {userPaths} from './user';
import {teacherPaths} from './teacher';
import {groupPaths} from './group';
import {domainPaths} from './domain';
import {studentPaths} from './student';
import {disciplinePaths} from './discipline';
import {parameterPaths} from './parameter';
import {lockPaths} from './lock';
import {classPaths} from './class';
import {classStudentsPaths} from './classStudents';
import {classParametersPaths} from './classParameters';
import {evaluationPaths} from './evaluation';
import {studentEvaluationsPaths} from './studentEvaluations';
import {importPaths} from './import';
import {resetPaths} from './reset';

export const paths: Array<Route<BaseProps>> = [
    ...signInPaths,
    ...profilePaths,
    ...dashboardPaths,
    ...[
        ...classPaths,
        ...classStudentsPaths,
        ...classParametersPaths,
        ...evaluationPaths,
        ...importPaths,
        ...studentPaths,
        ...disciplinePaths,
        ...parameterPaths,
        ...cyclePaths,
        ...periodPaths,
        ...groupPaths,
        ...domainPaths,
        ...yearPaths,
        ...schoolYearPaths,
        ...lockPaths,
        ...schoolPaths,
        ...studentEvaluationsPaths,
        ...teacherPaths,
        ...resetPaths,
        ...userPaths,
    ]
        .sort((routeA: Route<BaseProps>, routeB: Route<BaseProps>) => {
            if (!routeA.name) {
                return -1;
            }
            if (!routeB.name) {
                return 1;
            }
            if (routeA.name < routeB.name) {
                return -1;
            }
            if (routeA.name > routeB.name) {
                return 1;
            }
            return 0;
        }),
];

export function generateRoute(name: Paths): string {
    const route: Route<BaseProps> | undefined = paths.find((path: Route<BaseProps>) => {
        return path.key === name;
    });

    if (route) {
        // eslint-disable-next-line prefer-const
        let {path, parent} = route;
        if (parent) {
            const prefix = generateRoute(parent);
            path = prefix + path;
        }
        return path;
    }

    return DEFAULT_PATH;
}

export function generateLink(name: Paths, params?: { [paramName: string]: string | number | boolean | undefined }): string {
    const route = generateRoute(name);
    return generatePath(route, params);
}
