import {DispatchProps, Store, StoreProps} from '../types';
import {connect} from 'react-redux';
import {
    Action,
    classCreate,
    classDelete,
    classGet,
    classList,
    classParameterList, classStudentDelete,
    classTeacherParameterClassTeacherList,
    classTeacherParameterList,
    classUpdate,
    cycleCreate,
    cycleDelete,
    cycleGet,
    cycleList,
    cycleUpdate,
    disciplineCreate,
    disciplineDelete,
    disciplineGet,
    disciplineList,
    disciplineUpdate,
    dismiss,
    domainCreate,
    domainDelete,
    domainGet,
    domainList,
    domainUpdate,
    evaluationCreate,
    evaluationDelete,
    evaluationGet,
    evaluationGrades,
    evaluationList,
    evaluationParameterList,
    evaluationStudentEvaluationsList,
    evaluationStudentList,
    evaluationUpdate,
    gate,
    groupCreate,
    groupDelete,
    groupGet,
    groupList,
    groupUpdate,
    lockEvaluationsUpdate,
    lockEvaluationUpdate,
    notify,
    parameterClassTeacherCreate,
    parameterClassTeacherDelete,
    parameterClassTeacherGet,
    parameterCreate,
    parameterDelete,
    parameterGet,
    parameterList,
    parameterUpdate,
    periodCreate,
    periodDelete,
    periodGet,
    periodList,
    periodUpdate,
    resetConfirm,
    resetRequest,
    schoolCreate,
    schoolDataImport,
    schoolDelete,
    schoolGet,
    schoolList,
    schoolUpdate,
    schoolYearCreate,
    schoolYearDelete,
    schoolYearGet,
    schoolYearList,
    schoolYearUpdate,
    signIn,
    signOut,
    studentClassCreate,
    studentClassDelete,
    studentClassList,
    studentClassListByClass,
    studentClassUpdate,
    studentCreate,
    studentDelete,
    studentEvaluationCreate,
    studentEvaluationDelete,
    studentEvaluationUpdate,
    studentGet,
    studentList,
    studentUpdate,
    teacherCreate,
    teacherDelete,
    teacherGet,
    teacherList,
    teacherUpdate,
    userCreate,
    userDelete,
    userGet,
    userList,
    userResendConfirmationEmail,
    userUpdate,
    yearCreate,
    yearDelete,
    yearGet,
    yearList,
    yearUpdate,
} from '../actions';
import {SvgIconComponent} from '@material-ui/icons';
import {
    AlunoDto,
    AlunoTurmaDto,
    AnoDto,
    AnoLetivoDto,
    AvaliacaoAlunoDto,
    AvaliacaoDto,
    CicloDto,
    DisciplinaDto,
    DominioDto,
    EscolaDto,
    GrupoDto,
    NotasDto,
    ParametroDto,
    ParametroTurmaProfessorDto,
    PeriodoDto,
    ProfessorDto,
    ResetDto,
    TurmaDto,
    UserDto,
} from '../../api/types';
import {LockOption, Type} from '../../api/enums';
import {ThunkDispatch} from 'redux-thunk';
import {Roles} from '../../base';

const mapStateToProps = <T extends StoreProps>(state: Store, ownProps: T): T => {
    return {
        ...ownProps,
        ...state,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, unknown, Action>): DispatchProps => {
    return {
        dismiss(): void {
            dispatch(dismiss());
        },
        notify(message: string,
               color?: 'info' | 'warning' | 'danger' | 'primary' | 'success',
               icon?: SvgIconComponent,
               place?: 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc',
               close = true,
               progress = false): void {
            dispatch(notify(message, color, icon, place, close, progress));
        },
        gate(role: Roles, callback: (...args: Array<unknown>) => void): ((...args: Array<unknown>) => void) | undefined {
            return dispatch(gate(role, callback));
        },
        async signIn(username: string, password: string): Promise<boolean> {
            return dispatch(signIn(username, password));
        },
        async signOut(): Promise<boolean> {
            return dispatch(signOut());
        },
        school: {
            async list(): Promise<Array<EscolaDto> | undefined> {
                return dispatch(schoolList());
            },
            async create(escolaDto: EscolaDto): Promise<EscolaDto | undefined> {
                return dispatch(schoolCreate(escolaDto));
            },
            async get(id: string): Promise<EscolaDto | undefined> {
                return dispatch(schoolGet(id));
            },
            async update(id: string, escolaDto: EscolaDto): Promise<EscolaDto | undefined> {
                return dispatch(schoolUpdate(id, escolaDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(schoolDelete(id));
            },
            async dataImport(id: string, data: FormData, type: Type): Promise<void> {
                await dispatch(schoolDataImport(id, data, type));
            },
        },
        user: {
            async list(): Promise<Array<UserDto> | undefined> {
                return dispatch(userList());
            },
            async create(userDto: UserDto): Promise<UserDto | undefined> {
                return dispatch(userCreate(userDto));
            },
            async get(id: string): Promise<UserDto | undefined> {
                return dispatch(userGet(id));
            },
            async update(id: string, userDto: UserDto): Promise<UserDto | undefined> {
                return dispatch(userUpdate(id, userDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(userDelete(id));
            },
            async resendConfirmationEmail(id: string): Promise<void> {
                await dispatch(userResendConfirmationEmail(id));
            },
        },
        year: {
            async list(): Promise<Array<AnoDto> | undefined> {
                return dispatch(yearList());
            },
            async create(anoDto: AnoDto): Promise<AnoDto | undefined> {
                return dispatch(yearCreate(anoDto));
            },
            async get(id: string): Promise<AnoDto | undefined> {
                return dispatch(yearGet(id));
            },
            async update(id: string, anoDto: AnoDto): Promise<AnoDto | undefined> {
                return dispatch(yearUpdate(id, anoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(yearDelete(id));
            },
        },
        class: {
            async list(): Promise<Array<TurmaDto> | undefined> {
                return dispatch(classList());
            },
            async create(turmaDto: TurmaDto): Promise<TurmaDto | undefined> {
                return dispatch(classCreate(turmaDto));
            },
            async get(id: string): Promise<TurmaDto | undefined> {
                return dispatch(classGet(id));
            },
            async update(id: string, turmaDto: TurmaDto): Promise<TurmaDto | undefined> {
                return dispatch(classUpdate(id, turmaDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(classDelete(id));
            },
            async studentDelete(id: string, alunoId: string): Promise<string | undefined> {
                return await dispatch(classStudentDelete(id, alunoId));
            },
            async parameterList(id: string): Promise<Array<ParametroDto> | undefined> {
                return dispatch(classParameterList(id));
            },
            async teacherParameterList(id: string): Promise<Array<ParametroDto> | undefined> {
                return dispatch(classTeacherParameterList(id));
            },
            async teacherParameterClassTeacherList(id: string): Promise<Array<ParametroTurmaProfessorDto> | undefined> {
                return dispatch(classTeacherParameterClassTeacherList(id));
            },
        },
        teacher: {
            async list(): Promise<Array<ProfessorDto> | undefined> {
                return dispatch(teacherList());
            },
            async create(professorDto: ProfessorDto): Promise<ProfessorDto | undefined> {
                return dispatch(teacherCreate(professorDto));
            },
            async get(id: string): Promise<ProfessorDto | undefined> {
                return dispatch(teacherGet(id));
            },
            async update(id: string, professorDto: ProfessorDto): Promise<ProfessorDto | undefined> {
                return dispatch(teacherUpdate(id, professorDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(teacherDelete(id));
            },
        },
        cycle: {
            async list(): Promise<Array<CicloDto> | undefined> {
                return dispatch(cycleList());
            },
            async create(cicloDto: CicloDto): Promise<CicloDto | undefined> {
                return dispatch(cycleCreate(cicloDto));
            },
            async get(id: string): Promise<CicloDto | undefined> {
                return dispatch(cycleGet(id));
            },
            async update(id: string, cicloDto: CicloDto): Promise<CicloDto | undefined> {
                return dispatch(cycleUpdate(id, cicloDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(cycleDelete(id));
            },
        },
        evaluation: {
            async list(): Promise<Array<AvaliacaoDto> | undefined> {
                return dispatch(evaluationList());
            },
            async create(avaliacaoDto: AvaliacaoDto): Promise<AvaliacaoDto | undefined> {
                return dispatch(evaluationCreate(avaliacaoDto));
            },
            async get(id: string): Promise<AvaliacaoDto | undefined> {
                return dispatch(evaluationGet(id));
            },
            async studentList(id: string): Promise<Array<AlunoDto> | undefined> {
                return dispatch(evaluationStudentList(id));
            },
            async parameterList(id: string): Promise<Array<ParametroDto> | undefined> {
                return dispatch(evaluationParameterList(id));
            },
            async studentEvaluationList(id: string): Promise<Array<AvaliacaoAlunoDto> | undefined> {
                return dispatch(evaluationStudentEvaluationsList(id));
            },
            async grades(id: string): Promise<NotasDto | undefined> {
                return dispatch(evaluationGrades(id));
            },
            async update(id: string, avaliacaoDto: AvaliacaoDto): Promise<AvaliacaoDto | undefined> {
                return dispatch(evaluationUpdate(id, avaliacaoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(evaluationDelete(id));
            },
        },
        schoolYear: {
            async list(): Promise<Array<AnoLetivoDto> | undefined> {
                return dispatch(schoolYearList());
            },
            async create(anoLetivoDto: AnoLetivoDto): Promise<AnoLetivoDto | undefined> {
                return dispatch(schoolYearCreate(anoLetivoDto));
            },
            async get(id: string): Promise<AnoLetivoDto | undefined> {
                return dispatch(schoolYearGet(id));
            },
            async update(id: string, anoLetivoDto: AnoLetivoDto): Promise<AnoLetivoDto | undefined> {
                return dispatch(schoolYearUpdate(id, anoLetivoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(schoolYearDelete(id));
            },
        },
        period: {
            async list(): Promise<Array<PeriodoDto> | undefined> {
                return dispatch(periodList());
            },
            async create(periodoDto: PeriodoDto): Promise<PeriodoDto | undefined> {
                return dispatch(periodCreate(periodoDto));
            },
            async get(id: string): Promise<PeriodoDto | undefined> {
                return dispatch(periodGet(id));
            },
            async update(id: string, periodoDto: PeriodoDto): Promise<PeriodoDto | undefined> {
                return dispatch(periodUpdate(id, periodoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(periodDelete(id));
            },
        },
        group: {
            async list(): Promise<Array<GrupoDto> | undefined> {
                return dispatch(groupList());
            },
            async create(grupoDto: GrupoDto): Promise<GrupoDto | undefined> {
                return dispatch(groupCreate(grupoDto));
            },
            async get(id: string): Promise<GrupoDto | undefined> {
                return dispatch(groupGet(id));
            },
            async update(id: string, grupoDto: GrupoDto): Promise<GrupoDto | undefined> {
                return dispatch(groupUpdate(id, grupoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(groupDelete(id));
            },
        },
        domain: {
            async list(): Promise<Array<DominioDto> | undefined> {
                return dispatch(domainList());
            },
            async create(dominioDto: DominioDto): Promise<DominioDto | undefined> {
                return dispatch(domainCreate(dominioDto));
            },
            async get(id: string): Promise<DominioDto | undefined> {
                return dispatch(domainGet(id));
            },
            async update(id: string, dominioDto: DominioDto): Promise<DominioDto | undefined> {
                return dispatch(domainUpdate(id, dominioDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(domainDelete(id));
            },
        },
        student: {
            async list(): Promise<Array<AlunoDto> | undefined> {
                return dispatch(studentList());
            },
            async create(alunoDto: AlunoDto): Promise<AlunoDto | undefined> {
                return dispatch(studentCreate(alunoDto));
            },
            async get(id: string): Promise<AlunoDto | undefined> {
                return dispatch(studentGet(id));
            },
            async update(id: string, alunoDto: AlunoDto): Promise<AlunoDto | undefined> {
                return dispatch(studentUpdate(id, alunoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(studentDelete(id));
            },
        },
        studentEvaluation: {
            async create(avaliacaoAlunoDto: AvaliacaoAlunoDto): Promise<AvaliacaoAlunoDto | undefined> {
                return dispatch(studentEvaluationCreate(avaliacaoAlunoDto));
            },
            async update(id: string, avaliacaoAlunoDto: AvaliacaoAlunoDto): Promise<AvaliacaoAlunoDto | undefined> {
                return dispatch(studentEvaluationUpdate(id, avaliacaoAlunoDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(studentEvaluationDelete(id));
            },
        },
        discipline: {
            async list(): Promise<Array<DisciplinaDto> | undefined> {
                return dispatch(disciplineList());
            },
            async create(disciplinaDto: DisciplinaDto): Promise<DisciplinaDto | undefined> {
                return dispatch(disciplineCreate(disciplinaDto));
            },
            async get(id: string): Promise<DisciplinaDto | undefined> {
                return dispatch(disciplineGet(id));
            },
            async update(id: string, disciplinaDto: DisciplinaDto): Promise<DisciplinaDto | undefined> {
                return dispatch(disciplineUpdate(id, disciplinaDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(disciplineDelete(id));
            },
        },
        parameter: {
            async list(): Promise<Array<ParametroDto> | undefined> {
                return dispatch(parameterList());
            },
            async create(parametroDto: ParametroDto): Promise<ParametroDto | undefined> {
                return dispatch(parameterCreate(parametroDto));
            },
            async get(id: string): Promise<ParametroDto | undefined> {
                return dispatch(parameterGet(id));
            },
            async update(id: string, parametroDto: ParametroDto): Promise<ParametroDto | undefined> {
                return dispatch(parameterUpdate(id, parametroDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(parameterDelete(id));
            },
        },
        parameterClassTeacher: {
            async get(id: string): Promise<ParametroTurmaProfessorDto | undefined> {
                return dispatch(parameterClassTeacherGet(id));
            },
            async create(parametroTurmaProfessorDto: ParametroTurmaProfessorDto): Promise<ParametroTurmaProfessorDto | undefined> {
                return dispatch(parameterClassTeacherCreate(parametroTurmaProfessorDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(parameterClassTeacherDelete(id));
            },
        },
        studentClass: {
            async list(): Promise<Array<AlunoTurmaDto> | undefined> {
                return dispatch(studentClassList());
            },
            async listByClass(id: string): Promise<Array<AlunoTurmaDto> | undefined> {
                return dispatch(studentClassListByClass(id));
            },
            async create(alunoTurmaDto: AlunoTurmaDto): Promise<AlunoTurmaDto | undefined> {
                return dispatch(studentClassCreate(alunoTurmaDto));
            },
            async update(id: string, alunoTurmaDto: AlunoTurmaDto): Promise<AlunoTurmaDto | undefined> {
                return dispatch(studentClassUpdate(id, alunoTurmaDto));
            },
            async delete(id: string): Promise<string | undefined> {
                return dispatch(studentClassDelete(id));
            },
        },
        reset: {
            async request(resetDto: ResetDto): Promise<void> {
                await dispatch(resetRequest(resetDto));
            },
            async confirm(resetDto: ResetDto): Promise<void> {
                await dispatch(resetConfirm(resetDto));
            },
        },
        lock: {
            async evaluation(id: string, lockOption: LockOption): Promise<void> {
                await dispatch(lockEvaluationUpdate(id, lockOption));
            },
            async evaluations(startDate: string, endDate: string, lockOption: LockOption): Promise<void> {
                await dispatch(lockEvaluationsUpdate(startDate, endDate, lockOption));
            },
        },
    };
};
export const withStore = connect(mapStateToProps, mapDispatchToProps);
