import {FunctionComponent, PropsWithChildren} from 'react';
import {DialogProps} from './types';
import {Button, Dialog as MaterialDialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const Dialog: FunctionComponent<DialogProps> = (props: PropsWithChildren<DialogProps>) => {
    return (
        <MaterialDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                {
                    typeof props.content === 'string' ?
                        <DialogContentText id="alert-dialog-description">
                            {props.content}
                        </DialogContentText>
                        : props.content
                }
            </DialogContent>
            <DialogActions>
                {
                    props.onClose &&
                    <Button onClick={props.onClose} color="secondary">
                        {props.closeText ?? 'Cancelar'}
                    </Button>
                }
                {
                    props.onConfirm &&
                    <Button onClick={props.onConfirm} color="primary" autoFocus>
                        {props.confirmText ?? 'OK'}
                    </Button>
                }
            </DialogActions>
        </MaterialDialog>
    );
};

export default Dialog;

