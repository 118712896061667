import {Component, ReactNode} from 'react';
import DashboardView from './dashboard-view';
import {BaseProps, withAll} from '../../../base';

class DashboardContainer extends Component<BaseProps, { avaliacoes: number; turmas: number; disciplinas: number; alunos: number }> {
    public state: Readonly<{ avaliacoes: number; turmas: number; disciplinas: number; alunos: number }> = {
        avaliacoes: 0,
        turmas: 0,
        disciplinas: 0,
        alunos: 0,
    };

    public async componentDidMount(): Promise<void> {
        await this.loadData();
    }

    public loadData = async (): Promise<void> => {
        const results = await Promise.all([
            this.props.evaluation?.list?.(),
            this.props.class?.list?.(),
            this.props.discipline?.list?.(),
            this.props.student?.list?.(),
        ]);

        this.setState({
            avaliacoes: results?.[0]?.length ?? 0,
            turmas: results?.[1]?.length ?? 0,
            disciplinas: results?.[2]?.length ?? 0,
            alunos: results?.[3]?.length ?? 0,
        });
    };

    public render(): ReactNode {
        return (
            <DashboardView
                avaliacaes={this.state.avaliacoes}
                turmas={this.state.turmas}
                disciplinas={this.state.disciplinas}
                alunos={this.state.alunos}
            />
        );
    }
}

export default withAll(DashboardContainer);
