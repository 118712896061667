import {ChangeEvent, PropsWithChildren, ReactElement} from 'react';
import {ItemProps} from '../types';
import {TextField} from '@material-ui/core';

const SimplePassword = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement => {
    const _onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | undefined => {
        return props.onChange(props.item.id, event.target.value as T);
    };

    return (
        <TextField
            id={props.item.id}
            inputRef={props.item.innerRef}
            label={props.item.label}
            disabled={props.item.disabled}
            required={props.item.required}
            margin={props.item.margin}
            variant={props.item.variant}
            type={'password'}
            fullWidth={props.item.fullWidth}
            helperText={props.item.helperText}
            value={props.item.state ?? ''}
            onChange={_onChangeHandler}
        />
    );
};

export default SimplePassword;
