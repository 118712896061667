import {FormItem} from '../../../../organisms';
import {LockOption} from '../../../../../api/enums';

export const profileFormDefinition: Array<FormItem> = [
    {
        type: 'date',
        id: 'startDate',
        label: 'Data de início',
        helperText: 'Selecione a data de início',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'date',
        id: 'endDate',
        label: 'Data de fim',
        helperText: 'Selecione a data de fim',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'select',
        id: 'lockOption',
        label: 'Operação',
        helperText: 'Selecione a operação pretendida',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        options: [
            {
                key: LockOption.BLOQUEAR,
                value: 'Bloquear',
            },

            {
                key: LockOption.DESBLOQUEAR,
                value: 'Desbloquear',
            },

        ],
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

