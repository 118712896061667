import {FormItem} from '../../components/organisms';

/**
 * Maps an object to a form definition.
 * @param {object} object - The object to map
 * @param {Array<FormItem<T>>} form - The form definition to map to
 *
 * @return {Array<FormItem>} - The form definition with the mapped object
 */
export function mapObjectToForm<T extends string | number | boolean | Record<string, unknown> | Array<string | number> | null, Y extends Record<string, T>>(object: Y, form: Array<FormItem<T>>): Array<FormItem<T>> {
    return form.map(fi => {
        if (fi.id in object) {
            fi.state = object[fi.id];
        }
        return fi;
    });
}
