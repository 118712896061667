import _ from 'lodash';

/**
 * Returns the difference between two objects
 * @param  {object} origObject - Source object to compare newObject against
 * @param  {object} newObject  - New object with potential changes
 *
 * @return {object} - The resulting object with the difference
 *
 * Based on https://davidwells.io/snippets/get-difference-between-two-objects-javascript
 */
export function objectDiff<T extends string | number | boolean | Record<string, unknown> | Array<string | number> | null, Y extends Record<string, T>, Z extends Record<string, T>>(origObject: Y, newObject: Y): Z {
    function changes(oObject: { [key: string]: T }, nObject: { [key: string]: T }): { [key: string]: T } {
        let indexCounter = 0;
        return _.transform(nObject, function (result: { [key: string]: T }, value: T, key: string) {
            if (!_.isEqual(value, oObject[key])) {
                const resultKey = _.isArray(oObject) ? indexCounter++ : key;
                result[resultKey] = _.isObject(value) && _.isObject(oObject[key]) ? changes(oObject[key] as { [key: string]: T }, value as { [key: string]: T }) as T : value;
            }
        }, {});
    }

    return changes(origObject, newObject) as Z;
}
