import {Route} from '../types';
import {AssignmentOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {DomainPaths} from './domainPaths';
import {Create, Domain, Edit, List} from '../../components/pages/Domain';
import {ReactElement} from 'react';

export const domainPaths: Array<Route<BaseProps>> = [
    {
        key: DomainPaths.HOME,
        name: 'Domínios',
        path: '/domains',
        icon: AssignmentOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Domain/>,
    },
    {
        key: DomainPaths.LIST,
        parent: DomainPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: DomainPaths.CREATE,
        parent: DomainPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: DomainPaths.EDIT,
        parent: DomainPaths.HOME,
        path: '/edit/:domainId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

