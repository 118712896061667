import {Children, cloneElement, CSSProperties, PropsWithChildren, ReactElement} from 'react';
import {TableEditColumn} from '@devexpress/dx-react-grid-material-ui';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

export type ColumnCellEditorProps = TableEditColumnBase.CellProps & {
    className?: string;
    style?: CSSProperties;
    [x: string]: unknown;
    errors: { [key: number]: boolean };
};

export const ColumnCellEditor = (props: PropsWithChildren<ColumnCellEditorProps>): ReactElement => {
    return (
        <TableEditColumn.Cell {...props}>
            {Children.map(props.children, child => {
                    return (
                        typeof child === 'object' && child && 'props' in child && child.props.id === 'commit' && props.tableRow.rowId !== undefined
                            ? cloneElement(child, {disabled: props.errors[props.tableRow.rowId as number]})
                            : typeof child === 'object' && child && 'props' in child && (props.row.editDisabled || props.row.deleteDisabled)
                                ? cloneElement(child, {
                                    editDisabled: props.row.editDisabled,
                                    deleteDisabled: props.row.deleteDisabled,
                                })
                                : child
                    );
                },
            )}
        </TableEditColumn.Cell>
    );
};
