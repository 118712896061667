import {ComponentType, ReactElement} from 'react';
import {Redirect} from 'react-router-dom';
import {BaseProps} from '../types';

export const withSession = <T extends BaseProps>(WrappedComponent: ComponentType<T>): (props: T) => ComponentType<T> | ReactElement => {
    return (props: T): ComponentType<T> | ReactElement => {

        if (props.session?.access_token && props.location?.pathname === '/signin') {
            return <Redirect to={'/'}/>;
        }

        if (!props.session?.access_token && props.location?.pathname !== '/signin'
            && props.location?.pathname !== '/reset-request'
            && props.location?.pathname !== '/reset-confirm') {
            return <Redirect to={'/signin'}/>;
        }

        return <WrappedComponent {...props as T} />;
    };
};
