import {Notification} from '../../base';
import {Action} from '../actions';
import {DISMISS, NOTIFY} from '../constants';

export const notifyReducer = (state: Array<Notification> = [], action: Action): Array<Notification> => {
    switch (action.type) {
        case NOTIFY:
            if (action.notification) {
                return [
                    ...state,
                    {
                        ...action.notification,
                    },
                ];
            }
            return [
                ...state,
            ];
        case DISMISS:
            state.pop();
            return [
                ...state,
            ];
    }
    return state;
};
