import {withStyles} from '@material-ui/core';
import {
    AddCircleOutlined,
    ArrowBackIos,
    AutorenewOutlined,
    HighlightOffOutlined,
    RemoveCircleOutlined,
} from '@material-ui/icons';
import {TreeItem, TreeView} from '@material-ui/lab';
import {FunctionComponent, ReactElement} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {treeViewListStyle} from '../../../styles';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    GridContainer,
    GridItem,
    RegularButton,
    SearchInput,
    TreeListItem,
} from '../../atoms';
import {TreeViewListProps} from './types';

function generateTreeViewItems(items: Array<TreeListItem>, onSelect?: (id: string | number) => void): Array<ReactElement> {
    return items.map((item: TreeListItem) => {
        return (
            <TreeItem onClick={(): void => onSelect?.(item.id)}
                      key={item.id}
                      style={item.disabled ? {color: 'rgb(224,224,224)'} : undefined}
                      icon={!item.visited ? <AutorenewOutlined color={'action'}/> : undefined}
                      nodeId={item.id.toString()}
                      aria-disabled={item.disabled}
                      label={item.name}>
                {generateTreeViewItems(item.children, onSelect)}
            </TreeItem>
        );
    });
}

const TreeViewList: FunctionComponent<TreeViewListProps> = (props: TreeViewListProps) => {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog title={props.dialogTitle} open={props.dialogOpen}
                        content={props.dialogContent}
                        onConfirm={props.onDialogConfirm}
                        onClose={props.onDialogCancel}
                />
            </GridItem>
            {
                props.onReturn &&
                <GridItem xs={6} sm={6} md={6}
                          style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <RegularButton
                        id={'MuiCTTableListReturnButton'}
                        variant={'contained'}
                        color={'white'}
                        size={'lg'}
                        type={'button'}
                        startIcon={<ArrowBackIos/>}
                        onClick={props.onReturn}
                    >
                        Voltar
                    </RegularButton>
                </GridItem>
            }
            {
                props.onAction &&
                <GridItem xs={props.onReturn ? 6 : 12} sm={props.onReturn ? 6 : 12} md={props.onReturn ? 6 : 12}
                          style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <RegularButton
                        id={'MuiCTTableListAddButton'}
                        variant={'contained'}
                        color={'primary'}
                        size={'lg'}
                        type={'button'}
                        startIcon={props.onActionIcon ?? <AddCircleOutlined/>}
                        onClick={props.onAction}
                    >
                        {props.onActionMessage ?? 'Novo'}
                    </RegularButton>
                </GridItem>
            }
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color={props.treeHeaderColor}>
                        <GridContainer>
                            <GridItem xs={8} sm={8} md={8}>
                                <h4 className={props.classes.cardTitleWhite}>{props.title}</h4>
                            </GridItem>
                            {props.onSearch &&
                            <GridItem xs={4} sm={4} md={4}>
                                <SearchInput
                                    id={'MuiCTTableListSearchInput'}
                                    fullWidth={true}
                                    placeholder={'Pesquisar...'}
                                    onSearch={props.onSearch}
                                    ariaLabel={'search-box'}
                                />
                            </GridItem>
                            }
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12}
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      width: '100%',
                                      height: 400,
                                      borderBottomWidth: 1,
                                      borderBottomStyle: 'solid',
                                      borderBottomColor: 'rgb(224,224,224)',
                                  }}>
                            <PerfectScrollbar style={{width: '100%'}}>
                                <TreeView
                                    style={{width: '95%'}}
                                    className={props.classes.root}
                                    defaultCollapseIcon={<RemoveCircleOutlined color={'action'}/>}
                                    defaultExpandIcon={<AddCircleOutlined color={'action'}/>}
                                    defaultEndIcon={<HighlightOffOutlined color={'action'}/>}
                                >
                                    {generateTreeViewItems(props.items, props.onSelect)}
                                </TreeView>
                            </PerfectScrollbar>
                        </GridItem>
                        {props.actions &&
                        <GridItem xs={12} sm={12} md={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <props.actions/>
                        </GridItem>
                        }
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default withStyles(treeViewListStyle, {name: 'MuiCTTreeViewList'})(TreeViewList);

