import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {EscolaDto} from '../../../api/types';
import {handleErr} from '../handleErr';

export const schoolGet = (id: string): ThunkAction<Promise<EscolaDto | undefined>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<EscolaDto | undefined> => {
        dispatch(notify(
            'A obter informação. Por favor aguarde...',
            'primary',
            undefined,
            'bc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        let result: EscolaDto | undefined;
        try {
            result = await gesAvaliaApi.schoolGet(id);
            dispatch(dismiss());
        } catch (err) {
            dispatch(handleErr(err));
        }
        return result;
    };
};
