/**
 * Exception class used to return generic exceptions
 */
export class Exception {
    private readonly _message: string;

    /**
     * Constructor
     * @param message the errorPassword message
     */
    public constructor(message: string) {
        this._message = message;
    }

    /**
     * Returns the object converted to string
     */
    public toString(): string {
        return this._message;
    }
}
