import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {cardAvatarStyle} from '../../../styles';
import {CardAvatarProps} from './types';
import {withStyles} from '@material-ui/core';

const CardAvatar: FunctionComponent<CardAvatarProps> = (props: PropsWithChildren<CardAvatarProps>) => {
    const {classes, children, className, plain, profile, ...rest} = props;
    const classList: { [key: string]: boolean | undefined } = {
        [classes.cardAvatar]: true,
        [classes.cardAvatarProfile]: profile,
        [classes.cardAvatarPlain]: plain,
    };

    if (className) {
        classList[className] = true;
    }

    const cardAvatarClasses = classNames(classList);
    return (
        <div className={cardAvatarClasses} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(cardAvatarStyle, {name: 'MuiCTCardAvatar'})(CardAvatar);
