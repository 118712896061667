import {IconButton, ListItem, ListItemText, Tooltip, withStyles} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {CSSProperties, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {FixedSizeList} from 'react-window';
import {listStyle} from '../../../styles';
import {ListProps} from './types';

const DEFAULT_HEIGHT = 280;
const DEFAULT_ITEM_SIZE = 35;

const generateListItem = (items: Array<string>) => (props: { index: number; style?: CSSProperties }): ReactElement => {
    const labelId = `transfer-list-item-${items[props.index]}-label`;
    return (
        <ListItem
            style={props.style}
            key={items[props.index]} role="listitem" button
        >
            <ListItemText id={labelId} primary={items[props.index]}/>
        </ListItem>
    );
};

const List: FunctionComponent<ListProps> = (props: PropsWithChildren<ListProps>) => {
    const {classes, label, value} = props;

    return (
        <div className={classes.container}>
            <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                    <span>
                        {label}
                        {
                            props.informationalText &&
                            <Tooltip title={props.informationalText}>
                                <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </span>
                </legend>
                <div className={classes.innerContent}>
                    <FixedSizeList width={'100%'}
                                   height={props.height ?? DEFAULT_HEIGHT}
                                   itemCount={value.length}
                                   itemSize={props.itemSize ?? DEFAULT_ITEM_SIZE}
                                   outerElementType={PerfectScrollbar}>
                        {generateListItem(value)}
                    </FixedSizeList>
                </div>
            </fieldset>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(listStyle, {name: 'MuiCTList'})(List);
