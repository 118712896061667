import {blackColor, hexToRgb} from '../material-dashboard-react';
import {Styles} from '@material-ui/styles/withStyles';
import {Theme} from '@material-ui/core';

export const cardAvatarStyle: Styles<Theme, Record<string, unknown>, string> = {
    cardAvatar: {
        '&$cardAvatarProfile img': {
            width: '100%',
            height: 'auto',
        },
    },
    cardAvatarProfile: {
        maxWidth: '130px',
        maxHeight: '130px',
        margin: '-50px auto 0',
        borderRadius: '50%',
        overflow: 'hidden',
        padding: '0',
        boxShadow:
            '0 16px 38px -12px rgba(' +
            hexToRgb(blackColor) +
            ', 0.56), 0 4px 25px 0px rgba(' +
            hexToRgb(blackColor) +
            ', 0.12), 0 8px 10px -5px rgba(' +
            hexToRgb(blackColor) +
            ', 0.2)',
        '&$cardAvatarPlain': {
            marginTop: '0',
        },
    },
    cardAvatarPlain: {},
};
