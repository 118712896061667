import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {cardStyle} from '../../../styles';
import {CardProps} from './types';
import {withStyles} from '@material-ui/core';

const Card: FunctionComponent<CardProps> = (props: PropsWithChildren<CardProps>) => {
    const {classes, className, children, plain, profile, chart, ...rest} = props;
    const classList: { [key: string]: boolean | undefined } = {
        [classes.card]: true,
        [classes.cardPlain]: plain,
        [classes.cardProfile]: profile,
        [classes.cardChart]: chart,
    };

    if (className) {
        classList[className] = true;
    }

    const cardClasses = classNames(classList);
    return (
        <div className={cardClasses} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(cardStyle, {name: 'MuiCTCard'})(Card);
