import {Item} from '../../components/organisms';

/**
 * Extracts an object content from a form result
 * @param {Array<Item<T>>} result - The form result
 *
 * @returns {object} - The resulting object
 */
export function extractObjectFromResult<T extends string | number | boolean | FileList | Record<string, unknown> | Array<string | number> | null, Y extends Record<string, T>>(result: Array<Item<T>>): Y {
    const object: { [key: string]: T } = {};

    result.filter(i => i.state).forEach(i => {
        object[i.id] = i.state;
    });

    return object as Y;
}
