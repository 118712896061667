import {Routes} from '../types';

export const routes: Routes = {
    studentClasses: '/aluno_turmas',
    studentClassesByClass: '/aluno_turmas/turma/:id',
    students: '/alunos',
    schoolYears: '/ano_letivos',
    years: '/anos',
    studentEvaluations: '/avaliacao_alunos',
    evaluations: '/avaliacaos',
    evaluationTeachers: '/avaliacaos/:id/professors',
    evaluationStudents: '/avaliacaos/:id/alunos',
    evaluationParameters: '/avaliacaos/:id/parametros',
    evaluationStudentEvaluations: '/avaliacaos/:id/avaliacao_alunos',
    evaluationGrades: '/avaliacaos/:id/avaliacao',
    cycles: '/ciclos',
    disciplines: '/disciplinas',
    disciplineTeachers: '/disciplinas/:id/professors',
    domains: '/dominios',
    schools: '/escolas',
    schoolImport: '/escolas/:id/importar',
    groups: '/grupo_disciplinars',
    groupTeachers: '/grupo_disciplinars/:id/professors',
    login: '/login',
    logout: '/logout',
    media: '/media',
    sessions: '/sessions',
    parameters: '/parametros',
    parameterClassTeachers: '/parametro_turma_professors',
    periods: '/periodos',
    teachers: '/professors',
    classes: '/turmas',
    classTeachers: '/turmas/:id/professors',
    classDisciplines: '/turmas/:id/disciplinas',
    classStudents: '/turmas/:id/alunos',
    classParameters: '/turmas/:id/parametros',
    classTeacherParameters: '/turmas/:id/professor/parametros',
    classTeacherParameterClassTeachers: '/turmas/:id/professor/parametro_turma_professors',
    users: '/users',
    userResendConfirmation: '/users/:id/resend',
    resetRequest: '/reset/request',
    resetConfirm: '/reset/confirm',
};
