import {Route} from '../types';
import {RateReviewOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {EvaluationPaths} from './evaluationPaths';
import {Create, Edit, Evaluation, List} from '../../components/pages/Evaluation';
import {ReactElement} from 'react';
import {StudentEvaluationsPaths} from './studentEvaluationsPaths';
import {StudentEvaluations} from '../../components/pages/StudentEvaluations';
import {GradesPaths} from './gradesPaths';
import {Grades} from '../../components/pages/Grades';

export const evaluationPaths: Array<Route<BaseProps>> = [
    {
        key: EvaluationPaths.HOME,
        name: 'Avaliações',
        path: '/evaluations',
        icon: RateReviewOutlined,
        role: Roles.ROLE_USER,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Evaluation/>,
    },
    {
        key: EvaluationPaths.LIST,
        parent: EvaluationPaths.HOME,
        path: '/',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: EvaluationPaths.CREATE,
        parent: EvaluationPaths.HOME,
        path: '/new',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: EvaluationPaths.EDIT,
        parent: EvaluationPaths.HOME,
        path: '/edit/:evaluationId',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Edit,
    },
    {
        key: StudentEvaluationsPaths.HOME,
        parent: EvaluationPaths.HOME,
        path: '/view/:evaluationId/students',
        role: Roles.ROLE_USER,
        exact: false,
        hidden: true,
        component: (): ReactElement => <StudentEvaluations/>,
    },
    {
        key: GradesPaths.REPORT,
        parent: EvaluationPaths.HOME,
        path: '/view/:evaluationId/grades',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: Grades,
    },
];

