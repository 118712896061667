import {FunctionComponent, PropsWithChildren} from 'react';
import {AddCircleOutlined, ArrowBackIos} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';
import {
    Card,
    CardBody,
    CardHeader,
    CustomTable,
    Dialog,
    GridContainer,
    GridItem,
    RegularButton,
    SearchInput,
} from '../../atoms';
import {TableListProps} from './types';
import {withStyles} from '@material-ui/core';
import {tableListStyle} from '../../../styles';

const TableList: FunctionComponent<TableListProps> = (props: PropsWithChildren<TableListProps>) => {
    const {classes} = props;

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog title={props.dialogTitle} open={props.dialogOpen}
                        content={props.dialogContent}
                        onConfirm={props.onDialogConfirm}
                        onClose={props.onDialogCancel}
                />
            </GridItem>
            {
                props.onReturn &&
                <GridItem xs={6} sm={6} md={6}
                          style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <RegularButton
                        id={'MuiCTTableListReturnButton'}
                        variant={'contained'}
                        color={'white'}
                        size={'lg'}
                        type={'button'}
                        startIcon={<ArrowBackIos/>}
                        onClick={props.onReturn}
                    >
                        Voltar
                    </RegularButton>
                </GridItem>
            }
            {
                props.onAction &&
                <GridItem xs={props.onReturn ? 6 : 12} sm={props.onReturn ? 6 : 12} md={props.onReturn ? 6 : 12}
                          style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <RegularButton
                        id={'MuiCTTableListAddButton'}
                        variant={'contained'}
                        color={'primary'}
                        size={'lg'}
                        type={'button'}
                        startIcon={props.onActionIcon ?? <AddCircleOutlined/>}
                        onClick={props.onAction}
                    >
                        {props.onActionMessage ?? 'Novo'}
                    </RegularButton>
                </GridItem>
            }
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color={props.tableHeaderColor}>
                                <GridContainer>
                                    <GridItem xs={8} sm={8} md={8}>
                                        <h4 className={classes.cardTitleWhite}>{props.title}</h4>
                                        <p className={classes.cardCategoryWhite}>
                                            {props.subtitle}
                                        </p>
                                    </GridItem>
                                    {props.onSearch &&
                                    <GridItem xs={4} sm={4} md={4}>
                                        <SearchInput
                                            id={'MuiCTTableListSearchInput'}
                                            fullWidth={true}
                                            placeholder={'Pesquisar...'}
                                            onSearch={props.onSearch}
                                            ariaLabel={'search-box'}
                                        />
                                    </GridItem>
                                    }
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                <CustomTable
                                    tableHeaderColor={props.tableHeaderColor}
                                    tableHead={props.listHeader}
                                    tableData={props.listData}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </GridItem>
            {props.totalNumberOfPages && props.totalNumberOfPages > 0 && props.onPageChange ?
                <GridItem xs={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
                    <Pagination page={props.page} count={props.totalNumberOfPages} showFirstButton showLastButton
                                onChange={props.onPageChange}/>
                </GridItem> :
                <></>
            }
        </GridContainer>
    );
};

export default withStyles(tableListStyle, {name: 'MuiCTTableList'})(TableList);
