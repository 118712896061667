import {ErrorOutlined} from '@material-ui/icons';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {HttpStatus} from '../../api/enums';
import {DEFAULT_ERROR_TIMEOUT} from '../../base/constants';
import {Store} from '../types';
import {Action} from './index';
import {dismiss, notify} from './notify';
import {UnauthorizedException, UriException} from '../../exceptions';
import {SIGN_OUT} from '../constants';

export const handleErr = (err: unknown | Error | UriException): ThunkAction<void, Store, unknown, Action> => {
    return (dispatch: ThunkDispatch<Store, unknown, Action>): void => {
        let message = err instanceof Error ? err.toString() : 'Não foi possível proceder de momento, por favor tente mais tarde!';
        if (err instanceof UriException) {
            switch (err.getCode()) {
                case HttpStatus.UNAUTHORIZED:
                    message = 'A sua autenticação expirou. Por favor autentique-se novamente para proceder!';
                    break;
                case HttpStatus.FORBIDDEN:
                    message = 'Não tem acesso ao recurso indicado. Por favor contacte o administrador para mais informações!';
                    break;
                default:
                    message = err.toString();
                    break;
            }
        }
        dispatch(dismiss());
        dispatch(notify(
            message,
            'danger',
            ErrorOutlined,
            'tc',
            true,
        ));
        setTimeout(() => dispatch(dismiss()), DEFAULT_ERROR_TIMEOUT);
        if (err instanceof UnauthorizedException) {
            dispatch({
                type: SIGN_OUT,
                session: null,
            });
        }
    };
};
