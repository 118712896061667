import {ReactElement} from 'react';
import {DataTypeProvider, DataTypeProviderProps} from '@devexpress/dx-react-grid';
import {BooleanFormatter} from '../formatters';
import {BooleanEditor} from '../editors';

export const BooleanTypeProvider = (props: DataTypeProviderProps): ReactElement =>
    <DataTypeProvider
        formatterComponent={BooleanFormatter}
        editorComponent={BooleanEditor}
        {...props}
    />;
