import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../types';
import {Action} from './index';
import {Roles} from '../../base';

export const gate = (role: Roles, callback: (...args: Array<unknown>) => void): ThunkAction<((...args: Array<unknown>) => void) | undefined, Store, unknown, Action> => {
    return (_dispatch: ThunkDispatch<Store, unknown, Action>, getState: () => Store): (() => void) | undefined => {
        if (getState().session?.user.roles?.includes(role)) {
            return callback;
        }
        return undefined;
    };
};

