import {SIGN_IN} from '../constants';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {dismiss, notify} from './notify';
import {SessionDto} from '../../api/types';
import {handleErr} from './handleErr';
import {Store} from '../types';
import {Action} from './index';
import {getApi} from './getApi';

export type SignIn = {
    type: SIGN_IN;
    session: SessionDto;
};

export const signIn = (username: string, password: string): ThunkAction<Promise<boolean>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<boolean> => {
        dispatch(notify(
            'A iniciar sessão. Por favor aguarde...',
            'primary',
            undefined,
            'tc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        try {
            const sessionDto: SessionDto = await gesAvaliaApi.login(username, password);
            dispatch(dismiss());
            dispatch({
                type: SIGN_IN,
                session: sessionDto,
            });

            return true;
        } catch (err) {
            dispatch(handleErr(err));
            return false;
        }
    };
};
