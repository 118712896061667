import {Exception} from './Exception';

/**
 * Generic class used to return endpoint exceptions
 */
export class UriException extends Exception {
    private readonly _code: number;

    /**
     * Constructor
     *
     * @param message the exception message
     * @param code the response code returned by the URI
     */
    public constructor(message: string, code: number) {
        super(message);
        this._code = code;
    }

    /**
     * Returns the response code
     */
    public getCode(): number {
        return this._code;
    }
}
