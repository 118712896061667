import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {AvaliacaoDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {CreateState} from '../../types';

class CreateContainer extends Component<BaseProps, CreateState> {
    public state: Readonly<CreateState> = {};

    public render(): ReactNode {
        return (
            <Profile
                title={'Registar nova avaliação'}
                evaluationProfile={this.state.evaluation}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | number | Array<string>>>): Promise<void> => {
        const avaliacaoDto: AvaliacaoDto = extractObjectFromResult<string | number | Array<string>, AvaliacaoDto>(result);
        this.setState({evaluation: avaliacaoDto});

        if (this.props.session &&
            await this.props.evaluation?.create?.(avaliacaoDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(CreateContainer);
