import {ThunkDispatch} from 'redux-thunk';
import {Store} from '../types';
import {Action} from './index';
import {Scheme} from '../../api/enums';
import {GesAvaliaApi} from '../../api/ges-avalia-api';

export const getApi = (): (_dispatch: ThunkDispatch<Store, unknown, Action>, getState: () => Store) => GesAvaliaApi => {
    return (_dispatch: ThunkDispatch<Store, unknown, Action>, getState: () => Store): GesAvaliaApi => {
        return new GesAvaliaApi(Scheme.Bearer, getState()?.session?.access_token);
    };
};
