import {Chip, IconButton, Tooltip, withStyles} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {FunctionComponent, PropsWithChildren} from 'react';
import {chipsStyle} from '../../../styles';
import {ChipsProps} from './types';


const Chips: FunctionComponent<ChipsProps> = (props: PropsWithChildren<ChipsProps>) => {
    const {classes, label, value} = props;

    return (
        <div className={classes.container}>
            <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                    <span>
                        {label}
                        {
                            props.informationalText &&
                            <Tooltip title={props.informationalText}>
                                <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </span>
                </legend>
                <div className={classes.innerContent}>
                    {value.map(v => <Chip style={{margin: 2, padding: 5}} key={v} label={v}/>)}
                </div>
            </fieldset>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(chipsStyle, {name: 'MuiCTChips'})(Chips);
