import {Component, ReactNode} from 'react';
import {BaseProps, withAll} from '../../../base';
import {FormBuilder, FormItem, Item} from '../../organisms/Builder';
import {signInFormDefinition} from './sign-in.form.definition';
import {LockOpen} from '@material-ui/icons';
import _ from 'lodash';
import {DEFAULT_PATH, generateLink} from '../../../router';
import {ResetPaths} from '../../../router/paths/resetPaths';

class SignInContainer extends Component<BaseProps> {
    private _formDefinition: Array<FormItem> = _.cloneDeep(signInFormDefinition);

    public render(): ReactNode {
        return (
            <FormBuilder
                title={'Introduza os seus dados de acesso'}
                autoComplete={'off'}
                onSubmitButtonText={'Iniciar sessão'}
                onSubmitButtonIcon={<LockOpen/>}
                items={this._formDefinition}
                onSubmit={this._onSubmit}
                onCancel={this._resetPassword}
                onCancelButtonText={'Recuperar acesso'}
            />
        );
    }

    private _onSubmit = async (result: Array<Item>): Promise<void> => {
        const username = result.find(value => value.id === 'username')?.state ?? '';
        const password = result.find(value => value.id === 'password')?.state ?? '';

        if (await this.props.signIn?.(username, password)) {
            this.props.history.push(generateLink(DEFAULT_PATH));
        }
    };

    private _resetPassword = (): void => {
        this.props.history.push(generateLink(ResetPaths.REQUEST));
    };
}

export default withAll(SignInContainer);

