import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {generateFormDefinition} from './generate.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapOptionsToForm, Roles} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {AlunoDto, ParametroDto, PeriodoDto} from '../../../../../api/types';
import {withStore} from '../../../../../redux';
import {routes} from '../../../../../api/constants';


const GenerateContainer: FunctionComponent<ProfileProps<Array<string>>> = (props: PropsWithChildren<ProfileProps<Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.evaluation?.studentList?.(props.evaluationId),
                props.parameter?.list?.(),
                props.evaluation?.parameterList?.(props.evaluationId),
                props.period?.list?.(),
            ]);

            const studentOptions: Array<SelectItem> = results?.[0]?.map((student: AlunoDto) => {
                return {
                    key: `${routes.students}/${student.id ?? 0}`,
                    value: student.nome ?? '',
                };
            }) ?? [];

            const parameterOptions: Array<SelectItem> = results?.[props.session.user.roles?.includes(Roles.ROLE_ADMIN) ? 1 : 2]?.map((parameter: ParametroDto) => {
                return {
                    key: `${routes.parameters}/${parameter.id ?? 0}`,
                    value: `${parameter.nome} - ${parameter.percentagem}%`,
                };
            }) ?? [];

            const periodOptions: Array<SelectItem> = results?.[3]?.map((period: PeriodoDto) => {
                return {
                    key: `${routes.periods}/${period.id ?? 0}`,
                    value: period.nome ?? '',
                };
            }) ?? [];

            setSelectOptions([
                {
                    id: 'alunos',
                    options: studentOptions,
                },
                {
                    id: 'parametros',
                    options: parameterOptions,
                },
                {
                    id: 'periodos',
                    options: periodOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.evaluationId]);


    let _formDefinition: Array<FormItem<Array<string>>> = _.cloneDeep(generateFormDefinition);

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Gerar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(GenerateContainer);

