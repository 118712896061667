import {FunctionComponent, ReactElement} from 'react';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';
import {AddBoxOutlined} from '@material-ui/icons';
import {RegularButton} from '../../../atoms';
import {Box, Tooltip} from '@material-ui/core';

export const AddButton: FunctionComponent<TableEditColumnBase.CommandProps> = (props: TableEditColumnBase.CommandProps): ReactElement => {
    return (
        <Tooltip title={'Clique para adicionar um novo registo'}>
            <Box component={'span'}>
                <RegularButton
                    color="primary"
                    onClick={props.onExecute}
                    startIcon={<AddBoxOutlined/>}
                >
                    Novo
                </RegularButton>
            </Box>
        </Tooltip>
    );
};
