import {Theme} from '@material-ui/core';
import {Styles} from '@material-ui/styles/withStyles';

export const textStyle: Styles<Theme, Record<string, unknown>, string> = {
    container: {
        marginTop: 16,
        marginBottom: 8,
    },
    fieldset: {
        borderRadius: 4,
        borderColor: 'rgba(0,0,0,0.23)',
        borderWidth: 1,
    },
    legend: {
        fontSize: '0.85em',
        fontWeight: 400,
        color: 'rgba(0,0,0,0.53)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginBottom: 0,
    },
    innerText: {
        margin: 0,
        lineHeight: '1.5em',
        minHeight: '1.7em',
    },
};
