import {ChangeEvent, FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import {TableList} from '../TableList';
import {PaginatedTableListProps} from './types';

const PaginatedTableList: FunctionComponent<PaginatedTableListProps> = (props: PropsWithChildren<PaginatedTableListProps>) => {
    const [itemsPerPage] = useState(props.itemsPerPage ?? 10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(Math.ceil(props.listData.length / itemsPerPage));
    const [paginatedData, setPaginatedData] = useState(props.listData.slice((currentPage - 1) * itemsPerPage, itemsPerPage * currentPage));

    const setData = (): void => {
        const totalNrPages = Math.ceil(props.listData.length / itemsPerPage);
        setTotalNumberOfPages(totalNrPages);

        if (currentPage > totalNrPages) {
            setCurrentPage(1);
            setPaginatedData(props.listData.slice(0, itemsPerPage));
        } else {
            setPaginatedData(props.listData.slice((currentPage - 1) * itemsPerPage, itemsPerPage * currentPage));
        }
    };

    useEffect(() => {
        setData();
    }, [props.listData]);

    const _onPageChange = (_event: ChangeEvent<unknown>, selectedPage: number): void => {
        if (props.listData) {
            setCurrentPage(selectedPage);
            setPaginatedData(props.listData.slice((selectedPage - 1) * itemsPerPage, itemsPerPage * selectedPage));
        }
    };

    return (
        <TableList
            {...props}
            page={currentPage}
            listData={paginatedData}
            totalNumberOfPages={totalNumberOfPages}
            onPageChange={_onPageChange}
        />
    );
};

export default PaginatedTableList;
