import {
    Checkbox as CheckboxItem,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    makeStyles,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    AddCircleOutlined,
    AutorenewOutlined,
    HighlightOffOutlined,
    InfoOutlined,
    RemoveCircleOutlined,
} from '@material-ui/icons';
import {TreeItem as MuTreeItem, TreeView as MuTreeView} from '@material-ui/lab';
import {ChangeEvent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Dialog, GridContainer, GridItem, RegularButton, SearchInput} from '../../../atoms';
import {ItemProps, SelectItem, TreeViewItem} from '../types';

const TreeView = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(props.item.options ?? []);
    const [selected, setSelected] = useState<Array<string>>([]);
    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                width: '100%',
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 15,
                paddingRight: 15,
                margin: '0 auto',
                '& > li div div:nth-child(2)': {
                    lineHeight: 2,
                    width: 'auto',
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginLeft: 0,
                },
            },
        }),
    );

    useEffect(() => {
        const selectedOptions: Array<string> = (props.item.state ?? []) as Array<string>;
        const selectOptions: Array<SelectItem> = props.item.options ?? [];

        setOptions([...selectOptions]);
        setSelected([...selectedOptions]);
    }, [props.item.state]);


    const _onChangeHandler = (): void => {
        props.onChange?.(props.item.id, selected as T);
        setOpen(false);
    };

    const _onCloseHandler = (): void => {
        const selectedOptions: Array<string> = (props.item.state ?? []) as Array<string>;
        setSelected([...selectedOptions]);
        setOpen(false);
    };

    const onSelectedChange = (key: string) => (_event: ChangeEvent<Record<string, unknown>>, checked: boolean): void | undefined => {
        if (checked) {
            setSelected([
                ...selected,
                key,
            ]);
        } else {
            setSelected([...selected.filter(s => s.toString() !== key.toString())]);
        }
    };

    const generateTreeViewItems = (items: Array<TreeViewItem>, onSelect?: (id: string | number) => void): Array<ReactElement> => {
        return items.map((item: TreeViewItem) => {
            return (
                <MuTreeItem onClick={(): void => onSelect?.(item.key)}
                            key={item.key}
                            icon={!item.visited ? <AutorenewOutlined color={'action'}/> : undefined}
                            nodeId={item.key.toString()}
                            label={item.value}>
                    {
                        item.children &&
                        generateTreeViewItems(item.children, onSelect)
                    }
                </MuTreeItem>
            );
        });
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        setOptions([
            ...props.item.options?.filter(
                s => s.value?.toLowerCase()?.includes(event.target.value.toLowerCase(),
                )) ?? [],
        ]);
    };

    const generateDialogContent = (): ReactElement => {
        return (
            <FormControl fullWidth={true}>
                <SearchInput
                    id={'MuiCTTreeViewSearchInput'}
                    fullWidth={true}
                    placeholder={'Pesquisar...'}
                    onSearch={onSearch}
                    ariaLabel={'search-box'}
                />
                <FormGroup style={{
                    height: 200,
                    minWidth: 250,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgb(128,128,128)',
                    borderRadius: '3px',
                    marginTop: 10,
                    marginBottom: 5,
                    padding: 10,
                }}>
                    <PerfectScrollbar style={{width: '100%'}}>
                        {
                            options?.map((selectItem: SelectItem) => {
                                return (
                                    <FormControlLabel
                                        style={{display: 'flex'}}
                                        key={selectItem.key.toString()}
                                        checked={!!selectItem.key && selected.some(s => s.toString() === selectItem.key.toString())}
                                        value={selectItem.key.toString()}
                                        control={<CheckboxItem/>}
                                        label={selectItem.value}
                                        labelPlacement="end"
                                        onChange={onSelectedChange(selectItem.key.toString())}
                                    />
                                );
                            })
                        }
                    </PerfectScrollbar>
                </FormGroup>
            </FormControl>
        );
    };

    return (
        <GridContainer
            direction={'column'}
            style={{paddingTop: 20, paddingBottom: 20}}
        >
            <GridItem xs={12} sm={12} md={12}>
                <Dialog title={props.item.treeOptions?.onEditButtonText ?? 'Editar'}
                        onClose={_onCloseHandler}
                        open={open}
                        content={generateDialogContent()}
                        onConfirm={_onChangeHandler}
                />
            </GridItem>
            <GridItem style={{
                display: 'flex',
                marginBottom: 10,
                marginLeft: 15,
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Typography style={{fontSize: '0.8rem'}}
                            component={'label'}>
                    {props.item.label}{props.item.required ? ' *' : ''}
                    {
                        props.item.informationalText &&
                        <Tooltip title={props.item.informationalText}>
                            <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                        aria-label={'informational-text'}
                            >
                                <InfoOutlined style={{fontSize: '1.0rem'}}/>
                            </IconButton>
                        </Tooltip>
                    }
                </Typography>
                <RegularButton
                    variant="contained"
                    startIcon={props.item.treeOptions?.onEditButtonIcon}
                    onClick={(): void => setOpen(true)}
                    color="primary"
                    size={'sm'}
                    type={'button'}
                >
                    {props.item.treeOptions?.onEditButtonText ?? 'Editar'}
                </RegularButton>
            </GridItem>
            <GridItem>
                <Paper style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: 400}}>
                    <PerfectScrollbar style={{width: '100%'}}>
                        <MuTreeView
                            style={{width: '95%'}}
                            ref={props.item.innerRef}
                            id={props.item.id}
                            disableSelection={props.item.disabled}
                            aria-required={props.item.required}
                            className={useStyles().root}
                            defaultCollapseIcon={<RemoveCircleOutlined color={'action'}/>}
                            defaultExpandIcon={<AddCircleOutlined color={'action'}/>}
                            defaultEndIcon={<HighlightOffOutlined color={'action'}/>}
                        >
                            {generateTreeViewItems((props.item.treeState ?? []) as Array<TreeViewItem>, props.item.treeOptions?.onSelect)}
                        </MuTreeView>
                    </PerfectScrollbar>
                </Paper>
            </GridItem>
            <GridItem style={{marginTop: 8, marginLeft: 15}}>
                <Typography style={{fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.54)'}}
                            component={'p'}>{props.item.helperText}</Typography>
            </GridItem>
        </GridContainer>
    );
};

export default TreeView;
