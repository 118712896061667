import {FormItem, SelectItem} from '../../components/organisms';

/**
 * Maps an array of options to a specific item in the form definition.
 * @param {string} id - the item identifier that will receive the options
 * @param {Array<SelectItem>} options - the options to map
 * @param {Array<FormItem<T>>} form - The form definition to map to
 *
 * @return {Array<FormItem>} - The form definition with the mapped options
 */
export function mapOptionsToForm<T extends string | number | boolean | Record<string, unknown> | Array<string | number> | null>(id: string, options: Array<SelectItem>, form: Array<FormItem<T>>): Array<FormItem<T>> {
    return form.map(fi => {
        if (fi.id === id) {
            fi.options = options;
        }
        return fi;
    });
}
