import {Route} from '../types';
import {MenuBookOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {Create, Discipline, Edit, List} from '../../components/pages/Discipline';
import {ReactElement} from 'react';
import {DisciplinePaths} from './disciplinePaths';

export const disciplinePaths: Array<Route<BaseProps>> = [
    {
        key: DisciplinePaths.HOME,
        name: 'Disciplinas',
        path: '/disciplines',
        icon: MenuBookOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Discipline/>,
    },
    {
        key: DisciplinePaths.LIST,
        parent: DisciplinePaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: DisciplinePaths.CREATE,
        parent: DisciplinePaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: DisciplinePaths.EDIT,
        parent: DisciplinePaths.HOME,
        path: '/edit/:disciplineId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

