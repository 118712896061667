import {FunctionComponent, MouseEvent as ReactMouseEvent, PropsWithChildren, ReactElement, useState} from 'react';
import classNames from 'classnames';
import {
    ClickAwayListener,
    Divider,
    Grow,
    Hidden,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    withStyles,
} from '@material-ui/core';
import {Person} from '@material-ui/icons';
import {headerLinksStyle} from '../../../styles';
import {RegularButton} from '../../atoms/Button';
import {ReferenceObject} from 'popper.js';
import {NavbarLinksProps} from './types';
import {withStore} from '../../../redux';
import {generateLink, SIGN_IN_PATH} from '../../../router';
import {ProfilePaths} from '../../../router/paths/profilePaths';
import {withRouter} from 'react-router-dom';


const AdminNavbarLinks: FunctionComponent<NavbarLinksProps> = (props: PropsWithChildren<NavbarLinksProps>) => {
    const {classes} = props;
    const [openProfile, setOpenProfile] = useState<EventTarget | null>(null);

    const handleClickProfile = (event: ReactMouseEvent<unknown, MouseEvent>): void => {
        if (openProfile === event.target) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };

    const handleCloseProfile = (): void => {
        setOpenProfile(null);
    };

    const editProfile = (): void => {
        setOpenProfile(null);
        if (props.history) {
            props.history.push(generateLink(ProfilePaths.HOME));
        }
    };

    const signOut = async (): Promise<void> => {
        setOpenProfile(null);
        if (await props.signOut?.()) {
            props.history.push(generateLink(SIGN_IN_PATH));
        }
    };

    return (
        <div>
            <div className={classes.manager}>
                <RegularButton
                    color={'primary'}
                    aria-owns={openProfile ? 'profile-menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={classes.buttonLink}
                >
                    <Person className={classes.icons}/>
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Perfil</p>
                    </Hidden>
                </RegularButton>
                <Popper
                    open={Boolean(openProfile)}
                    anchorEl={openProfile as unknown as ReferenceObject}
                    transition
                    disablePortal
                    className={
                        classNames({[classes.popperClose]: !openProfile}) +
                        ' ' +
                        classes.popperNav
                    }
                >
                    {({TransitionProps, placement}): ReactElement =>
                        <Grow
                            {...TransitionProps}
                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                            // @ts-ignore
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        <MenuItem
                                            onClick={editProfile}
                                            className={classes.dropdownItem}
                                        >
                                            Perfil
                                        </MenuItem>
                                        <Divider light/>
                                        <MenuItem
                                            onClick={signOut}
                                            className={classes.dropdownItem}
                                        >
                                            Terminar sessão
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    }
                </Popper>
            </div>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(headerLinksStyle, {name: 'MuiCTNavbarLinks'})(withStore(withRouter(AdminNavbarLinks)));
