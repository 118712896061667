import {Route} from '../types';
import {HowToRegOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {ParameterPaths} from './parameterPaths';
import {Create, Edit, List, Parameter} from '../../components/pages/Parameter';
import {ReactElement} from 'react';

export const parameterPaths: Array<Route<BaseProps>> = [
    {
        key: ParameterPaths.HOME,
        name: 'Parâmetros',
        path: '/parameters',
        icon: HowToRegOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Parameter/>,
    },
    {
        key: ParameterPaths.LIST,
        parent: ParameterPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: ParameterPaths.CREATE,
        parent: ParameterPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: ParameterPaths.EDIT,
        parent: ParameterPaths.HOME,
        path: '/edit/:parameterId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

