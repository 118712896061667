import {FunctionComponent, PropsWithChildren} from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import {footerStyle} from '../../../styles';
import {withStyles} from '@material-ui/core';
import {FooterProps} from './types';

const Footer: FunctionComponent<FooterProps> = (props: PropsWithChildren<FooterProps>) => {
    const {classes} = props;

    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="/" className={classes.block}>
                                Início
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="https://hgpestana.dev?ref=gesavalia"
                               target="_blank"
                               rel="noreferrer"
                               className={classes.block}>
                                Projeto
                            </a>
                        </ListItem>
                    </List>
                </div>
                <p className={classes.right}>
          <span>
            &copy; {new Date().getFullYear()}{' '}
              <a
                  href="https://hgpestana.dev?ref=gesavalia"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.a}
              >
              GESAVALIA&nbsp;&nbsp;&nbsp;
            </a>
          </span>
                </p>
            </div>
        </footer>
    );
};

export default withStyles(footerStyle, {name: 'MuiCTFooter'})(Footer);
