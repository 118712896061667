import {SIGN_OUT} from '../constants';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {dismiss, notify} from './notify';
import {handleErr} from './handleErr';
import {Store} from '../types';
import {Action} from './index';
import {getApi} from './getApi';

export type SignOut = {
    type: SIGN_OUT;
    session: null;
};

export const signOut = (): ThunkAction<Promise<boolean>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<boolean> => {
        dispatch(notify(
            'A terminar sessão. Por favor aguarde...',
            'primary',
            undefined,
            'tc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        try {
            await gesAvaliaApi.logout();
            dispatch(dismiss());
            dispatch({
                type: SIGN_OUT,
                session: null,
            });

            return true;
        } catch (err) {
            dispatch(handleErr(err));
            return false;
        }
    };
};
