import {Component, Fragment, ReactElement, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, Roles, withAll} from '../../../../../base';
import {ManageState} from '../../types';
import {Column, DataGrid, isSelectable, Item, Selectable} from '../../../../organisms';
import {ChangeSet, EditingState, TableColumnWidthInfo} from '@devexpress/dx-react-grid';
import {AlunoDto, AvaliacaoAlunoDto, GradesDto, ParametroDto, PeriodoDto} from '../../../../../api/types';
import {routes} from '../../../../../api/constants';
import {Generate} from '../Generate';
import {FlipCameraAndroidOutlined, WarningOutlined} from '@material-ui/icons';
import {Snackbar} from '../../../../atoms';
import moment from 'moment';

class ManageContainer extends Component<BaseProps, ManageState> {
    public state: Readonly<ManageState> = {
        alertOpen: false,
        dialogOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._getEvaluation();
        await this._setItems();
        await this._setGrades();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ManageState>): void {
        if (prevState.studentEvaluations !== this.state.studentEvaluations) {
            this._setRowsData();
        }
    }

    public render(): ReactNode {
        return (
            <DataGrid
                title={`Lista de notas ${this.state.turma?.nome ?? ''} - ${this.state.discipline?.nome ?? ''}`}
                gridHeaderColor={'primary'}
                columns={this._getColumns()}
                getRowId={this._getRowId}
                columnExtensions={this._getColumnExtensions()}
                defaultColumnWidths={this._getDefaultColumnWidths()}
                numberColumns={['nota']}
                booleanColumns={['excluida']}
                rows={this.state.rows}
                availableRowValues={this.state.availableRowValues}
                initialSortColumn={'aluno'}
                hiddenColumns={['id']}
                onReturn={this._onReturn}
                onAction={this._openDialog}
                onActionIcon={<FlipCameraAndroidOutlined/>}
                onActionMessage={'Gerar notas'}
                onCommitChanges={this._onCommitChanges}
                dialogOpen={this.state.dialogOpen}
                dialogContent={this._generateMultipleGradesForm()}
            />
        );
    }

    private _generateMultipleGradesForm = (): ReactElement => {
        return (
            <Fragment>
                <Snackbar
                    place={'tc'}
                    color={'warning'}
                    icon={WarningOutlined}
                    message={'Todos os campos são de preenchimento obrigatório'}
                    open={true}
                    close={false}
                    progress={false}
                />
                <Generate
                    onSubmit={this._handleMultipleGradesForm}
                    onCancel={this._closeDialog}
                    title={'Gerar notas'}
                    evaluationId={this.props.match.params.evaluationId ?? ''}
                />
            </Fragment>
        );
    };

    private _closeDialog = (): void => {
        this.setState({
            dialogOpen: false,
        });
    };

    private _openDialog = (): void => {
        this.setState({
            dialogOpen: true,
        });
    };

    private _handleMultipleGradesForm = async (data: Array<Item<Array<string>>>): Promise<void> => {
        const grades: GradesDto = extractObjectFromResult<Array<string>, GradesDto>(data);
        const chunkSize = 20;

        if (grades.alunos.length === 0 || grades.parametros.length === 0 || grades.periodos.length === 0) {
            return;
        }

        const avaliacoesAlunos: Array<AvaliacaoAlunoDto> = [];
        const evaluationId = this.props.match.params.evaluationId;

        grades.alunos.forEach(aluno => {
            grades.parametros.forEach(parametro => {
                grades.periodos.forEach(periodo => {
                    avaliacoesAlunos.push({
                        aluno: aluno,
                        avaliacao: `${routes.evaluations}/${evaluationId ?? 0}`,
                        periodo: periodo,
                        parametro: parametro,
                        nota: '0',
                        excluida: false,
                    });
                });
            });
        });

        while (avaliacoesAlunos.length > 0) {
            const batch = avaliacoesAlunos.splice(0, chunkSize);
            await this._processGradesBatch(batch);
        }

        this._closeDialog();

        await this._setItems();
        await this._setGrades();
    };

    private _processGradesBatch = async (avaliacoesAlunos: Array<AvaliacaoAlunoDto>): Promise<void> => {
        await Promise.all(avaliacoesAlunos.map(avaliacaoAluno => {
            return this.props.studentEvaluation?.create?.(avaliacaoAluno);
        }));
    };

    private _getColumns = (): Array<Column> => {
        return [
            {
                name: 'id',
                title: 'ID',
            },
            {
                name: 'aluno',
                title: 'Aluno',
                required: true,
                getCellValue: (row: { [key: string]: Selectable }): string | number | boolean => row.aluno?.value,
            },
            {
                name: 'parametro',
                title: 'Parametro',
                required: true,
                getCellValue: (row: { [key: string]: Selectable }): string | number | boolean => row.parametro?.value,
            },
            {
                name: 'nota',
                title: 'Nota',
                required: true,
            },
            {
                name: 'periodo',
                title: 'Periodo',
                required: true,
                getCellValue: (row: { [key: string]: Selectable }): string | number | boolean => row.periodo?.value,
            },
            {
                name: 'excluida',
                title: 'Justificada',
            },
            {
                name: 'updateDate',
                title: 'Data',
            },
        ];
    };

    private _getColumnExtensions = (): Array<EditingState.ColumnExtension> => {
        return [
            {
                columnName: 'id',
                editingEnabled: false,
            },
            {
                columnName: 'aluno',
                createRowChange: (_row: { [key: string]: Selectable }, value: Selectable): { [key: string]: Selectable } => ({
                    aluno: {
                        ...value,
                    },
                }),
            },
            {
                columnName: 'parametro',
                createRowChange: (_row: { [key: string]: Selectable }, value: Selectable): { [key: string]: Selectable } => ({
                    parametro: {
                        ...value,
                    },
                }),
            },
            {
                columnName: 'nota',
            },
            {
                columnName: 'periodo',
                createRowChange: (_row: { [key: string]: Selectable }, value: Selectable): { [key: string]: Selectable } => ({
                    periodo: {
                        ...value,
                    },
                }),
            },
            {
                columnName: 'excluida',
            },
            {
                columnName: 'updateDate',
                editingEnabled: false,
            },
        ];
    };

    private _getDefaultColumnWidths = (): Array<TableColumnWidthInfo> => {
        return [
            {
                columnName: 'id',
                width: 0,
            },
            {
                columnName: 'aluno',
                width: '20%',
            },
            {
                columnName: 'parametro',
                width: '20%',
            },
            {
                columnName: 'nota',
                width: '10%',
            },
            {
                columnName: 'periodo',
                width: '10%',
            },
            {
                columnName: 'excluida',
                width: '11%',
            },
            {
                columnName: 'updateDate',
                width: '13%',
            },
        ];
    };

    private _setGrades = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.evaluationId) {
            const results = await Promise.all([
                this.props.evaluation?.studentEvaluationList?.(this.props.match.params.evaluationId),
            ]);
            this.setState({studentEvaluations: results[0] ?? []});
        }
    };

    private _getEvaluation = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.evaluationId) {
            const results = await Promise.all([
                this.props.evaluation?.get?.(this.props.match.params.evaluationId),
            ]);
            this.setState({
                evaluation: results?.[0],
            });
        }
    };

    private _setItems = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.evaluationId && this.state.evaluation?.turma && this.state.evaluation.disciplina) {
            const results = await Promise.all([
                this.props.evaluation?.studentList?.(this.props.match.params.evaluationId),
                this.props.parameter?.list?.(),
                this.props.evaluation?.parameterList?.(this.props.match.params.evaluationId),
                this.props.period?.list?.(),
                this.props.class?.get?.(this.state.evaluation?.turma.split('/')?.[2]),
                this.props.discipline?.get?.(this.state.evaluation.disciplina.split('/')?.[2]),
            ]);

            const studentOptions: Array<Selectable> = results?.[0]?.map((student: AlunoDto) => {
                return {
                    key: `${routes.students}/${student.id ?? 0}`,
                    value: student.nome ?? '',
                };
            }) ?? [];

            const parameterOptions: Array<Selectable> = results?.[this.props.session.user.roles?.includes(Roles.ROLE_ADMIN) ? 1 : 2]?.map((parameter: ParametroDto) => {
                return {
                    key: `${routes.parameters}/${parameter.id ?? 0}`,
                    value: `${parameter.nome} - ${parameter.percentagem}%`,
                };
            }) ?? [];

            const periodOptions: Array<Selectable> = results?.[3]?.map((period: PeriodoDto) => {
                return {
                    key: `${routes.periods}/${period.id ?? 0}`,
                    value: period.nome ?? '',
                };
            }) ?? [];

            this.setState({
                availableRowValues: {
                    aluno: studentOptions,
                    parametro: parameterOptions,
                    periodo: periodOptions,
                },
                turma: results?.[4],
                discipline: results?.[5],
            });
        }
    };

    private _setRowsData = (): void => {
        const studentEvaluations = this.state.studentEvaluations;
        const availableRowValues = this.state.availableRowValues;

        this.setState({
            rows: studentEvaluations?.map(studentEvaluation => {
                return {
                    id: studentEvaluation.id ?? '',
                    aluno: availableRowValues?.aluno?.find(v => v.key === studentEvaluation.aluno) ?? null,
                    parametro: availableRowValues?.parametro?.find(v => v.key === studentEvaluation.parametro) ?? null,
                    nota: studentEvaluation.nota ?? 0,
                    periodo: availableRowValues?.periodo?.find(v => v.key === studentEvaluation.periodo) ?? null,
                    excluida: studentEvaluation.excluida ?? false,
                    editDisabled: studentEvaluation.bloqueada ?? false,
                    deleteDisabled: studentEvaluation.bloqueada ?? false,
                    updateDate: moment(studentEvaluation.updateDate ?? new Date()).format('DD/MM/YY HH:mm'),
                };
            }),
        });
    };

    private _getRowId = (row: { [key: string]: string | number | boolean | Record<string, unknown> | null }): number | string => {
        return 'id' in row ? row.id?.toString() ?? -1 : -1;
    };

    private _onCommitChanges = async (changes: ChangeSet): Promise<void> => {
        await this._handleDelete(changes.deleted);
        await this._handleChanged(changes.changed);
        await this._handleAdded(changes.added);
    };

    private _handleAdded = async (data?: ReadonlyArray<{ [key: string]: string | number | boolean | Selectable | null }>): Promise<void> => {
        const evaluationId = this.props.match.params.evaluationId;

        if (evaluationId && data) {
            const rows = this.state.rows;
            this.setState({
                rows: [
                    ...rows ?? [],
                    ...data.map(d => {
                        return {
                            id: 'not-available',
                            aluno: d.aluno,
                            parametro: d.parametro,
                            nota: d.nota ?? 0,
                            periodo: d.periodo,
                            excluida: d.excluida ?? false,
                        } as AvaliacaoAlunoDto;
                    }),
                ],
            });

            const result = await Promise.all(data.map(d => {
                return this.props.studentEvaluation?.create?.({
                    aluno: isSelectable(d.aluno) ? d.aluno.key.toString() : undefined,
                    parametro: isSelectable(d.parametro) ? d.parametro.key.toString() : undefined,
                    nota: (d.nota ?? 0).toString(),
                    periodo: isSelectable(d.periodo) ? d.periodo.key.toString() : undefined,
                    avaliacao: `${routes.evaluations}/${evaluationId ?? 0}`,
                    excluida: !!d.excluida,
                });
            }));

            if (result.includes(undefined)) {
                this.setState({
                    rows: [...rows ?? []],
                });
            } else {
                this.setState({
                    studentEvaluations: [
                        ...this.state.studentEvaluations ?? [],
                        ...result as Array<AvaliacaoAlunoDto>,
                    ],
                });
            }
        }
    };

    private _handleChanged = async (data?: { [key: string]: { [key: string]: string | number | boolean | Selectable } }): Promise<void> => {
        if (data) {
            const changedRows: Array<{ [key: string]: string | number | boolean | Selectable | null }> = [];
            const rows = this.state.rows ?? [];
            const updateRowsIds: Array<string> = [];

            Object.keys(data).forEach((key: string) => {
                const row = rows.find(r => r.id === key) ?? {};
                updateRowsIds.push(key);

                Object.keys(data[key]).forEach((column: string) => {
                    row[column] = data[key][column];
                });
                changedRows.push(row);
            });

            this.setState({
                rows: [...rows],
            });

            const result = await Promise.all(changedRows.map(d => {
                return d.id ? this.props.studentEvaluation?.update?.(d.id.toString(), {
                    aluno: isSelectable(d.aluno) ? d.aluno.key.toString() : undefined,
                    parametro: isSelectable(d.parametro) ? d.parametro.key.toString() : undefined,
                    nota: (d.nota ?? 0).toString(),
                    periodo: isSelectable(d.periodo) ? d.periodo.key.toString() : undefined,
                    excluida: !!d.excluida,
                }) : undefined;
            }));

            if (result.includes(undefined)) {
                this.setState({
                    rows: [...rows ?? []],
                });
            } else {
                this.setState({
                    studentEvaluations: [
                        ...(this.state.studentEvaluations ?? []).filter(e => e.id && !updateRowsIds.includes(e.id)),
                        ...result as Array<AvaliacaoAlunoDto>,
                    ],
                });
            }
        }
    };

    private _handleDelete = async (data?: ReadonlyArray<number | string>): Promise<void> => {
        if (data) {
            const studentEvaluations = this.state.studentEvaluations;
            this.setState({
                studentEvaluations: [...studentEvaluations?.filter(s => s.id && !data?.includes(s.id)) ?? []],
            });

            const result = await Promise.all(data.map(id => this.props.studentEvaluation?.delete?.(id.toString())));
            if (result.includes(undefined)) {
                this.setState({
                    studentEvaluations: [...studentEvaluations ?? []],
                });
            }
        }
    };

    private _onReturn = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(ManageContainer);
