import {FormItem} from '../../../../organisms/Builder';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome do grupo disciplinar',
        helperText: 'Introduza o nome do grupo',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'number',
        id: 'numero',
        label: 'Número do grupo disciplinar',
        helperText: 'Introduza o número do grupo',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'shuttle',
        id: 'professores',
        label: 'Professores',
        helperText: 'Selecione os professores associados ao grupo disciplinar',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        gridWidth: 12,
    },
];

