import {ChangeEvent, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {PaginatedTableListWithSearchProps} from './types';
import {PaginatedTableList} from '../PaginatedTableList';

const PaginatedTableListWithSearch: FunctionComponent<PaginatedTableListWithSearchProps> = (props: PropsWithChildren<PaginatedTableListWithSearchProps>) => {
    const [filteredListData, setFilteredListData] = useState(props.listData);
    const [searchParameter, setSearchParameter] = useState('');

    const _resetSearch = (): void => {
        if (searchParameter.length >= 2 && props.listData) {
            const filteredData: Array<Array<string | ReactElement>> = props.listData.filter(
                data => data.reduce((pv: boolean, cv: string | ReactElement) => {
                    return cv?.toString().toLowerCase()?.includes(searchParameter.toLowerCase()) || pv;
                }, false));
            setFilteredListData(filteredData);
        }
    };

    const _search = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchParameter(event.target.value);
        if (event.target.value.length >= 2 && props.listData) {
            const filteredData: Array<Array<string | ReactElement>> = props.listData.filter(
                data => data.reduce((pv: boolean, cv: string | ReactElement) => {
                    return cv?.toString().toLowerCase()?.includes(event.target.value.toLowerCase()) || pv;
                }, false));
            setFilteredListData(filteredData);
        } else if (props.listData) {
            setFilteredListData(props.listData);
        }
    };

    useEffect(() => {
        setFilteredListData(props.listData);
        _resetSearch();
    }, [props.listData]);

    return (
        <PaginatedTableList
            {...props}
            listData={filteredListData}
            onSearch={_search}
        />
    );
};

export default PaginatedTableListWithSearch;
