import {FunctionComponent, PropsWithChildren} from 'react';
import {AppBar, Hidden, IconButton, Toolbar, withStyles} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import classNames from 'classnames';
import {NavbarProps} from './types';
import {navbarStyles} from '../../../styles';
import {AdminNavbarLinks} from './index';
import {RouteComponentProps, withRouter} from 'react-router';

const Navbar: FunctionComponent<NavbarProps & RouteComponentProps> = (props: PropsWithChildren<NavbarProps & RouteComponentProps>) => {
    const {color, classes} = props;
    const appBarClasses = color ? classNames({[' ' + classes[color]]: color}) : '';

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container} id={'hello-world'}>
                <div className={classes.flex}/>
                <Hidden smDown implementation="css">
                    <AdminNavbarLinks/>
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default withStyles(navbarStyles, {name: 'MuiCTNavbar'})(withRouter(Navbar));
