import {PropsWithChildren, ReactElement, RefObject} from 'react';
import {ItemProps} from '../types';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

const Date = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement => {
    const _onChangeHandler = (_date: MaterialUiPickersDate | null, value?: string | null): void | undefined => {
        return props.onChange(props.item.id, value as T);
    };
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                clearable={props.item.dateOptions?.clearable}
                autoOk={props.item.dateOptions?.autoOk}
                disableFuture={props.item.dateOptions?.disableFuture}
                disablePast={props.item.dateOptions?.disablePast}
                views={props.item.dateOptions?.views}
                margin={props.item.margin}
                id={props.item.id}
                disabled={props.item.disabled}
                required={props.item.required}
                label={props.item.label}
                format={props.item.dateOptions?.format ?? 'yyyy-MM-DD'}
                ref={props.item.innerRef as RefObject<HTMLDivElement>}
                value={props.item?.state ? moment(props.item.state.toString()).toDate() : undefined}
                onChange={_onChangeHandler}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default Date;
