import {FormItem} from '../../organisms';

export const resetConfirmFormDefinition: Array<FormItem> = [
    {
        type: 'text',
        id: 'username',
        label: 'Utilizador',
        state: '',
        helperText: 'Introduza o seu nome de utilizador',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'text',
        id: 'confirmationCode',
        label: 'Código de confirmação',
        state: '',
        helperText: 'Introduza o código de confirmação recebido',
        informationalText: 'Deverá receber um código de confirmação no seu email num prazo máximo de 10 minutos. Deverá utilizar esse código para repor a sua palavra passe e recuperar o seu acesso.',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'password',
        id: 'password',
        label: 'Palavra-passe pretendida',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
];

