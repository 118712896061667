import {FunctionComponent, PropsWithChildren} from 'react';
import {FormProps} from './types';

const Form: FunctionComponent<FormProps> = (props: PropsWithChildren<FormProps>) => {
    return (
        <form
            ref={props.innerRef}
            id={props.id}
            className={props.className}
            action={props.action}
            noValidate={props.noValidate}
            onClick={props.onClick}
            onSubmit={props.onSubmit}
            autoComplete={props.autoComplete}>
            {props.children}
        </form>
    );
};

export default Form;
