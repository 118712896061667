import {FormItem} from '../../../../organisms/Builder';

export const profileFormDefinition: Array<FormItem<string | boolean | number | Array<string>>> = [
    {
        type: 'number',
        id: 'numeroMecanografico',
        label: 'Numero mecanográfico',
        helperText: 'Introduza o número mecanográfico',
        state: 0,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'text',
        id: 'nome',
        label: 'Nome',
        helperText: 'Introduza o nome do aluno',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'date',
        id: 'dataNascimento',
        label: 'Data Nascimento',
        gridWidth: 3,
        fullWidth: true,
        helperText: 'Introduza a data de nascimento do aluno',
        margin: 'normal',
        required: true,
        shrink: true,
        state: '',
        dateOptions: {
            autoOk: true,
            disableFuture: true,
            clearable: true,
        },
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'select',
        id: 'escola',
        label: 'Escola',
        helperText: 'Selecione a escola associada ao aluno',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

