import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, Roles, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {ButtonGroup} from '@material-ui/core';
import {ActionButton} from '../../../../atoms';
import {DeleteOutline, EditOutlined, EmailOutlined} from '@material-ui/icons';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {UserDto} from '../../../../../api/types';
import {UserPaths} from '../../../../../router/paths/userPaths';
import {Gate} from '../../../../organisms';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['username'];

    public state: Readonly<ListState> = {
        listHeader: ['NOME', 'AÇÕES'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setUserList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.users !== this.state.users) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={this.state.alertOpen}
                dialogTitle={'Eliminar o utilizador?'}
                dialogContent={'Tem a certeza de que deseja eliminar este utilizador?'}
                tableHeaderColor={'primary'}
                title={'Lista de utilizadors'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onActionMessage={'Novo utilizador'}
                onAction={this.props.gate?.(Roles.ROLE_CONFIG, this._create)}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setUserList = async (): Promise<void> => {
        if (this.props.session) {
            const users: Array<UserDto> | undefined = await this.props.user?.list?.();
            this.setState({users});
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.users?.map(value => {
            const data: { [key: string]: string | boolean | Array<string> | null } = value as { [key: string]: string | boolean | Array<string> | null };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });

            if (value.id) {
                result.push(this._generateActionButtons(value.id));
            }
            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _generateActionButtons = (id: string): ReactElement => {
        return (
            <ButtonGroup>
                <Gate role={Roles.ROLE_ADMIN}>
                    <ActionButton icon={<EditOutlined/>} title={'Editar'} ariaLabel={'editar'}
                                  onClick={this._edit(id)}/>
                </Gate>
                <Gate role={Roles.ROLE_ADMIN}>
                    <ActionButton icon={<DeleteOutline/>} title={'Eliminar'} ariaLabel={'eliminar'}
                                  onClick={this._delete(id)}/>
                </Gate>
                <Gate role={Roles.ROLE_ADMIN}>
                    <ActionButton icon={<EmailOutlined/>} title={'Reenviar email'} ariaLabel={'reenviar-email'}
                                  onClick={this._resendEmail(id)}/>
                </Gate>
            </ButtonGroup>
        );
    };

    private _create = (): void => {
        this.props.history.push(generateLink(UserPaths.CREATE));
    };

    private _edit = (id: string) => (): void => {
        this.props.history.push(generateLink(UserPaths.EDIT, {userId: id}));
    };

    private _resendEmail = (id: string) => async (): Promise<void> => {
        await this.props.user?.resendConfirmationEmail?.(id);
    };

    private _delete = (id: string) => (): void => {
        this.setState({
            alertOpen: true,
            confirmAction: () => {
                if (this.props.session) {
                    this.props.user?.delete?.(id);
                }
                this.setState({
                    alertOpen: false,
                    confirmAction: undefined,
                });
            },
        });
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };
}

export default withAll(ListContainer);
