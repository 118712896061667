import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms/Builder';
import {DominioDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadDomain();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar domínio'}
                domainProfile={this.state.domain}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | number>>): Promise<void> => {
        const dominioDto: DominioDto = extractObjectFromResult<string | boolean | number, DominioDto>(result);

        if (this.props.session &&
            this.props.match.params.domainId &&
            await this.props.domain?.update?.(this.props.match.params.domainId, dominioDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadDomain = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.domainId) {
            const domain: DominioDto | undefined = await this.props.domain?.get?.(this.props.match.params.domainId);
            this.setState({domain});
        }
    };
}

export default withAll(EditContainer);
