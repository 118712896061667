import {FormItem} from '../../../../organisms';
import {Grade, Mention} from '../../../../../api/enums';

export const profileFormDefinition: Array<FormItem<string | number | Array<string>>> = [
    {
        type: 'select',
        id: 'turma',
        label: 'Turma',
        helperText: 'Selecione a turma',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'disciplina',
        label: 'Disciplina',
        helperText: 'Selecione a disciplina',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'mencao',
        label: 'Menção',
        helperText: 'Selecione qual a menção',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        options: [
            {
                key: 'quantitativa',
                value: Mention.QUANTITATIVA,
            },
            {
                key: 'qualitativa',
                value: Mention.QUALITATIVA,
            },
        ],
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'escala',
        label: 'Escala',
        helperText: 'Selecione qual a escala',
        informationalText: 'Lance as notas de 0 a 100 para a escala de 1 a 5 ou de 0 a 200 para a escala de 1 a 20',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        options: [
            {
                key: 'till20',
                value: Grade.TILL20,
            },
            {
                key: 'till5',
                value: Grade.TILL5,
            },
        ],
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'shuttle',
        id: 'professores',
        label: 'Professores',
        helperText: 'Selecione os professores associados à avaliação',
        state: [],
        required: true,
        margin: 'normal',
        variant: 'outlined',
        gridWidth: 12,
    },
    {
        type: 'text',
        id: 'nome',
        label: 'Designação',
        helperText: 'Introduza uma designação opcional para a ficha avaliativa',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
    },
    {
        type: 'text',
        id: 'observacoes',
        label: 'Observações',
        helperText: 'Introduza observações a constarem na ficha avaliativa',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        textOptions: {
            minRows: 4,
            multiline: true,
        },
    },
];

