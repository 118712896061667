export type Selectable = {
    key: string | ReadonlyArray<string> | number;
    value: string | number;
    active?: boolean;
};

export function isSelectable(object: unknown): object is Selectable {
    // eslint-disable-next-line no-extra-parens
    return (object as Selectable)?.value !== undefined && (object as Selectable)?.key !== undefined;
}
