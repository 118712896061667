import {Route} from '../types';
import {TimelapseOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {Create, Edit, List, Period} from '../../components/pages/Period';
import {ReactElement} from 'react';
import {PeriodPaths} from './periodPaths';

export const periodPaths: Array<Route<BaseProps>> = [
    {
        key: PeriodPaths.HOME,
        name: 'Períodos',
        path: '/periods',
        icon: TimelapseOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Period/>,
    },
    {
        key: PeriodPaths.LIST,
        parent: PeriodPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: PeriodPaths.CREATE,
        parent: PeriodPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: PeriodPaths.EDIT,
        parent: PeriodPaths.HOME,
        path: '/edit/:periodId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

