import {FunctionComponent, ReactElement} from 'react';
import {Box, IconButton, Tooltip} from '@material-ui/core';
import {EditOutlined} from '@material-ui/icons';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

export const EditButton: FunctionComponent<TableEditColumnBase.CommandProps & { editDisabled?: boolean }> = (props: TableEditColumnBase.CommandProps & { editDisabled?: boolean }): ReactElement => {
    if (props.editDisabled) {
        return <></>;
    }
    return (
        <Tooltip title={'Clique para editar este registo'}>
            <Box component={'span'}>
                <IconButton disabled={props.editDisabled} onClick={props.onExecute}>
                    <EditOutlined/>
                </IconButton>
            </Box>
        </Tooltip>
    );
};
