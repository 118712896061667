import {FormItem} from '../../../../organisms/Builder';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome do ano escolar',
        helperText: 'Introduza o nome do ano escolar',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'select',
        id: 'ciclo',
        label: 'Ciclo do ano escolar',
        helperText: 'Selecione o ciclo associado',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

