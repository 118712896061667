import {FunctionComponent, PropsWithChildren} from 'react';
import {QuoteProps} from './types';
import {typographyStyle} from '../../../styles';
import {withStyles} from '@material-ui/core';


const Quote: FunctionComponent<QuoteProps> = (props: PropsWithChildren<QuoteProps>) => {
    const {classes, text, author} = props;
    return (
        <blockquote className={classes.defaultFontStyle + ' ' + classes.quote}>
            <p className={classes.quoteText}>{text}</p>
            <small className={classes.quoteAuthor}>{author}</small>
        </blockquote>
    );
};
export default withStyles(typographyStyle, {name: 'MuiCTQuote'})(Quote);
