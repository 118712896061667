import {FunctionComponent, PropsWithChildren} from 'react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import {buttonStyle} from '../../../styles';
import {RegularButtonProps} from './types';
import {withStyles} from '@material-ui/core';

const RegularButton: FunctionComponent<RegularButtonProps<unknown>> = (props: PropsWithChildren<RegularButtonProps<unknown>>) => {
    const {
        color,
        round,
        children,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        className,
        muiClasses,
        classes,
        ...rest
    } = props;

    const classList: { [key: string]: string | boolean | undefined } = {
        [classes.button]: true,
        [classes.round]: round,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
    };

    if (size) {
        classList[classes[size]] = size;
    }
    if (color) {
        classList[classes[color]] = color;
    }
    if (className) {
        classList[className] = className;
    }

    const btnClasses = classNames(classList);

    return (
        <Button {...rest} classes={muiClasses} className={btnClasses}>
            {children}
        </Button>
    );
};

export default withStyles(buttonStyle, {name: 'MuiCTRegularButton'})(RegularButton);

