import {Route} from '../types';
import {ImportExportOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {ImportPaths} from './importPaths';
import {Import, Set} from '../../components/pages/Import';
import {ReactElement} from 'react';

export const importPaths: Array<Route<BaseProps>> = [
    {
        key: ImportPaths.HOME,
        name: 'Importar',
        path: '/import',
        icon: ImportExportOutlined,
        role: Roles.ROLE_ADMIN,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Import/>,
    },
    {
        key: ImportPaths.SET,
        parent: ImportPaths.HOME,
        path: '/',
        role: Roles.ROLE_ADMIN,
        exact: true,
        hidden: true,
        component: Set,
    },
];

