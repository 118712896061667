import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms/Builder';
import {AnoLetivoDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadSchoolYear();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar ano letivo'}
                schoolYear={this.state.schoolYear}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | Array<string>>>): Promise<void> => {
        const anoLetivoDto: AnoLetivoDto = extractObjectFromResult<string | boolean | Array<string>, AnoLetivoDto>(result);

        if (this.props.session &&
            this.props.match.params.schoolYearId &&
            await this.props.schoolYear?.update?.(this.props.match.params.schoolYearId, anoLetivoDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadSchoolYear = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.schoolYearId) {
            const schoolYear: AnoLetivoDto | undefined = await this.props.schoolYear?.get?.(this.props.match.params.schoolYearId);
            this.setState({schoolYear});
        }
    };
}

export default withAll(EditContainer);
