import {Route} from '../types';
import {DashboardOutlined} from '@material-ui/icons';
import {DashboardPaths} from './dashboardPaths';
import {BaseProps, Roles} from '../../base';
import {Dashboard} from '../../components/pages/Dashboard';

export const dashboardPaths: Array<Route<BaseProps>> = [
    {
        key: DashboardPaths.HOME,
        name: 'Dashboard',
        path: '/dashboard',
        icon: DashboardOutlined,
        role: Roles.ROLE_USER,
        exact: true,
        component: Dashboard,
    },
];

