import {Action} from '../actions';
import {SIGN_IN, SIGN_OUT} from '../constants';
import {SessionDto} from '../../api/types';

export const sessionReducer = (state: SessionDto | null = null, action: Action): SessionDto | null => {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...action.session,
            };
        case SIGN_OUT:
            return action.session;
    }
    return state;
};
