import {FormItem} from '../../../../organisms';
import {Roles} from '../../../../../base';

export const profileFormAddDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'username',
        label: 'Nome do utilizador',
        helperText: 'Introduza o nome do utilizador',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'email',
        id: 'email',
        label: 'Endereço de email',
        helperText: 'Introduza o endereço de email',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'password',
        id: 'password',
        label: '',
        gridWidth: 10,
        fullWidth: true,
        margin: 'normal',
        required: true,
        variant: 'outlined',
        state: '',
    },
    {
        type: 'checkbox',
        id: 'roles',
        label: 'Permissões aplicáveis',
        gridWidth: 6,
        options: [
            {
                key: Roles.ROLE_USER,
                value: 'Utilização',
            },
            {
                key: Roles.ROLE_CONFIG,
                value: 'Configuração',
            },
            {
                key: Roles.ROLE_ADMIN,
                value: 'Administração',
            },
        ],
        fullWidth: true,
        margin: 'normal',
        state: ['ROLE_USER'],
        variant: 'outlined',
        checkBoxOptions: {
            row: true,
        },
    },
];

