import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {SignInPaths} from './signInPaths';
import {SignIn} from '../../components/pages/SignIn';

export const signInPaths: Array<Route<BaseProps>> = [
    {
        key: SignInPaths.HOME,
        path: '/signin',
        role: Roles.ROLE_ANONYMOUS,
        exact: true,
        hidden: true,
        component: SignIn,
    },
];

