import _ from 'lodash';
import {createRef, FormEvent, PureComponent, ReactElement} from 'react';
import {TOOLTIP_DURATION} from '../../consts';
import {FormBuilderProps, FormBuilderState, FormItem, Item} from '../../types';
import FormBuilderLoadingView from './form-builder-loading-view';
import FormBuilderView from './form-builder-view';

class FormBuilderContainer<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends PureComponent<FormBuilderProps<T>, FormBuilderState<T>> {
    public state: Readonly<FormBuilderState<T>> = {
        items: [],
        open: false,
        message: '',
    };

    public componentDidMount(): void {
        this._setItems();
    }

    public componentDidUpdate(prevProps: Readonly<FormBuilderProps<T>>, _prevState: Readonly<FormBuilderState<T>>): void {
        if (prevProps.items !== this.props.items) {
            this._setItems();
        }
    }

    public render(): ReactElement {
        if (!this.props.items) {
            return <FormBuilderLoadingView/>;
        }

        return <FormBuilderView
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={this._onChangeHandler}
            onSubmit={this._onSubmitHandler}
            onSubmitButtonText={this.props.onSubmitButtonText}
            onSubmitButtonIcon={this.props.onSubmitButtonIcon}
            onCancel={this.props.onCancel}
            onCancelButtonText={this.props.onCancelButtonText}
            onCancelButtonIcon={this.props.onCancelButtonIcon}
            title={this.props.title}
            noValidate={this.props.noValidate}
            autoComplete={this.props.autoComplete}
            className={this.props.className}
            spacing={this.props.spacing}
            alignItems={this.props.alignItems}
            id={this.props.id}
            open={this.state.open}
            message={this.state.message}
            closeNotification={this._dismissErrorMessage}
            items={this.state.items}
        />;
    }

    private _onChangeHandler = (id: string, state: T): void => {
        const items = this.state.items.map((item: FormItem<T>) => {
            if (item.id === id) {
                item.state = state as T;
            }
            return item;
        });
        if (this.props.onChange) {
            this.props.onChange(this._parseToItemArray(items));
        } else {
            this.setState({items});
        }
    };

    private _onSubmitHandler = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (this._validate()) {
            this.props.onSubmit(this._parseToItemArray(this.state.items));
        } else {
            this._showErrorMessage();
        }
    };

    private _showErrorMessage = (): void => {
        this.setState({
            open: true,
            message: 'Por favor preencha todos os campos obrigatórios!',
        });

        setTimeout(() => {
            this.setState({
                open: false,
                message: '',
            });
        }, TOOLTIP_DURATION);
    };

    private _dismissErrorMessage = (): void => {
        this.setState({
            open: false,
        });
    };

    private _parseToItemArray = (items: Array<FormItem<T>>): Array<Item<T>> => {
        return items.map(item => {
            return {
                id: item.id,
                state: item.state,
            } as Item<T>;
        });
    };

    private _validate = (): boolean => {
        return this.state.items.reduce((success: boolean, item: FormItem<T>) => {
            if (item.innerRef?.current) {
                return success && this._validateArias(item.innerRef.current) && this._validateRequired(item.innerRef.current);
            }
            return success;
        }, true);
    };

    private _validateArias = (element: Element): boolean => {
        return !element.getAttribute('aria-invalid') || _.isEqual(element.getAttribute('aria-invalid'), 'false') || _.isEqual(element.getAttribute('value'), 'false');
    };

    private _validateRequired = (element: Element): boolean => {
        return !element.getAttribute('required') || !_.isEmpty(element.getAttribute('value'));
    };

    private _setItems = (): void => {
        if (this.props.items) {
            this.setState({
                items: this.props.items.map(item => {
                    item.innerRef = item.innerRef ?? createRef();
                    return item;
                }),
            });
        }
    };
}

export default FormBuilderContainer;
