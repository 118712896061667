import {Route} from '../types';
import {EventOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {YearPaths} from './yearPaths';
import {Create, Edit, List, Year} from '../../components/pages/Year';
import {ReactElement} from 'react';

export const yearPaths: Array<Route<BaseProps>> = [
    {
        key: YearPaths.HOME,
        name: 'Anos Escolares',
        path: '/years',
        icon: EventOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Year/>,
    },
    {
        key: YearPaths.LIST,
        parent: YearPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: YearPaths.CREATE,
        parent: YearPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: YearPaths.EDIT,
        parent: YearPaths.HOME,
        path: '/edit/:yearId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

