import {ChangeEvent, FunctionComponent} from 'react';
import {ButtonGroup, FormControl, MenuItem, Select} from '@material-ui/core';
import {RemoveCircleOutline} from '@material-ui/icons';
import {WorkingHour, WorkingHourProps} from '../types';
import {enumKeys} from '../../../../base';
import {ActionButton, GridContainer, GridItem} from '../../../atoms';
import {Hour, WeekDay} from '../enums';

const WorkingHourComponent: FunctionComponent<WorkingHourProps> = (props: WorkingHourProps) => {
    const onChange = (element: 'day' | 'startTime' | 'endTime') => (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
        const changedWorkingHour: { [key: string]: WeekDay | Hour | string } = props.workingHour as { [key: string]: WeekDay | Hour | string };
        changedWorkingHour[element] = element === 'day' ? event.target.value as WeekDay : event.target.value as Hour;
        props.onChange(changedWorkingHour as WorkingHour);
    };

    return (
        <GridContainer style={{padding: '7px 0'}}>
            <GridItem xs={6} sm={6} md={6}>
                <FormControl variant="outlined" fullWidth={true}>
                    <Select value={props.workingHour.day} onChange={onChange('day')}>
                        {
                            enumKeys(WeekDay).map(value => <MenuItem
                                key={value}
                                value={WeekDay[value]}>
                                {WeekDay[value]}
                            </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
                <FormControl variant="outlined" fullWidth={true}>
                    <Select value={props.workingHour.startTime} onChange={onChange('startTime')}>
                        {
                            enumKeys(Hour).map(value => <MenuItem
                                key={value}
                                value={Hour[value]}>
                                {Hour[value]}
                            </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
                <FormControl variant="outlined" fullWidth={true}>
                    <Select value={props.workingHour.endTime} onChange={onChange('endTime')}>
                        {
                            enumKeys(Hour).map(value => <MenuItem
                                key={value}
                                value={Hour[value]}>
                                {Hour[value]}
                            </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
                <ButtonGroup>
                    <ActionButton icon={<RemoveCircleOutline/>} title={'Eliminar'} ariaLabel={'eliminar'}
                                  onClick={props.onDelete}/>
                </ButtonGroup>
            </GridItem>
        </GridContainer>
    );
};

export default WorkingHourComponent;

