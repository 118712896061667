import {FunctionComponent, PropsWithChildren} from 'react';
import Grid from '@material-ui/core/Grid';
import {GridProps} from './types';
import {withStyles} from '@material-ui/core';

const gridStyle = {
    grid: {
        padding: '0 15px !important',
    },
};

const GridItem: FunctionComponent<GridProps> = (props: PropsWithChildren<GridProps>) => {
    const {classes, children, ...rest} = props;
    return (
        <Grid item {...rest} className={classes.grid}>
            {children}
        </Grid>
    );
};

export default withStyles(gridStyle, {name: 'MuiCTGridItem'})(GridItem);
