import {Fragment, PropsWithChildren, ReactElement} from 'react';
import {ItemProps} from '../types';
import Shuttle from './Shuttle';
import Text from './Text';
import Switch from './Switch';
import Number from './Number';
import Float from './Float';
import Select from './Select';
import Email from './Email';
import Password from './Password';
import SimplePassword from './SimplePassword';
import File from './File';
import Date from './Date';
import Checkbox from './Checkbox';
import TreeView from './TreeView';

const Factory = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement=> {
    switch (props.item.type) {
        case 'checkbox':
            return <Checkbox {...props}/>;
        case 'password':
            return <Password {...props}/>;
        case 'simple-password':
            return <SimplePassword {...props}/>;
        case 'text':
            return <Text {...props}/>;
        case 'date':
            return <Date {...props}/>;
        case 'email':
            return <Email {...props}/>;
        case 'number':
            return <Number {...props}/>;
        case 'float':
            return <Float {...props}/>;
        case 'switch':
            return <Switch {...props}/>;
        case 'select':
            return <Select {...props}/>;
        case 'file':
            return <File {...props}/>;
        case 'shuttle':
            return <Shuttle {...props}/>;
        case 'tree-view':
            return <TreeView {...props}/>;
        default:
            return <Fragment/>;
    }
};

export default Factory;
