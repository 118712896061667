import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {CicloDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadCycle();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar ciclo'}
                cycle={this.state.cycle}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | Array<string>>>): Promise<void> => {
        const cicloDto: CicloDto = extractObjectFromResult<string | boolean | Array<string>, CicloDto>(result);

        if (this.props.session &&
            this.props.match.params.cycleId &&
            await this.props.cycle?.update?.(this.props.match.params.cycleId, cicloDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadCycle = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.cycleId) {
            const cycle: CicloDto | undefined = await this.props.cycle?.get?.(this.props.match.params.cycleId);
            this.setState({cycle});
        }
    };
}

export default withAll(EditContainer);
