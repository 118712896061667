import {ChangeEvent, ReactElement} from 'react';
import {Input, MenuItem, Select} from '@material-ui/core';
import {DataTypeProvider} from '@devexpress/dx-react-grid';

export const BooleanEditor = (props: DataTypeProvider.ValueEditorProps): ReactElement =>
    <Select
        input={<Input/>}
        value={props.value ? 'Sim' : 'Não'}
        onChange={(event: ChangeEvent<{ name?: string; value: unknown }>): void => props.onValueChange(event.target.value === 'Sim')}
        style={{width: '100%'}}
    >
        <MenuItem value="Sim">
            Sim
        </MenuItem>
        <MenuItem value="Não">
            Não
        </MenuItem>
    </Select>;
