import {FormItem} from '../../../../organisms/Builder';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome da escola',
        helperText: 'Introduza o nome da escola',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 10,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: false,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'text',
        id: 'morada',
        label: 'Morada',
        helperText: 'Introduza a morada da escola',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        textOptions: {
            multiline: true,
            minRows: 3,
            maxRows: 6,
        },
        gridWidth: 12,
    },
    {
        type: 'text',
        id: 'telefone',
        label: 'Telefone',
        state: '',
        helperText: 'Por favor introduza o telefone da escola',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'text',
        id: 'movel',
        label: 'Telemóvel',
        state: '',
        helperText: 'Por favor introduza o telemóvel da escola',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
];

