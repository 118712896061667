import {FormItem} from '../../../../organisms';

export const profileFormDefinition: Array<FormItem<string | boolean | number>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome do domínio',
        helperText: 'Introduza o nome do dominio',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'number',
        id: 'percentagem',
        label: 'Percentagem',
        helperText: 'Introduza a percentagem associada',
        state: 0,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'select',
        id: 'ciclo',
        label: 'Ciclo',
        helperText: 'Selecione o ciclo associado',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

