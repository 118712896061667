import {ChangeEvent, FunctionComponent, PropsWithChildren, SetStateAction, useState} from 'react';
import classNames from 'classnames';
import {Tab, Tabs, withStyles} from '@material-ui/core';
import {CustomTabProps} from './types';
import {customTabsStyle} from '../../../styles';
import {Card, CardBody, CardHeader} from '../../atoms';

const CustomTabs: FunctionComponent<CustomTabProps> = (props: PropsWithChildren<CustomTabProps>) => {
    const [value, setValue] = useState(0);

    const handleChange = (_event: ChangeEvent<Record<string, unknown>>, state: SetStateAction<number>): void => {
        setValue(state);
    };

    const {classes, headerColor, plainTabs, tabs, title, rtlActive} = props;
    const cardTitle = classNames({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive,
    });
    return (
        <Card plain={plainTabs}>
            <CardHeader color={headerColor} plain={plainTabs}>
                {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                        root: classes.tabsRoot,
                        indicator: classes.displayNone,
                        scrollButtons: classes.displayNone,
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map((prop, key) => {
                        let icon = {};
                        if (prop.tabIcon) {
                            icon = {
                                icon: <prop.tabIcon/>,
                            };
                        }
                        return (
                            <Tab
                                classes={{
                                    root: classes.tabRootButton,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper,
                                }}
                                key={key}
                                label={prop.tabName}
                                {...icon}
                            />
                        );
                    })}
                </Tabs>
            </CardHeader>
            <CardBody>
                {tabs.map((prop, key) => {
                    if (key === value) {
                        return <div key={key}>{prop.tabContent}</div>;
                    }
                    return null;
                })}
            </CardBody>
        </Card>
    );
};

export default withStyles(customTabsStyle, {name: 'MuiCTCustomTabs'})(CustomTabs);
