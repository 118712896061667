import {applyMiddleware, createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import ReduxThunk from 'redux-thunk';

import {rootReducer} from '../reducers';

export const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: [
        'session',
    ],
};

const middlewares = [ReduxThunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

export const store = createStoreWithMiddleware(persistedReducer);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const persistor = persistStore(store);
