import {FormItem, SelectItem} from '../../../../organisms';
import {ReactNode} from 'react';
import {Checkbox} from '@material-ui/core';
import {CheckBox, CheckBoxOutlineBlank} from '@material-ui/icons';

export const generateFormDefinition: Array<FormItem<Array<string>>> = [
    {
        type: 'select',
        id: 'alunos',
        label: 'Alunos',
        helperText: 'Selecione os alunos pretendidos',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            clearOnBlur: true,
            multiple: true,
            limitTags: 2,
            disableCloseOnSelect: true,
            renderOption: (option: SelectItem, {selected}): ReactNode =>
                <li>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.value}
                </li>,
        },
    },
    {
        type: 'select',
        id: 'parametros',
        label: 'Parâmetros',
        helperText: 'Selecione os parâmetros pretendidos',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            clearOnBlur: true,
            multiple: true,
            limitTags: 2,
            disableCloseOnSelect: true,
            renderOption: (option: SelectItem, {selected}): ReactNode =>
                <li>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.value}
                </li>,
        },
    },
    {
        type: 'select',
        id: 'periodos',
        label: 'Períodos',
        helperText: 'Selecione os períodos pretendidos',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            clearOnBlur: true,
            multiple: true,
            limitTags: 4,
            disableCloseOnSelect: true,
            renderOption: (option: SelectItem, {selected}): ReactNode =>
                <li>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBox fontSize="small"/>}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.value}
                </li>,
        },
    },
];

