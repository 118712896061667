import {FunctionComponent, ReactElement} from 'react';
import {Box, IconButton, Tooltip} from '@material-ui/core';
import {CancelOutlined} from '@material-ui/icons';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

export const CancelButton: FunctionComponent<TableEditColumnBase.CommandProps> = (props: TableEditColumnBase.CommandProps): ReactElement => {
    return (
        <Tooltip title={'Clique para cancelar as alterações a este registo'}>
            <Box component={'span'}>
                <IconButton color="secondary" onClick={props.onExecute}>
                    <CancelOutlined/>
                </IconButton>
            </Box>
        </Tooltip>
    );
};
