import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {handleErr} from '../handleErr';
import {LockOption} from '../../../api/enums';

export const lockEvaluationUpdate = (id: string, lockOption: LockOption): ThunkAction<void, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<void> => {
        dispatch(notify(
            `A ${lockOption.toLowerCase()} notas para a avaliação indicada por favor aguarde...`,
            'primary',
            undefined,
            'tc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        try {
            await gesAvaliaApi.evaluationLock(id, lockOption);
            dispatch(dismiss());
        } catch (err) {
            dispatch(handleErr(err));
        }
    };
};
