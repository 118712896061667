import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {Profile} from '../Profile';
import {ImportarDto} from '../../../../../api/types';

class SetContainer extends Component<BaseProps> {
    public render(): ReactNode {
        return (
            <Profile
                title={'Importar dados'}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item>): Promise<void> => {
        const importData: ImportarDto = extractObjectFromResult<string | FileList, ImportarDto>(result);
        const formData = new FormData();
        formData.append('file', importData.file[0]);

        if (this.props.session &&
            await this.props.school?.dataImport?.(importData.escola, formData, importData.type)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(SetContainer);
