import {TextField} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import {ChangeEvent, Component, Fragment, ReactElement} from 'react';
import {TOOLTIP_DURATION} from '../consts';
import {ItemProps, PasswordState} from '../types';

const PASSWORD_MINIMUM_LENGTH = 8;

class Password<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends Component<ItemProps<T>, PasswordState> {
    public state: Readonly<PasswordState> = {
        passwordRepeat: '',
        errorPassword: false,
        errorPasswordRepeat: false,
        tooltipPassword: false,
        tooltipMessagePassword: '',
        tooltipPasswordRepeat: false,
        tooltipMessagePasswordRepeat: '',
    };

    public render(): ReactElement {
        return (
            <Fragment>
                <Tooltip open={this.state.tooltipPassword}
                         title={this.state.tooltipMessagePassword}
                         disableFocusListener={true}
                         disableHoverListener={true}
                         disableTouchListener={true}
                         arrow={true}>
                    <TextField
                        id={this.props.item.id}
                        inputRef={this.props.item.innerRef}
                        label={'Introduza a palavra-passe pretendida.'}
                        required={this.props.item.required}
                        error={this.state.errorPassword}
                        margin={this.props.item.margin}
                        variant={this.props.item.variant}
                        type={this.props.item.type}
                        fullWidth={this.props.item.fullWidth}
                        helperText={'A palavra-passe deve conter números, letras (maiúsculas e minúsculas) e carácteres especiais.'}
                        value={this.props.item.state ?? ''}
                        onChange={this._onChangeHandler}
                        onBlur={this._checkPasswordValidity}
                    />
                </Tooltip>
                <Tooltip open={this.state.tooltipPasswordRepeat}
                         title={this.state.tooltipMessagePasswordRepeat}
                         disableFocusListener={true}
                         disableHoverListener={true}
                         disableTouchListener={true}
                         arrow={true}>
                    <TextField
                        id={`${this.props.item.id}-repeat`}
                        label={'Repita a palavra-passe'}
                        required={this.props.item.required}
                        error={this.state.errorPasswordRepeat}
                        margin={this.props.item.margin}
                        variant={this.props.item.variant}
                        type={this.props.item.type}
                        fullWidth={this.props.item.fullWidth}
                        helperText={'Reintroduza a palavra-passe escolhida.'}
                        value={this.state.passwordRepeat}
                        onChange={this._onPasswordRepeatChangeHandler}
                        onBlur={this._checkPasswordIsEqual}
                    />
                </Tooltip>
            </Fragment>
        );
    }

    private _onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | undefined => {
        return this.props.onChange(this.props.item.id, event.target.value as T);
    };

    private _onPasswordRepeatChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void | undefined => {
        this.setState({
            passwordRepeat: event.target.value,
        });
    };

    private _checkPasswordValidity = (): void => {
        if (_.isEmpty(this.props.item.state)) {
            return;
        }
        if (!this._passwordIsLong()) {
            this._setErrorPassword(`A palavra-passe deve ter um mínimo de ${PASSWORD_MINIMUM_LENGTH} carácteres.`);
            this._timeoutTooltipPassword();
        } else if (!this._passwordIsComplex()) {
            this._setErrorPassword('A palavra-passe deve conter letras maiúsculas, minúsculas, números e carácteres especiais');
            this._timeoutTooltipPassword();
        } else {
            this._clearErrorPassword();
        }
    };

    private _checkPasswordIsEqual = (): void => {
        if (this._passwordIsEqual()) {
            this._clearErrorPasswordRepeat();
        } else {
            this._setErrorPasswordRepeat('A palavra-passe não é igual.');
            this._timeoutTooltipPasswordRepeat();
        }
    };

    private _passwordIsEqual = (): boolean => {
        return this.props.item.state === this.state.passwordRepeat;
    };

    private _passwordIsComplex = (): boolean => {
        const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
        return !!this.props.item.state && typeof this.props.item.state === 'string' && regExp.test(this.props.item.state);
    };

    private _passwordIsLong = (): boolean => {
        return !!this.props.item.state && typeof this.props.item.state === 'string' && this.props.item.state.length >= PASSWORD_MINIMUM_LENGTH;
    };

    private _setErrorPassword = (text: string): void => {
        this.setState({
            errorPassword: true,
            tooltipPassword: true,
            tooltipMessagePassword: text,
        });
    };

    private _setErrorPasswordRepeat = (text: string): void => {
        this.setState({
            errorPasswordRepeat: true,
            tooltipPasswordRepeat: true,
            tooltipMessagePasswordRepeat: text,
        });
    };

    private _timeoutTooltipPassword = (): void => {
        setTimeout(() => {
            this.setState({
                tooltipPassword: false,
                tooltipMessagePassword: '',
            });
        }, TOOLTIP_DURATION);
    };

    private _timeoutTooltipPasswordRepeat = (): void => {
        setTimeout(() => {
            this.setState({
                tooltipPasswordRepeat: false,
                tooltipMessagePasswordRepeat: '',
            });
        }, TOOLTIP_DURATION);
    };

    private _clearErrorPassword = (): void => {
        this.setState({
            errorPassword: false,
            tooltipPassword: false,
            tooltipMessagePassword: '',
        });
    };

    private _clearErrorPasswordRepeat = (): void => {
        this.setState({
            errorPasswordRepeat: false,
            tooltipPasswordRepeat: false,
            tooltipMessagePasswordRepeat: '',
        });
    };
}

export default Password;
