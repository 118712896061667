import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {cardBodyStyle} from '../../../styles';
import {CardBodyProps} from './types';
import {withStyles} from '@material-ui/core';

const CardBody: FunctionComponent<CardBodyProps> = (props: PropsWithChildren<CardBodyProps>) => {
    const {classes, className, children, plain, profile, ...rest} = props;
    const classList: { [key: string]: boolean | undefined } = {
        [classes.cardBody]: true,
        [classes.cardBodyPlain]: plain,
        [classes.cardBodyProfile]: profile,
    };

    if (className) {
        classList[className] = true;
    }

    const cardBodyClasses = classNames(classList);
    return (
        <div className={cardBodyClasses} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(cardBodyStyle, {name: 'MuiCTCardBody'})(CardBody);
