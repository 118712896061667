import {FunctionComponent, useEffect, useState} from 'react';
import {Results, WorkingHour, WorkingHoursProps} from './types';
import {Card, CardBody, CardHeader, GridContainer, GridItem, RegularButton} from '../../atoms';
import {withStyles} from '@material-ui/core';
import {workingHoursStyle} from '../../../styles';
import {Hour, WeekDay} from './enums';
import {WorkingHourComponent} from './components';
import _ from 'lodash';

const WorkingHours: FunctionComponent<WorkingHoursProps> = (props: WorkingHoursProps) => {
    const [workingHours, setWorkingHours] = useState<Array<WorkingHour>>([..._.cloneDeep(props.workingHours)]);

    useEffect(() => {
        setWorkingHours([..._.cloneDeep(props.workingHours)]);
    }, [props.workingHours]);

    const onAddWorkingHours = (): void => {
        setWorkingHours([
            ...workingHours,
            {day: WeekDay.MONDAY, startTime: Hour['00h00'], endTime: Hour['24h00']},
        ]);
    };

    const onChange = (index: number) => (workingHour: WorkingHour): void => {
        const currentWorkingHours = [...workingHours];
        currentWorkingHours[index] = workingHour;
        setWorkingHours(currentWorkingHours);
    };

    const onDelete = (index: number) => (): void => {
        const currentWorkingHours = [...workingHours];
        currentWorkingHours.splice(index, 1);
        setWorkingHours(currentWorkingHours);
    };

    const onSubmit = (): void => {
        const results: Results = {
            deletedHours: props.workingHours.filter(pw => !workingHours.find(w => pw.id === w.id)).map(pw => pw.id ?? 'N/A'),
            addedHours: workingHours.filter(w => !w.id),
            modifiedHours: workingHours.filter(cw => {
                const pw = props.workingHours.find(w => cw.id === w.id);
                console.log(cw, pw);
                return !!pw && !_.isEqual(pw, cw);
            }),
        };
        props.onSubmit(results);
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    {props.title &&
                    <CardHeader color="primary">
                        <h4 className={props.classes.cardTitleWhite}>{props.title}</h4>
                    </CardHeader>
                    }
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12}>
                            {
                                workingHours.map((w: WorkingHour, idx: number) => <WorkingHourComponent
                                    key={idx}
                                    workingHour={w}
                                    onChange={onChange(idx)}
                                    onDelete={onDelete(idx)}/>)
                            }
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}
                                  style={{display: 'flex', justifyContent: 'center'}}>
                            <RegularButton
                                variant="contained"
                                startIcon={props.onAddButtonIcon}
                                color="white"
                                size={'lg'}
                                type={'button'}
                                onClick={onAddWorkingHours}
                            >
                                {props.onAddButtonText ?? 'Adicionar'}
                            </RegularButton>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}
                                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <RegularButton
                                onClick={onSubmit}
                                variant="contained"
                                startIcon={props.onSubmitButtonIcon}
                                color="primary"
                                size={'lg'}
                                type={'button'}
                            >
                                {props.onSubmitButtonText ?? 'Submeter'}
                            </RegularButton>
                            {props.onCancel &&
                            <RegularButton
                                onClick={props.onCancel}
                                type={'button'}
                                variant="contained"
                                color="danger"
                                size="lg"
                                style={{marginLeft: '10px'}}
                                startIcon={props.onCancelButtonIcon}
                            >
                                {props.onCancelButtonText ?? 'Cancelar'}
                            </RegularButton>
                            }
                        </GridItem>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default withStyles(workingHoursStyle, {name: 'MuiCTWorkingHours'})(WorkingHours);

