import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {routes} from '../../../../../api/constants';
import {withStore} from '../../../../../redux';


const ProfileContainer: FunctionComponent<ProfileProps<string | number | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | number | Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.class?.list?.(),
                props.discipline?.list?.(),
                props.teacher?.list?.(),
                props.group?.list?.(),
            ]);

            const classOptions: Array<SelectItem> = results?.[0]?.map(turma => {
                return {
                    key: `${routes.classes}/${turma.id ?? 0}`,
                    value: turma.nome ?? '',
                };
            }) ?? [];

            const disciplineOptions: Array<SelectItem> = results?.[1]?.map(disciplina => {
                const grupoDisciplinar = results[3]?.find(
                    g => g.id && disciplina.grupoDisciplinar?.includes(g.id),
                );
                return {
                    key: `${routes.disciplines}/${disciplina.id ?? 0}`,
                    value: `${disciplina.nome} [${grupoDisciplinar?.numero}]`,
                };
            }) ?? [];

            const professorOptions: Array<SelectItem> = results?.[2]?.map(teacher => {
                return {
                    key: `${routes.teachers}/${teacher.id ?? 0}`,
                    value: teacher.nome ?? '',
                };
            }) ?? [];

            setSelectOptions([
                {
                    id: 'turma',
                    options: classOptions,
                },
                {
                    id: 'disciplina',
                    options: disciplineOptions,
                },
                {
                    id: 'professores',
                    options: professorOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.evaluationProfile]);


    let _formDefinition: Array<FormItem<string | number | Array<string>>> = mapObjectToForm<string | number | Array<string>, Record<string, string | number | Array<string>>>(props.evaluationProfile ?? {}, _.cloneDeep(profileFormDefinition));
    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<string | number | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

