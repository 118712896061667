import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {profileFormEditDefinition} from './profile.form.edit.definition';
import {profileFormAddDefinition} from './profile.form.add.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem} from '../../../../organisms/Builder';
import {mapObjectToForm} from '../../../../../base';
import {ProfileProps} from '../../types';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | Array<string> | null>> = (props: PropsWithChildren<ProfileProps<string | boolean | Array<string> | null>>): ReactElement => {
    const _getDefaultFormDefinition = (): Array<FormItem<string | boolean | Array<string>>> => {
        switch (props.type) {
            case 'add':
                return profileFormAddDefinition;
            case 'edit':
                return profileFormEditDefinition;
        }
        return [];
    };

    const _formDefinition: Array<FormItem<string | boolean | Array<string> | null>> = mapObjectToForm<string | boolean | Array<string> | null, Record<string, string | boolean | Array<string> | null>>(props.user ?? {}, _.cloneDeep(_getDefaultFormDefinition()));

    return (
        <FormBuilder<string | boolean | Array<string> | null>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default ProfileContainer;

