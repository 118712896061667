import {ComponentType, FunctionComponent, ReactElement} from 'react';
import {AddButton} from './AddButton';
import {EditButton} from './EditButton';
import {DeleteButton} from './DeleteButton';
import {CancelButton} from './CancelButton';
import {SaveButton} from './SaveButton';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

const commandComponents: { [key: string]: ComponentType<TableEditColumnBase.CommandProps> } = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: SaveButton,
    cancel: CancelButton,
};

export const CommandComponent: FunctionComponent<TableEditColumnBase.CommandProps & { disabled?: boolean }> = (props: TableEditColumnBase.CommandProps & { disabled?: boolean }): ReactElement => {
    const CommandButton = commandComponents[props.id];
    return (
        <CommandButton
            {...props}
            onExecute={props.onExecute}
        />
    );
};
