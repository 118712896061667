import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps, SelectOptions} from '../../types';
import {CicloDto} from '../../../../../api/types';
import {withStore} from '../../../../../redux';
import {routes} from '../../../../../api/constants';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | boolean | Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const cicloDtos: Array<CicloDto> = await props.cycle?.list?.() ?? [];

            const ciclosOptions: Array<SelectItem> = cicloDtos.map(ciclo => {
                return {
                    key: `${routes.cycles}/${ciclo.id ?? 0}`,
                    value: ciclo.nome ?? '',
                };
            });

            setSelectOptions([
                {
                    id: 'ciclo',
                    options: ciclosOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.yearProfile]);


    let _formDefinition: Array<FormItem<string | boolean | Array<string>>> = mapObjectToForm<string | boolean | Array<string>, Record<string, string | boolean | Array<string>>>(props.yearProfile ?? {}, _.cloneDeep(profileFormDefinition));

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<string | boolean | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

