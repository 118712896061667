import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {CardIconProps} from './types';
import {cardIconStyle} from '../../../styles';
import {withStyles} from '@material-ui/core';

const CardIcon: FunctionComponent<CardIconProps> = (props: PropsWithChildren<CardIconProps>) => {
    const {classes, className, children, color, ...rest} = props;
    const classList: { [key: string]: boolean | undefined | 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose' } = {
        [classes.cardIcon]: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [classes[`${color}CardHeader`]]: color,
    };

    if (className) {
        classList[className] = true;
    }

    const cardIconClasses = classNames(classList);
    return (
        <div className={cardIconClasses} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(cardIconStyle, {name: 'MuiCTCardIcon'})(CardIcon);
