import {FormItem} from '../../../../organisms';
import {Type} from '../../../../../api/enums';

export const profileFormDefinition: Array<FormItem> = [
    {
        type: 'file',
        id: 'file',
        label: 'Ficheiro',
        helperText: 'Clique para selecionar o ficheiro CSV a importar',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 12,
        shrink: true,
    },
    {
        type: 'select',
        id: 'type',
        label: 'Tipo',
        helperText: 'Selecione o tipo de ficheiro a importar',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        options: [
            {
                key: Type.ALUNOS,
                value: 'Alunos',
            },
            {
                key: Type.TURMAS,
                value: 'Turmas',
            },
            {
                key: Type.DISCIPLINAS,
                value: 'Disciplinas',
            },

        ],
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'escola',
        label: 'Escola',
        helperText: 'Selecione a escola associada à importaçãp',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

