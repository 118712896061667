import {ReactElement} from 'react';
import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';
import {TableEditRow as TableEditRowBase} from '@devexpress/dx-react-grid';
import {SelectCell, Selectable} from '../selectors';

export const SelectEditor = (availableValues?: { [key: string]: Array<Selectable> }) => (props: TableEditRowBase.CellProps): ReactElement => {
    const availableColumnValues = availableValues?.[props.column.name];
    if (availableColumnValues) {
        return <SelectCell {...props} availableColumnValues={availableColumnValues}/>;
    }
    return <TableEditRow.Cell style={{width: '100%'}} {...props}/>;
};
