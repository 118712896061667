import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {routes} from '../../../../../api/constants';
import {withStore} from '../../../../../redux';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | number | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | boolean | number | Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.parameter?.list?.(),
                props.domain?.list?.(),
                props.group?.list?.(),
                props.cycle?.list?.(),
            ]);

            const groupOptions: Array<SelectItem> = results?.[2]?.map(grupo => {
                return {
                    key: `${routes.groups}/${grupo.id ?? 0}`,
                    value: grupo.numero?.toString() ?? '',
                };
            }) ?? [];

            const domainOptions: Array<SelectItem> = results?.[1]?.map(dominio => {
                return {
                    key: `${routes.domains}/${dominio.id ?? 0}`,
                    value: `${dominio.nome} - ${dominio.percentagem}% - ${results?.[3]?.find(ciclo => ciclo.id && dominio?.ciclo?.includes(ciclo.id))?.nome ?? 'N/D'}`,
                };
            }) ?? [];

            const parameterOptions: Array<SelectItem> = results?.[0]?.map(parametro => {
                return {
                    key: `${routes.parameters}/${parametro.id ?? 0}`,
                    value: `${parametro.nome} - ${parametro.percentagem}% - ${results?.[2]?.find(grupo => grupo.id && parametro?.grupoDisciplinar?.includes(grupo.id))?.numero ?? 'N/D'}`,
                };
            }) ?? [];

            setSelectOptions([
                {
                    id: 'pai',
                    options: parameterOptions,
                },
                {
                    id: 'dominio',
                    options: domainOptions,
                },
                {
                    id: 'grupoDisciplinar',
                    options: groupOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.parameterProfile]);


    let _formDefinition: Array<FormItem<string | boolean | number | Array<string>>> = mapObjectToForm<string | boolean | number | Array<string>, Record<string, string | boolean | number | Array<string>>>(props.parameterProfile ?? {}, _.cloneDeep(profileFormDefinition));

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<string | boolean | number | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

