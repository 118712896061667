import {Paper, Typography} from '@material-ui/core';
import {KeyboardReturnOutlined} from '@material-ui/icons';
import {FunctionComponent} from 'react';
import {generateLink} from '../../../router';
import {Danger, GridContainer, GridItem, Primary, RegularButton} from '../../atoms';
import {BaseProps, getDefaultRoute} from '../../../base';
import {withStore} from '../../../redux';

const PageNotFoundView: FunctionComponent<BaseProps> = (props: BaseProps) => {
    const onClick = (): void => {
        props.history.push(generateLink(getDefaultRoute(props.session?.user.roles)));
    };

    return (
        <Paper style={{padding: 50}}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} align={'center'}>
                    <Danger>
                        <Typography style={{margin: 30}} variant={'h1'} component={'h1'}>
                            {'404'}
                        </Typography>
                    </Danger>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} align={'center'}>
                    <Primary>
                        <Typography style={{margin: 20}} variant={'h6'} component={'h6'}>
                            {'Ooops! Parece que a página que procuras não está disponível!'}
                        </Typography>
                    </Primary>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} align={'center'}>
                    <RegularButton
                        style={{margin: 20}}
                        id={'MuiCTPageNotFoundButton'}
                        variant={'contained'}
                        color={'primary'}
                        size={'lg'}
                        type={'button'}
                        startIcon={<KeyboardReturnOutlined/>}
                        onClick={onClick}
                    >
                        {'Voltar à página principal'}
                    </RegularButton>
                </GridItem>
            </GridContainer>
        </Paper>
    );
};

export default withStore(PageNotFoundView);
