import {FunctionComponent, ReactElement} from 'react';
import {Route as RouterRoute, Switch} from 'react-router-dom';
import {generateRoute, Route} from '../../../router';
import {BaseProps, getAuthorizedPaths} from '../../../base';
import {PageNotFound} from '../PageNotFound';
import {StoreProps, withStore} from '../../../redux';
import {StudentPaths} from '../../../router/paths/studentPaths';


const StudentRouter: FunctionComponent<StoreProps> = (props: StoreProps): ReactElement => {
    return (
        <Switch>
            {getAuthorizedPaths(props.session?.user.roles, StudentPaths.HOME).filter((route: Route<BaseProps>) => route.parent !== undefined).map((route: Route<BaseProps>, key: number) => {
                return (
                    <RouterRoute key={key}
                                 exact={route.exact}
                                 path={generateRoute(route.key)}
                                 component={route.component}/>
                );
            })}
            <RouterRoute path={'*'} component={PageNotFound}/>
        </Switch>
    );
};

export default withStore(StudentRouter);
