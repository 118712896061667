import {Component, ReactElement} from 'react';
import {BaseProps, withAll} from '../../../base';
import {GradesState} from './types';
import GradesView from './grades-view';
import {
    AlunoNotaDto,
    AvaliacaoAlunoDto,
    AvaliacaoDto,
    DominioDto,
    NotasDto,
    ParametroDto,
    PeriodoDto,
} from '../../../api/types';

class GradesContainer extends Component<BaseProps, GradesState> {
    public state: Readonly<GradesState> = {
        dialog: false,
        message: '',
        grades: undefined,
        parameters: [],
        periods: [],
        domains: [],
    };

    public async componentDidMount(): Promise<void> {
        await this._loadEvaluation();
        await this._loadGrades();
    }

    public render(): ReactElement {
        return <GradesView onClose={this._onClose} {...this.state}/>;
    }

    private _loadGrades = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.evaluationId) {
            const results = await Promise.all([
                this.props.domain?.list?.(),
                this.props.evaluation?.grades?.(this.props.match.params.evaluationId),
                this.props.evaluation?.parameterList?.(this.props.match.params.evaluationId),
                this.props.period?.list?.(),
            ]);
            const domains: Array<DominioDto> = results?.[0] ?? [];
            const grades: NotasDto = results?.[1] ?? {};

            grades.avaliacaoAlunos = Object.values(grades.avaliacaoAlunos ?? {}).sort((a: AlunoNotaDto, b: AlunoNotaDto) => {
                return (a?.numeroTurma ?? 0) - (b?.numeroTurma ?? 0);
            });

            const parameters: Array<ParametroDto> = (results?.[2] ?? [])
                .filter((parametro: ParametroDto) => {
                    return grades.avaliacaoAlunos?.find((alunoNota: AlunoNotaDto) => {
                        return alunoNota.avaliacoes?.find((avaliacaoAluno: AvaliacaoAlunoDto) => {
                            return parametro.id && avaliacaoAluno.parametro?.includes(parametro.id);
                        }) !== undefined;
                    }) !== undefined;
                })
                .sort((a: ParametroDto, b: ParametroDto) => {
                    const dominioA: DominioDto | undefined = domains.find((domain: DominioDto) => a.dominio?.includes(domain.id ?? ''));
                    const dominioB: DominioDto | undefined = domains.find((domain: DominioDto) => b.dominio?.includes(domain.id ?? ''));
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return (dominioB?.percentagem ?? 0) - (dominioA?.percentagem ?? 0) || (b.grupoDisciplinar ?? '').localeCompare(a.grupoDisciplinar ?? '') || (b?.percentagem.toString() ?? 0) - (a?.percentagem ?? 0);
                });

            const periods: Array<PeriodoDto> = (results?.[3] ?? [])
                .filter((periodo: PeriodoDto) => {
                    return grades.avaliacaoAlunos?.find((alunoNota: AlunoNotaDto) => {
                        return alunoNota.avaliacoes?.find((avaliacaoAluno: AvaliacaoAlunoDto) => {
                            return periodo.id && avaliacaoAluno.periodo?.includes(periodo.id);
                        }) !== undefined;
                    }) !== undefined;
                })
                .sort((a: PeriodoDto, b: PeriodoDto) => {
                    return a.nome?.localeCompare(b.nome ?? '') ?? 0;
                });

            this.setState({domains, grades, periods, parameters});
        }
    };

    private _loadEvaluation = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.evaluationId) {
            const evaluation: AvaliacaoDto | undefined = await this.props.evaluation?.get?.(this.props.match.params.evaluationId);
            this.setState({evaluation});
        }
    };

    private _onClose = (): void => {
        this.props.history.goBack();
    };

}

export default withAll(GradesContainer);
