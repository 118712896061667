import {Box, Checkbox, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {ChangeEvent, CSSProperties, ReactElement, useEffect, useState} from 'react';
import {FixedSizeList as List} from 'react-window';
import {SearchInput} from '../../../atoms';
import {SearchableListProps} from '../types';

const SEARCH_BAR_HEIGHT = 75;

const SearchableList = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: SearchableListProps<T>): ReactElement => {
    const [items, setItems] = useState(props.items);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setItems([
            ...props.items?.filter(
                s => s.value?.toLowerCase()?.includes(searchText.toLowerCase(),
                )) ?? [],
        ]);
    }, [props.items]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchText(event.target.value.toLowerCase());
        setItems([
            ...props.items?.filter(
                s => s.value?.toLowerCase()?.includes(event.target.value.toLowerCase(),
                )) ?? [],
        ]);
    };

    const ShuttleItem = (itemProps: { index: number; style?: CSSProperties }): ReactElement => {
        const labelId = `transfer-list-item-${items[itemProps.index]?.key ?? ''}-label`;
        return (
            <ListItem
                style={itemProps.style}
                disabled={props.disabled}
                key={items[itemProps.index]?.key ?? 0} role="listitem" button
                onClick={props.onToggle(items[itemProps.index])}>
                <ListItemIcon>
                    <Checkbox
                        disabled={props.disabled}
                        checked={props.checked.indexOf(items[itemProps.index]) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={items[itemProps.index]?.value}/>
            </ListItem>
        );
    };

    return <Box component={'div'} style={{padding: 10}}>
        <SearchInput
            id={'MuiCTShuttleSearchInput'}
            fullWidth={true}
            placeholder={'Pesquisar...'}
            onSearch={onSearch}
            ariaLabel={'search-box'}
        />
        <List {...props}
              style={{
                  ...props.style,
                  marginTop: 5,
              }}
              height={props.height - SEARCH_BAR_HEIGHT}
              itemCount={items.length}
              aria-invalid={props.required && props.items.length <= 0}>
            {ShuttleItem}
        </List>
    </Box>;
};

export default SearchableList;
