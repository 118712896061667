import {Box, IconButton, Tooltip} from '@material-ui/core';
import {FunctionComponent, PropsWithChildren} from 'react';
import {ActionButtonProps} from './types';

const ActionButton: FunctionComponent<ActionButtonProps> = (props: PropsWithChildren<ActionButtonProps>) => {
    return (
        <Tooltip placement={props.placement} key={props.key}
                 aria-label={props.ariaLabel}
                 title={props.title}>
            <Box component={'span'}>
                <IconButton edge={props.edge}
                            aria-label={props.ariaLabel}
                            disabled={props.disabled}
                            onClick={props.onClick}>
                    {props.icon}
                </IconButton>
            </Box>
        </Tooltip>
    );
};

export default ActionButton;

