import {FunctionComponent, PropsWithChildren} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {GridProps} from './types';

const gridStyle = {
    grid: {
        margin: '0 -15px !important',
        width: 'unset',
    },
};

const GridContainer: FunctionComponent<GridProps> = (props: PropsWithChildren<GridProps>) => {
    const {classes, children, ...rest} = props;
    return (
        <Grid container {...rest} className={classes.grid}>
            {children}
        </Grid>
    );
};

export default withStyles(gridStyle, {name: 'MuiCTGridContainer'})(GridContainer);
