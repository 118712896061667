import {Box, FormControlLabel, IconButton, Switch, Tooltip, Typography, withStyles} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {FunctionComponent, PropsWithChildren} from 'react';
import {switchStyle} from '../../../styles';
import {SwitchButtonProps} from './types';

const SwitchButton: FunctionComponent<SwitchButtonProps> = (props: PropsWithChildren<SwitchButtonProps>) => {
    const {classes} = props;
    return (
        <Tooltip placement={props.placement} key={props.key}
                 aria-label={props.ariaLabel}
                 title={props.title}>
            <Box component={'span'}>
                <FormControlLabel
                    control={
                        <Switch
                            classes={classes}
                            aria-label={props.ariaLabel}
                            disabled={props.disabled}
                            disableFocusRipple={props.disableFocusRipple}
                            checked={props.checked}
                            size={props.size}
                            value={props.value}
                            defaultValue={props.defaultValue}
                            defaultChecked={props.defaultChecked}
                            onClick={props.onClick}
                            onChange={props.onChange}
                            color={props.color}
                        />
                    }
                    style={{
                        height: '100%',
                        alignSelf: 'center',
                        margin: 0,
                    }}
                    label={
                        <Typography variant={'body2'} style={{alignContent: 'center'}}>
                            {props.label}
                            {
                                props.informationalText &&
                                <Tooltip title={props.informationalText}>
                                    <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                                aria-label={'informational-text'}
                                    >
                                        <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Typography>
                    }
                    labelPlacement={props.labelPlacement ?? 'start'}
                />
            </Box>
        </Tooltip>
    );
};

export default withStyles(switchStyle, {name: 'MuiCTSwitchButton'})(SwitchButton);

