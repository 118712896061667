import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {cardHeaderStyle} from '../../../styles';
import {CardHeaderProps} from './types';
import {withStyles} from '@material-ui/core';

const CardHeader: FunctionComponent<CardHeaderProps> = (props: PropsWithChildren<CardHeaderProps>) => {
    const {classes, className, children, color, plain, stats, icon, ...rest} = props;
    const classList: { [key: string]: boolean | undefined | 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose' } = {
        [classes.cardHeader]: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [classes[`${color}CardHeader`]]: color,
        [classes.cardHeaderPlain]: plain,
        [classes.cardHeaderStats]: stats,
        [classes.cardHeaderIcon]: icon,
    };

    if (className) {
        classList[className] = true;
    }

    const cardHeaderClasses = classNames(classList);
    return (
        <div className={cardHeaderClasses} {...rest}>
            {children}
        </div>
    );
};

export default withStyles(cardHeaderStyle, {name: 'MuiCTCardHeader'})(CardHeader);
