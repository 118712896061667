import {Route} from '../types';
import {SchoolOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {SchoolPaths} from './schoolPaths';
import {Create, Edit, List, School} from '../../components/pages/School';
import {ReactElement} from 'react';

export const schoolPaths: Array<Route<BaseProps>> = [
    {
        key: SchoolPaths.HOME,
        name: 'Escolas',
        path: '/schools',
        icon: SchoolOutlined,
        role: Roles.ROLE_ADMIN,
        exact: false,
        hidden: false,
        component: (): ReactElement => <School/>,
    },
    {
        key: SchoolPaths.LIST,
        parent: SchoolPaths.HOME,
        path: '/',
        role: Roles.ROLE_ADMIN,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: SchoolPaths.CREATE,
        parent: SchoolPaths.HOME,
        path: '/new',
        role: Roles.ROLE_ADMIN,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: SchoolPaths.EDIT,
        parent: SchoolPaths.HOME,
        path: '/edit/:schoolId',
        role: Roles.ROLE_ADMIN,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

