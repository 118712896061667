import {ReactElement} from 'react';
import {GridContainer, GridItem} from '../../../../atoms';
import {Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from '@material-ui/core';

const FormBuilderLoadingView = (): ReactElement => {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    open={true}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">Por favor aguarde...</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-description">
                            {'A carregar a informação...'}
                        </DialogContentText>
                    </DialogContent>
                    <LinearProgress/>
                </Dialog>
            </GridItem>
        </GridContainer>
    );
};

export default FormBuilderLoadingView;
