import {withStyles} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import {Fragment, PropsWithChildren, ReactElement} from 'react';
import {formStyle} from '../../../../../styles';
import {Card, CardBody, CardHeader, GridContainer, GridItem, RegularButton, Snackbar} from '../../../../atoms';
import {FormBuilderViewProps, FormItem} from '../../types';
import Factory from '../Factory';
import Form from '../Form/Form';


const FormBuilderView = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<FormBuilderViewProps<T>>): ReactElement => {
    const buildForm = (): Array<ReactElement> => {
        return props.items.map((item: FormItem<T>) => {
            return (
                <GridItem key={item.id}
                          xs={item.gridWidth}
                          sm={item.gridWidth}
                          md={item.gridWidth}
                >
                    <Factory item={item} onChange={props.onChange}/>
                </GridItem>
            );
        });
    };

    return (
        <Fragment>
            <Snackbar
                place={'tc'}
                color={'warning'}
                icon={Warning}
                message={props.message ?? ''}
                open={props.open}
                close={!!props.closeNotification}
                closeNotification={props.closeNotification}
                progress={false}
            />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {props.title &&
                        <CardHeader color="primary">
                            <h4 className={props.classes.cardTitleWhite}>{props.title}</h4>
                        </CardHeader>
                        }
                        <CardBody>
                            <Form
                                id={props.id}
                                innerRef={props.formRef}
                                autoComplete={props.autoComplete}
                                noValidate={props.noValidate}
                                onSubmit={props.onSubmit}>
                                <GridContainer className={props.className} spacing={props.spacing}
                                               alignItems={props.alignItems}>
                                    {buildForm()}
                                    <GridItem xs={12} sm={12} md={12}
                                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <RegularButton
                                            variant="contained"
                                            startIcon={props.onSubmitButtonIcon}
                                            color="primary"
                                            size={'lg'}
                                            type={'submit'}
                                        >
                                            {props.onSubmitButtonText ?? 'Submeter'}
                                        </RegularButton>
                                        {props.onCancel &&
                                        <RegularButton
                                            onClick={props.onCancel}
                                            type={'button'}
                                            variant="contained"
                                            color="danger"
                                            size="lg"
                                            style={{marginLeft: '10px'}}
                                            startIcon={props.onCancelButtonIcon}
                                        >
                                            {props.onCancelButtonText ?? 'Cancelar'}
                                        </RegularButton>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Fragment>
    );
};

export default withStyles(formStyle, {name: 'MuiCTForm'})(FormBuilderView);
