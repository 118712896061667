import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Store} from '../../types';
import {Action} from '../index';
import {getApi} from '../getApi';
import {dismiss, notify} from '../notify';
import {AlunoTurmaDto} from '../../../api/types';
import {handleErr} from '../handleErr';

export const studentClassList = (): ThunkAction<Promise<Array<AlunoTurmaDto> | undefined>, Store, unknown, Action> => {
    return async (dispatch: ThunkDispatch<Store, unknown, Action>): Promise<Array<AlunoTurmaDto> | undefined> => {
        dispatch(notify(
            'A carregar informação. Por favor aguarde...',
            'primary',
            undefined,
            'bc',
            true,
            true,
        ));

        const gesAvaliaApi = dispatch(getApi());
        let result: Array<AlunoTurmaDto> | undefined;
        try {
            result = await gesAvaliaApi.studentClassList();
            dispatch(dismiss());
        } catch (err) {
            dispatch(handleErr(err));
        }
        return result;
    };
};
