import {Box, IconButton, InputAdornment, TextField, Tooltip} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {ChangeEvent, createRef, PureComponent, ReactElement, RefObject} from 'react';
import {FileState, ItemProps} from '../types';

class File<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends PureComponent<ItemProps<T>, FileState> {
    public state: Readonly<FileState> = {};

    private fileInputRef: RefObject<HTMLInputElement> = createRef();

    private _openFileDialog = (): void => {
        if (this.fileInputRef.current !== null) {
            this.fileInputRef.current.click();
        }
    };

    public render(): ReactElement {
        return (
            <Box>
                <TextField
                    id={this.props.item.id}
                    label={this.props.item.label}
                    disabled={this.props.item.disabled}
                    required={this.props.item.required}
                    margin={this.props.item.margin}
                    variant={this.props.item.variant}
                    type={'text'}
                    inputRef={this.props.item.innerRef}
                    fullWidth={this.props.item.fullWidth}
                    helperText={this.props.item.helperText}
                    value={this.state.value}
                    placeholder={this.props.item.placeholderText}
                    InputLabelProps={{
                        shrink: this.props.item.shrink,
                    }}
                    InputProps={{
                        endAdornment: this.props.item.informationalText ?
                            <InputAdornment position={'end'}>
                                <Tooltip title={this.props.item.informationalText}>
                                    <IconButton style={{padding: 2, marginRight: -2}}
                                                aria-label={'informational-text'}
                                    >
                                        <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            : undefined,
                    }}
                    onClick={this._openFileDialog}
                />
                <input
                    ref={this.fileInputRef}
                    className="ges-fileinput__hidden"
                    type="file"
                    accept={'.csv'}
                    onChange={this._onChangeHandler}
                />
            </Box>
        );
    }

    private _onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            value: event.target.value,
        });
        return this.props.onChange(this.props.item.id, event.target.files as T);
    };

}

export default File;
