import {compose} from 'redux';
import {withStore} from '../../redux';
import {withSession} from './withSession';
import {ComponentType} from 'react';
import {BaseProps} from '../types';

export const withAll = compose<ComponentType<BaseProps>>(
    withStore,
    withSession,
);
