import {withStyles} from '@material-ui/core';
import {ArrowBackIos} from '@material-ui/icons';
import {FunctionComponent, PropsWithChildren} from 'react';
import {paperStyle} from '../../../styles';
import {Card, CardBody, CardHeader, Dialog, GridContainer, GridItem, RegularButton} from '../../atoms';
import {PaperProps} from './types';

const Paper: FunctionComponent<PaperProps> = (props: PropsWithChildren<PaperProps>) => {
    const {classes} = props;

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Dialog title={props.dialogTitle ?? ''} open={props.dialogOpen ?? false}
                        content={props.dialogContent ?? ''}
                        onConfirm={props.onDialogConfirm}
                        onClose={props.onDialogCancel}
                />
            </GridItem>
            {
                props.onReturn &&
                <GridItem xs={6} sm={6} md={6}
                          style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <RegularButton
                        id={'MuiCTPaperReturnButton'}
                        variant={'contained'}
                        color={'white'}
                        size={'lg'}
                        type={'button'}
                        startIcon={<ArrowBackIos/>}
                        onClick={props.onReturn}
                    >
                        Voltar
                    </RegularButton>
                </GridItem>
            }
            {
                props.onClick &&
                <GridItem xs={props.onReturn ? 6 : 12} sm={props.onReturn ? 6 : 12} md={props.onReturn ? 6 : 12}
                          style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <RegularButton
                        id={'MuiCTPaperButton'}
                        variant={'contained'}
                        color={'primary'}
                        size={'lg'}
                        type={'button'}
                        startIcon={props.onClickIcon}
                        onClick={props.onClick}
                    >
                        {props.onClickText ?? 'Novo'}
                    </RegularButton>
                </GridItem>
            }
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color={props.paperHeaderColor}>
                                <GridContainer>
                                    <GridItem xs={8} sm={8} md={8}>
                                        <h4 className={classes.cardTitleWhite}>{props.title}</h4>
                                        <p className={classes.cardCategoryWhite}>
                                            {props.subtitle}
                                        </p>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                {props.children}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
};

export default withStyles(paperStyle, {name: 'MuiCTPaper'})(Paper);
