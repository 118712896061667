import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms/Builder';
import {PeriodoDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadSchoolYear();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar período'}
                period={this.state.period}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | Array<string>>>): Promise<void> => {
        const periodoDto: PeriodoDto = extractObjectFromResult<string | boolean | Array<string>, PeriodoDto>(result);

        if (this.props.session &&
            this.props.match.params.periodId &&
            await this.props.period?.update?.(this.props.match.params.periodId, periodoDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadSchoolYear = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.periodId) {
            const period: PeriodoDto | undefined = await this.props.period?.get?.(this.props.match.params.periodId);
            this.setState({period});
        }
    };
}

export default withAll(EditContainer);
