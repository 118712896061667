import {FormItem} from '../../../../organisms';

export const profileFormDefinition: Array<FormItem<string | boolean | number | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome',
        helperText: 'Introduza o nome do disciplina',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 10,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 2,
    },
    {
        type: 'select',
        id: 'grupoDisciplinar',
        label: 'Grupo',
        helperText: 'Selecione o grupo associado à disciplina',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'escola',
        label: 'Escola',
        helperText: 'Selecione a escola associada à disciplina',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 5,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'shuttle',
        id: 'professores',
        label: 'Professores',
        helperText: 'Selecione os professores associados ao grupo disciplinar',
        state: [],
        required: false,
        margin: 'normal',
        variant: 'outlined',
        gridWidth: 12,
    },
];

