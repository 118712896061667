import {ChangeEvent, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {TableCell, TextField} from '@material-ui/core';
import {Selectable, SelectCellProps} from './types';
import {Autocomplete, AutocompleteRenderInputParams} from '@material-ui/lab';

const renderInputHandler = (params: AutocompleteRenderInputParams): ReactElement => {
    return <TextField {...params}/>;
};

const SelectCell: FunctionComponent<SelectCellProps> = (props: PropsWithChildren<SelectCellProps>) => {
    return (
        <TableCell>
            <Autocomplete
                options={props.availableColumnValues?.filter((item: Selectable) => item.active === undefined || item.active) ?? []}
                autoComplete={true}
                openOnFocus={true}
                clearOnBlur={true}
                onChange={(_event: ChangeEvent<Record<string, unknown>>, item: Selectable | null): void => props.onValueChange(item)}
                getOptionLabel={(option: Selectable): string => option.value.toString()}
                renderInput={renderInputHandler}
                style={{width: '100%'}}
                // eslint-disable-next-line no-extra-parens
                value={props.availableColumnValues?.find((item: Selectable) => item.key === (props.row[props.column.name] as Selectable | undefined)?.key) ?? null}
            />
        </TableCell>
    );
};

export default SelectCell;
