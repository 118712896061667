import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms/Builder';
import {ParametroDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadParameter();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar parametro'}
                parameterProfile={this.state.parameter}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | number | Array<string>>>): Promise<void> => {
        const parametroDto: ParametroDto = extractObjectFromResult<string | boolean | number | Array<string>, ParametroDto>(result);
        parametroDto.percentagem = parseFloat(parametroDto.percentagem?.toString() ?? '0');

        if (this.props.session &&
            this.props.match.params.parameterId &&
            await this.props.parameter?.update?.(this.props.match.params.parameterId, parametroDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadParameter = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.parameterId) {
            const parameter: ParametroDto | undefined = await this.props.parameter?.get?.(this.props.match.params.parameterId);
            this.setState({parameter});
        }
    };
}

export default withAll(EditContainer);
