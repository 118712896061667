import {FunctionComponent, PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem, SelectItem} from '../../../../organisms/Builder';
import {mapObjectToForm, mapOptionsToForm} from '../../../../../base';
import {ProfileProps} from '../../types';
import {SelectOptions} from '../../../Year';
import {routes} from '../../../../../api/constants';
import {withStore} from '../../../../../redux';


const ProfileContainer: FunctionComponent<ProfileProps<string | boolean | number | Array<string>>> = (props: PropsWithChildren<ProfileProps<string | boolean | number | Array<string>>>): ReactElement => {
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions>>([]);
    const _setSelectItems = async (): Promise<void> => {
        if (props.session) {
            const results = await Promise.all([
                props.school?.list?.(),
                props.group?.list?.(),
                props.teacher?.list?.(),
            ]);

            const schoolOptions: Array<SelectItem> = results?.[0]?.map(escola => {
                return {
                    key: `${routes.schools}/${escola.id ?? 0}`,
                    value: escola.nome ?? '',
                };
            }) ?? [];

            const groupOptions: Array<SelectItem> = results?.[1]?.map(grupo => {
                return {
                    key: `${routes.groups}/${grupo.id ?? 0}`,
                    value: `${grupo.numero} - ${grupo.nome}`,
                };
            }) ?? [];

            const professorOptions: Array<SelectItem> = results?.[2]?.map(teacher => {
                return {
                    key: `${routes.teachers}/${teacher.id ?? 0}`,
                    value: teacher.nome ?? '',
                };
            }) ?? [];

            setSelectOptions([
                {
                    id: 'escola',
                    options: schoolOptions,
                },
                {
                    id: 'grupoDisciplinar',
                    options: groupOptions,
                },
                {
                    id: 'professores',
                    options: professorOptions,
                },
            ]);
        }
    };

    useEffect(() => {
        _setSelectItems();
    }, [props.disciplineProfile]);


    let _formDefinition: Array<FormItem<string | boolean | number | Array<string>>> = mapObjectToForm<string | boolean | number | Array<string>, Record<string, string | boolean | number | Array<string>>>(props.disciplineProfile ?? {}, _.cloneDeep(profileFormDefinition));

    selectOptions?.forEach(so => {
        _formDefinition = mapOptionsToForm(so.id, so.options, _formDefinition);
    });

    return (
        <FormBuilder<string | boolean | number | Array<string>>
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default withStore(ProfileContainer);

