import {FunctionComponent} from 'react';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

import {persistor, store} from '../redux';
import {Router} from '../router';

import './material-dashboard-react.css?v=1.9.0';

const App: FunctionComponent = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router/>
            </PersistGate>
        </Provider>
    );
};

export default App;
