import {FormItem} from '../../../../organisms';

export const profileFormDefinition: Array<FormItem<string | boolean | number | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome',
        helperText: 'Introduza o nome do parametro',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 8,
    },
    {
        type: 'float',
        id: 'percentagem',
        label: 'Percentagem',
        helperText: 'Introduza a percentagem do parâmetro',
        state: 0,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 4,
        step: '0.0001',
    },
    {
        type: 'select',
        id: 'pai',
        label: 'Parâmetro pai',
        helperText: 'Selecione o parâmetro pai associado, se aplicáve',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 4,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'dominio',
        label: 'Dominio',
        helperText: 'Selecione o domínio associado',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 4,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
    {
        type: 'select',
        id: 'grupoDisciplinar',
        label: 'Grupo disciplinar',
        helperText: 'Selecione o grupo disciplinar associado',
        state: '',
        required: false,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 4,
        selectOptions: {
            autoComplete: true,
            openOnFocus: true,
            selectOnFocus: true,
        },
    },
];

