import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, Roles, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {ButtonGroup} from '@material-ui/core';
import {ActionButton} from '../../../../atoms';
import {DeleteOutline, EditOutlined} from '@material-ui/icons';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {GrupoDto} from '../../../../../api/types';
import {GroupPaths} from '../../../../../router/paths/groupPaths';
import {Gate} from '../../../../organisms';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['nome', 'numero'];

    public state: Readonly<ListState> = {
        listHeader: ['NOME', 'NÚMERO', 'AÇÕES'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setGroupsList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.groups !== this.state.groups) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={this.state.alertOpen}
                dialogTitle={'Eliminar o grupo?'}
                dialogContent={'Tem a certeza de que deseja eliminar este grupo disciplinar?'}
                tableHeaderColor={'primary'}
                title={'Lista de grupos disciplinares'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onActionMessage={'Novo grupo disciplinar'}
                onAction={this.props.gate?.(Roles.ROLE_CONFIG, this._create)}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setGroupsList = async (): Promise<void> => {
        if (this.props.session) {
            const groups: Array<GrupoDto> | undefined = await this.props.group?.list?.();
            this.setState({groups});
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.groups?.map(value => {
            const data: { [key: string]: string | boolean } = value as { [key: string]: string | boolean };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });

            if (value.id) {
                result.push(this._generateActionButtons(value.id));
            }
            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _generateActionButtons = (id: string): ReactElement => {
        return (
            <ButtonGroup>
                <Gate role={Roles.ROLE_CONFIG}>
                    <ActionButton icon={<EditOutlined/>} title={'Editar'} ariaLabel={'editar'}
                                  onClick={this._edit(id)}/>
                </Gate>
                <Gate role={Roles.ROLE_CONFIG}>
                    <ActionButton icon={<DeleteOutline/>} title={'Eliminar'} ariaLabel={'eliminar'}
                                  onClick={this._delete(id)}/>
                </Gate>
            </ButtonGroup>
        );
    };

    private _create = (): void => {
        this.props.history.push(generateLink(GroupPaths.CREATE));
    };

    private _edit = (id: string) => (): void => {
        this.props.history.push(generateLink(GroupPaths.EDIT, {groupId: id}));
    };

    private _delete = (id: string) => (): void => {
        this.setState({
            alertOpen: true,
            confirmAction: async () => {
                if (this.props.session) {
                    const data = this.state.groups;
                    if (await this.props.group?.delete?.(id)) {
                        this.setState({
                            groups: [...data?.filter(t => t.id !== id) ?? []],
                        });
                    }
                }
                this.setState({
                    alertOpen: false,
                    confirmAction: undefined,
                });
            },
        });
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };
}

export default withAll(ListContainer);
