import {FunctionComponent, PropsWithChildren} from 'react';
import {SearchInputProps} from './types';
import {FormControl, InputAdornment, OutlinedInput, withStyles} from '@material-ui/core';
import {SearchOutlined} from '@material-ui/icons';
import {searchInputStyle} from '../../../styles';

const SearchInput: FunctionComponent<SearchInputProps> = (props: PropsWithChildren<SearchInputProps>) => {
    const {classes} = props;

    return (
        <FormControl variant="outlined" fullWidth={props.fullWidth}>
            <OutlinedInput
                id={props.id}
                disabled={props.disabled}
                fullWidth={props.fullWidth}
                placeholder={props.placeholder}
                onClick={props.onClick}
                classes={classes}
                labelWidth={0}
                onChange={props.onSearch}
                inputProps={{'aria-label': props.ariaLabel}}
                endAdornment={
                    <InputAdornment position="end">
                        <SearchOutlined/>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default withStyles(searchInputStyle, {name: 'MuiCTSearchInput'})(SearchInput);

