import {Button, IconButton, Paper, Tooltip, Typography} from '@material-ui/core';
import {ChevronLeftOutlined, ChevronRightOutlined, InfoOutlined} from '@material-ui/icons';
import {Component, ReactElement, RefObject} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {GridContainer, GridItem} from '../../../atoms';
import {ItemProps, SelectItem, ShuttleState} from '../types';
import SearchableList from './SearchableList';

class Shuttle<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends Component<ItemProps<T>, ShuttleState> {
    public state: Readonly<ShuttleState> = {
        checked: [],
        items: [],
        selectedItems: [],
    };

    public shouldComponentUpdate(nextProps: Readonly<ItemProps<T>>, nextState: Readonly<ShuttleState>): boolean {
        return nextProps.item.options !== this.props.item.options
            || this.state.items.length === 0
            || nextProps.item.state !== this.props.item.state
            || nextState !== this.state;
    }

    public componentDidMount(): void {
        this._setInitialItems();
    }

    public componentDidUpdate(prevProps: Readonly<ItemProps<T>>, _prevState: Readonly<ShuttleState>): void {
        if (prevProps.item !== this.props.item) {
            this._setInitialItems();
        }
    }

    public render(): ReactElement {
        return (
            <GridContainer
                direction={'column'}
                style={{paddingTop: 20, paddingBottom: 20}}
            >
                <GridItem style={{marginBottom: 10, marginLeft: 15}}>
                    <Typography style={{fontSize: '0.8rem'}}
                                component={'label'}>
                        {this.props.item.label}{this.props.item.required ? ' *' : ''}
                        {
                            this.props.item.informationalText &&
                            <Tooltip title={this.props.item.informationalText}>
                                <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Typography>
                </GridItem>
                <GridItem style={{maxHeight: 410}}>
                    <GridContainer
                        alignItems="center"
                        margin={this.props.item.margin}
                        variant={this.props.item.variant}
                    >
                        <GridItem style={{width: '42.5%'}}>{this._renderCustomList(this.state.items)}</GridItem>
                        <GridItem style={{width: '15%'}}>
                            <GridContainer direction="column" alignItems="center">
                                <Button
                                    style={{marginTop: 15, marginBottom: 15}}
                                    variant={'outlined'}
                                    size={'large'}
                                    onClick={this._moveSelectedToRight}
                                    disabled={this.state.items.length === 0 || this.props.item.disabled}
                                    aria-label={'move selected right'}
                                >
                                    <ChevronRightOutlined/>
                                </Button>
                                {
                                    this.props.item.shuttleOptions?.customActions
                                }
                                <Button
                                    style={{marginTop: 15, marginBottom: 15}}
                                    variant={'outlined'}
                                    size={'large'}
                                    onClick={this._moveSelectedToLeft}
                                    disabled={this.state.selectedItems.length === 0 || this.props.item.disabled}
                                    aria-label={'move selected left'}
                                >
                                    <ChevronLeftOutlined/>
                                </Button>
                            </GridContainer>
                        </GridItem>
                        <GridItem
                            style={{width: '42.5%'}}>{this._renderCustomList(this.state.selectedItems, this.props.item.required, this.props.item.innerRef)}</GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem style={{marginTop: 8, marginLeft: 15}}>
                    <Typography style={{fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.54)'}}
                                component={'p'}>{this.props.item.helperText}</Typography>
                </GridItem>
            </GridContainer>
        );
    }

    private _renderCustomList = (items: Array<SelectItem>, required?: boolean, refObject?: RefObject<Element>): ReactElement => {
        return (
            <Paper style={{width: '100%'}}>
                {
                    items.length <= 0
                        ? <Typography
                            variant={'body1'}
                            component={'p'}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 400,
                                fontSize: '0.8rem',
                                fontStyle: 'italic',
                                color: 'rgb(128,128,128)',
                            }}
                        >
                            {required ? this.props.item.shuttleOptions?.requiredMessage ?? 'Please select at least one item!' : 'No options available...'}
                        </Typography>
                        :
                        <SearchableList
                            innerRef={refObject}
                            width={'100%'}
                            height={400}
                            itemSize={45}
                            items={items}
                            required={required}
                            disabled={this.props.item.disabled}
                            onToggle={this._handleToggle}
                            checked={this.state.checked}
                            outerElementType={PerfectScrollbar}
                        />
                }
            </Paper>
        );
    };
    private _setInitialItems = (): void => {
        if (this.props.item.options) {
            const items: Array<SelectItem> = this.props.item.options.filter((selectItem: SelectItem) => Array.isArray(this.props.item.state) && !this.props.item.state.includes(selectItem.key));
            const selectedItems: Array<SelectItem> = this.props.item.options.filter((selectItem: SelectItem) => Array.isArray(this.props.item.state) && this.props.item.state.includes(selectItem.key));
            this.setState({
                items,
                selectedItems,
            });
        }
    };

    private _handleToggle = (item: SelectItem) => (): void => {
        const currentIndex = this.state.checked.indexOf(item);
        const checked = [...this.state.checked];

        if (currentIndex === -1) {
            checked.push(item);
        } else {
            checked.splice(currentIndex, 1);
        }

        this.setState({checked});
    };

    private _moveSelectedToLeft = (): void => {
        const checked = [...this.state.checked].filter(item => this.state.selectedItems.indexOf(item) === -1);
        const selectedItems = [...this.state.selectedItems].filter(item => this.state.checked.indexOf(item) === -1);
        const items = [...this.state.items, ...this.state.checked.filter(item => this.state.items.indexOf(item) === -1)];

        if (this.props.onChange) {
            this.props.onChange(this.props.item.id, selectedItems.map(selectedItem => selectedItem.key) as T);
        }

        this.setState({
            checked,
            selectedItems,
            items,
        });
    };

    private _moveSelectedToRight = (): void => {
        const checked = [...this.state.checked].filter(item => this.state.items.indexOf(item) === -1);
        const items = [...this.state.items].filter(item => this.state.checked.indexOf(item) === -1);
        const selectedItems = [...this.state.selectedItems, ...this.state.checked.filter(item => this.state.selectedItems.indexOf(item) === -1)];

        if (this.props.onChange) {
            this.props.onChange(this.props.item.id, selectedItems.map(selectedItem => selectedItem.key) as T);
        }

        this.setState({
            checked,
            selectedItems,
            items,
        });
    };
}

export default Shuttle;
