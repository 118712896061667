import {Fragment, FunctionComponent, ReactElement, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {DeleteOutline} from '@material-ui/icons';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

export const DeleteButton: FunctionComponent<TableEditColumnBase.CommandProps & { deleteDisabled?: boolean }> = (props: TableEditColumnBase.CommandProps & { deleteDisabled?: boolean }): ReactElement => {
    const [open, setOpen] = useState(false);

    const confirm = (): void => {
        setOpen(true);
    };

    const proceed = (): void => {
        props.onExecute();
        setOpen(false);
    };

    if (props.deleteDisabled) {
        return <></>;
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={(): void => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Eliminar linha?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem a certeza de que deseja eliminar esta linha?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(): void => setOpen(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={proceed} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title={'Clique para eliminar este registo'}>
                <Box component={'span'}>
                    <IconButton
                        disabled={props.deleteDisabled}
                        onClick={confirm}
                    >
                        <DeleteOutline/>
                    </IconButton>
                </Box>
            </Tooltip>
        </Fragment>
    );
};
