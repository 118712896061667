import {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import classNames from 'classnames';
import Snack from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import {SnackbarProps} from './types';
import {snackbarContentStyle} from '../../../styles';
import {SvgIconComponent} from '@material-ui/icons';
import {CircularProgress, withStyles} from '@material-ui/core';

const Snackbar: FunctionComponent<SnackbarProps> = (props: PropsWithChildren<SnackbarProps>) => {
    const {classes, message, color, close, place, open, progress, rtlActive} = props;
    const Icon: SvgIconComponent | undefined = props.icon;
    let action: ReactNode = [];
    const messageClasses = classNames({
        [classes.iconMessage]: Icon !== undefined || progress,
    });
    if (close) {
        action = [
            <IconButton
                className={classes.iconButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={props.closeNotification}
            >
                <Close className={classes.close}/>
            </IconButton>,
        ];
    }
    return (
        <Snack
            anchorOrigin={{
                vertical: place?.indexOf('t') === -1 ? 'bottom' : 'top',
                horizontal:
                    place?.indexOf('l') !== -1
                        ? 'left'
                        : place?.indexOf('c') !== -1
                        ? 'center'
                        : 'right',
            }}
            open={open}
            message={
                <div>
                    {Icon ? <Icon className={classes.icon}/> : null}
                    {progress ? <CircularProgress color={'inherit'} className={classes.progress}/> : null}
                    <span className={messageClasses}>{message}</span>
                </div>
            }
            action={action}
            ContentProps={{
                classes: {
                    root: classes.root + ' ' + (color ? classes[color] : ''),
                    message: classes.message,
                    action: classNames({[classes.actionRTL]: rtlActive}),
                },
            }}
        />
    );
};

export default withStyles(snackbarContentStyle, {name: 'MuiCTSnackbar'})(Snackbar);

