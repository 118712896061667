import {Theme} from '@material-ui/core';
import {Styles} from '@material-ui/styles/withStyles';
import {grayColor, whiteColor} from '../material-dashboard-react';

export const treeViewListStyle: Styles<Theme, Record<string, unknown>, string> = {
    root: {
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 15,
        paddingRight: 15,
        margin: '0 auto',
        '& > li div div:nth-child(2)': {
            lineHeight: 2,
            width: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 0,
        },
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: 300,
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: grayColor[1],
            fontWeight: '400',
            lineHeight: '1',
        },
    },
};
