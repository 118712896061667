import {IconButton, Tooltip, withStyles} from '@material-ui/core';
import {
    AddCircleOutlined,
    AutorenewOutlined,
    HighlightOffOutlined,
    InfoOutlined,
    RemoveCircleOutlined,
} from '@material-ui/icons';
import {TreeItem, TreeView} from '@material-ui/lab';
import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {treeListStyle} from '../../../styles';
import {TreeListItem} from '../TreeListItem';
import {TreeListProps} from './types';

const generateTreeItems = (items: Array<TreeListItem>, onSelect?: (id: string | number) => void): Array<ReactElement> => {
    return items.map((item: TreeListItem) => {
        return (
            <TreeItem onClick={(): void => onSelect?.(item.id)}
                      key={item.id}
                      style={item.disabled ? {color: 'rgb(224,224,224)'} : undefined}
                      icon={!item.visited ? <AutorenewOutlined color={'action'}/> : undefined}
                      nodeId={item.id.toString()}
                      aria-disabled={item.disabled}
                      label={item.name}>
                {generateTreeItems(item.children, onSelect)}
            </TreeItem>
        );
    });
};

const TreeList: FunctionComponent<TreeListProps> = (props: PropsWithChildren<TreeListProps>) => {
    const {classes, label, value, onClick} = props;

    return (
        <div className={classes.container}>
            <fieldset className={classes.fieldset}>
                <legend className={classes.legend}>
                    <span>
                        {label}
                        {
                            props.informationalText &&
                            <Tooltip title={props.informationalText}>
                                <IconButton style={{padding: 0, marginTop: -2, marginLeft: 5}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </span>
                </legend>
                <div className={classes.innerContent}>
                    <PerfectScrollbar>
                        <TreeView
                            style={{width: '95%'}}
                            defaultCollapseIcon={<RemoveCircleOutlined color={'action'}/>}
                            defaultExpandIcon={<AddCircleOutlined color={'action'}/>}
                            defaultEndIcon={<HighlightOffOutlined color={'action'}/>}
                        >
                            {generateTreeItems(value, onClick)}
                        </TreeView>
                    </PerfectScrollbar>
                </div>
            </fieldset>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(treeListStyle, {name: 'MuiCTTreeList'})(TreeList);
