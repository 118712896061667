import {FunctionComponent, PropsWithChildren} from 'react';
import Icon from '@material-ui/core/Icon';
import {
    Accessibility,
    ClassOutlined,
    InfoOutlined,
    LocalOffer,
    MenuBookOutlined,
    RateReviewOutlined,
} from '@material-ui/icons';
import {DashboardProps} from './types';
import {Card, CardBody, CardFooter, CardHeader, CardIcon, GridContainer, GridItem, Info} from '../../atoms';
import {withStyles} from '@material-ui/core';
import {dashboardStyle} from '../../../styles/pages';

const DashboardView: FunctionComponent<DashboardProps> = (props: PropsWithChildren<DashboardProps>) => {
    const {classes} = props;
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <RateReviewOutlined/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Fichas avaliativas</p>
                            <h3 className={classes.cardTitle}>
                                {props.avaliacaes ?? 0}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Info>
                                    <InfoOutlined/>
                                </Info>
                                <a href="/evaluations">
                                    Registar avaliações
                                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <ClassOutlined/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Turmas</p>
                            <h3 className={classes.cardTitle}>
                                {props.turmas ?? 0}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Info>
                                    <InfoOutlined/>
                                </Info>
                                <a href="/class">
                                    Visualizar turmas
                                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <CardIcon color="danger">
                                <MenuBookOutlined/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Disciplinas</p>
                            <h3 className={classes.cardTitle}>
                                {props.disciplinas ?? 0}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer/>
                                Obtido pelo GesAvalia
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Accessibility/>
                            </CardIcon>
                            <p className={classes.cardCategory}>Alunos</p>
                            <h3 className={classes.cardTitle}>
                                {props.alunos ?? 0}
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer/>
                                Obtido pelo GesAvalia
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>info_outline</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>2021-12-11</p>
                            <h3 className={classes.cardTitle}>Informação</h3>
                        </CardHeader>
                        <CardBody>
                            <h3>
                                Correções
                            </h3>
                            <p>
                                É possível agora ordenar as notas na grelha pela data de lançamento das notas
                            </p>
                            <p>
                                Foram introduzidas novas correções ao nível do lançamento das notas e respetivas grelhas
                            </p>
                        </CardBody>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer/>
                                Octávia Figueira
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>info_outline</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>2021-11-28</p>
                            <h3 className={classes.cardTitle}>Informação</h3>
                        </CardHeader>
                        <CardBody>
                            <h3>
                                Novas funcionalidades introduzidas
                            </h3>
                            <p>
                                É possível agora recuperar o acesso à plataforma de forma autónoma (no ecrã de login)
                            </p>
                            <p>
                                É possível agora verificar, na grelha, se a soma dos parâmetros ultrapassa os 100% para um ou mais alunos.
                            </p>
                        </CardBody>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer/>
                                Octávia Figueira
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>info_outline</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>2021-11-11</p>
                            <h3 className={classes.cardTitle}>Informação</h3>
                        </CardHeader>
                        <CardBody>
                            <h3>
                                Nova versão do GesAvalia lançada
                            </h3>
                            <p>
                                Foi lançada a nova versão do GesAvalia para a escola EBS Carmo.
                            </p>
                        </CardBody>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <LocalOffer/>
                                Octávia Figueira
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default withStyles(dashboardStyle)(DashboardView);
