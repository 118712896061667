import {FunctionComponent, PropsWithChildren} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import {CustomTableProps} from './types';
import {tableStyle} from '../../../styles';


const CustomTable: FunctionComponent<CustomTableProps> = (props: PropsWithChildren<CustomTableProps>) => {
    const {classes, tableHead, tableData, tableHeaderColor} = props;

    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ?
                    <TableHead className={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        classes[tableHeaderColor + 'TableHeader']
                    }>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((headItem: string, hIndex: number) => {
                                return (
                                    <TableCell
                                        className={classes.tableCell + ' ' + classes.tableHeadCell}
                                        key={hIndex}
                                    >
                                        {headItem}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                 : null}
                <TableBody>
                    {tableData.map((prop, key) => {
                        return (
                            <TableRow key={key} className={classes.tableBodyRow}>
                                {prop.map((rowItem, rIndex: number) => {
                                    return (
                                        <TableCell className={classes.tableCell} key={rIndex}>
                                            {rowItem}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withStyles(tableStyle, {name: 'MuiCTCustomTable'})(CustomTable);
