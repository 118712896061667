import {FormItem} from '../../../../organisms/Builder';

export const profileFormDefinition: Array<FormItem<string | boolean | Array<string>>> = [
    {
        type: 'text',
        id: 'nome',
        label: 'Nome do ano letivo',
        helperText: 'Introduza o nome do ano letivo',
        state: '',
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
    {
        type: 'switch',
        id: 'active',
        label: '',
        state: true,
        required: true,
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
        gridWidth: 6,
    },
];

