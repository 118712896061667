import {Box, FormControlLabel, IconButton, Switch as SwitchField, Tooltip, Typography} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {ChangeEvent, PropsWithChildren, ReactElement, RefObject} from 'react';
import {ItemProps} from '../types';

const Switch = <T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string>(props: PropsWithChildren<ItemProps<T>>): ReactElement => {
    const _onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void | undefined => {
        return props.onChange(props.item.id, event.target.checked as T);
    };

    return (
        <Box component={'span'}>
            <FormControlLabel
                control={
                    <SwitchField
                        ref={props.item.innerRef as RefObject<HTMLButtonElement>}
                        checked={!!props.item.state}
                        disabled={props.item.disabled}
                        onChange={_onChangeHandler}
                        required={props.item.required}
                        size={props.item.switchOptions?.size}/>
                }
                style={{
                    height: '100%',
                    alignSelf: 'flex-end',
                }}
                label={
                    <Typography variant={'body2'} style={{alignContent: 'center'}}>
                        {props.item.label}
                    </Typography>
                }
                labelPlacement={props.item.switchOptions?.labelPlacement ?? 'start'}
            />
            {
                props.item.informationalText &&
                <Tooltip title={props.item.informationalText}>
                    <IconButton style={{padding: 0, marginTop: 0, marginLeft: -10}}
                                aria-label={'informational-text'}
                    >
                        <InfoOutlined style={{fontSize: '1.0rem'}}/>
                    </IconButton>
                </Tooltip>
            }
        </Box>
    );
};

export default Switch;
