import {Component, ReactNode} from 'react';
import {BaseProps, withAll} from '../../../../../base';
import {ManageState} from '../../types';
import {Column, DataGrid, isSelectable, Selectable} from '../../../../organisms';
import {ChangeSet, EditingState, TableColumnWidthInfo} from '@devexpress/dx-react-grid';
import {ParametroDto, ParametroTurmaProfessorDto} from '../../../../../api/types';
import {routes} from '../../../../../api/constants';

class ManageContainer extends Component<BaseProps, ManageState> {
    public state: Readonly<ManageState> = {
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setSelectItems();
        await this._setClassTeacherParameterList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ManageState>): void {
        if (prevState.parameterClassTeachers !== this.state.parameterClassTeachers) {
            this._setRowsData();
        }
    }

    public render(): ReactNode {
        return (
            <DataGrid
                title={'Lista de parâmetros'}
                gridHeaderColor={'primary'}
                columns={this._getColumns()}
                getRowId={this._getRowId}
                columnExtensions={this._getColumnExtensions()}
                defaultColumnWidths={this._getDefaultColumnWidths()}
                numberColumns={['numero']}
                rows={this.state.rows}
                availableRowValues={this.state.availableRowValues}
                initialSortColumn={'numero'}
                hiddenColumns={['id']}
                onReturn={this._onReturn}
                onCommitChanges={this._onCommitChanges}
            />
        );
    }

    private _getColumns = (): Array<Column> => {
        return [
            {
                name: 'id',
                title: 'ID',
            },
            {
                name: 'parametro',
                title: 'Parametro',
                required: true,
                getCellValue: (row: { [key: string]: Selectable }): string | number | boolean => {
                    return row.parametro?.value;
                },
            },
        ];
    };

    private _getColumnExtensions = (): Array<EditingState.ColumnExtension> => {
        return [
            {
                columnName: 'id',
            },
            {
                columnName: 'parametro',
                createRowChange: (_row: { [key: string]: Selectable }, value: Selectable): { [key: string]: Selectable } => ({
                    parametro: {
                        ...value,
                    },
                }),
            },
        ];
    };

    private _getDefaultColumnWidths = (): Array<TableColumnWidthInfo> => {
        return [
            {
                columnName: 'id',
                width: 0,
            },
            {
                columnName: 'parametro',
                width: '65%',
            },
        ];
    };

    private _setClassTeacherParameterList = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const results = await Promise.all([
                this.props.class?.teacherParameterClassTeacherList?.(this.props.match.params.classId),
            ]);
            this.setState({
                    parameterClassTeachers: results[0] ?? [],
                },
            );
        }
    };

    private _setSelectItems = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const results = await Promise.all([
                this.props.class?.parameterList?.(this.props.match.params.classId),
                this.props.group?.list?.(),
            ]);

            const parameterOptions: Array<Selectable> = results?.[0]?.map((parameter: ParametroDto) => {
                return {
                    key: `${routes.parameters}/${parameter.id ?? 0}`,
                    value: `${parameter.nome} - ${parameter.percentagem}% - ${results[1]?.find(g => g.id && parameter.grupoDisciplinar?.includes(g.id))?.numero?.toString() ?? 'S/G'}`,
                };
            }) ?? [];

            this.setState({
                availableRowValues: {
                    parametro: parameterOptions,
                },
            });
        }
    };

    private _setRowsData = (): void => {
        const parameterClassTeachers = this.state.parameterClassTeachers;
        const availableRowValues = this.state.availableRowValues;

        this.setState({
            rows: parameterClassTeachers?.map(parameterClassTeacher => {
                return {
                    id: parameterClassTeacher.id ?? '',
                    parametro: availableRowValues?.parametro?.find(v => v.key === parameterClassTeacher.parametro) ?? null,
                    editDisabled: true,
                };
            }),
        });
    };

    private _getRowId = (row: { [key: string]: string | number | boolean | Record<string, unknown> | null }): number | string => {
        return 'id' in row ? row.id?.toString() ?? -1 : -1;
    };

    private _onCommitChanges = async (changes: ChangeSet): Promise<void> => {
        await this._handleDelete(changes.deleted);
        await this._handleAdded(changes.added);
    };

    private _handleAdded = async (data?: ReadonlyArray<{ [key: string]: string | number | boolean | Selectable | null }>): Promise<void> => {
        const classId = this.props.match.params.classId;
        const teacherId = this.props.session?.user.professor;

        if (classId && teacherId && data) {
            const rows = this.state.rows;
            this.setState({
                rows: [
                    ...rows ?? [],
                    ...data.map(d => {
                        return {
                            id: 'not-available',
                            parametro: d.parametro,
                            editDisabled: true,
                        };
                    }),
                ],
            });

            const result = await Promise.all(data.map(d => {
                if (isSelectable(d.parametro)) {
                    return this.props.parameterClassTeacher?.create?.({
                        parametro: d.parametro.key.toString(),
                        turma: `${routes.classes}/${classId}`,
                        professor: teacherId,
                    });
                }
                return undefined;
            }));

            if (result.includes(undefined)) {
                this.setState({
                    rows: [...rows ?? []],
                });
            } else {
                this.setState({
                    parameterClassTeachers: [
                        ...this.state.parameterClassTeachers ?? [],
                        ...result as Array<ParametroTurmaProfessorDto>,
                    ],
                });
            }
        }
    };

    private _handleDelete = async (data?: ReadonlyArray<number | string>): Promise<void> => {
        if (data) {
            const parameterClassTeacher = this.state.parameterClassTeachers;
            this.setState({
                parameterClassTeachers: [...parameterClassTeacher?.filter(s => s.id && !data?.includes(s.id)) ?? []],
            });

            const result = await Promise.all(data.map(id => this.props.parameterClassTeacher?.delete?.(id.toString())));
            if (result.includes(undefined)) {
                this.setState({
                    parameterClassTeachers: [...parameterClassTeacher ?? []],
                });
            }
        }
    };

    private _onReturn = (): void => {
        this.props.history.goBack();
    };

}

export default withAll(ManageContainer);
