import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, Roles, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {ButtonGroup} from '@material-ui/core';
import {ActionButton} from '../../../../atoms';
import {
    AssessmentOutlined,
    DeleteOutline,
    EditOutlined,
    LibraryAddCheckOutlined,
    LockOpenOutlined,
    LockOutlined,
} from '@material-ui/icons';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {EvaluationPaths} from '../../../../../router/paths/evaluationPaths';
import {StudentEvaluationsPaths} from '../../../../../router/paths/studentEvaluationsPaths';
import {GradesPaths} from '../../../../../router/paths/gradesPaths';
import {LockOption} from '../../../../../api/enums';
import {Gate} from '../../../../organisms';
import {AvaliacaoDto} from '../../../../../api/types';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['disciplina', 'turma', 'nome'];

    public state: Readonly<ListState> = {
        listHeader: ['DISCIPLINA', 'TURMA', 'NOME', 'AÇÕES'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setEvaluationsList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.evaluations !== this.state.evaluations) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={this.state.alertOpen}
                dialogTitle={'Eliminar a avaliação?'}
                dialogContent={'Tem a certeza de que deseja eliminar esta avaliação?'}
                tableHeaderColor={'primary'}
                title={'Lista de avaliações'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onActionMessage={'Nova avaliação'}
                onAction={this.props.gate?.(Roles.ROLE_USER, this._create)}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setEvaluationsList = async (): Promise<void> => {
        if (this.props.session) {

            const results = await Promise.all([
                this.props.evaluation?.list?.(),
                this.props.discipline?.list?.(),
                this.props.class?.list?.(),
            ]);

            this.setState({
                evaluations: results[0]?.map(e => {
                    e.disciplina = results[1]?.find(d => d.id && e.disciplina?.includes(d.id))?.nome ?? 'S/D';
                    e.turma = results[2]?.find(c => c.id && e.turma?.includes(c.id))?.nome ?? 'S/T';
                    return e;
                }) ?? [],
            });
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.evaluations?.map(value => {
            const data: { [key: string]: string | boolean } = value as { [key: string]: string | boolean };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });

            if (value.id) {
                result.push(this._generateActionButtons(value));
            }
            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _generateActionButtons = (avaliacao: AvaliacaoDto): ReactElement => {
        if (avaliacao.id) {
            return (
                <ButtonGroup>
                    <Gate role={Roles.ROLE_ADMIN}>
                        <ActionButton icon={<LockOpenOutlined/>} title={'Desbloquear avaliação'}
                                      ariaLabel={'desbloquear'}
                                      onClick={this._setLock(avaliacao.id, LockOption.DESBLOQUEAR)}/>
                    </Gate>
                    <Gate role={Roles.ROLE_ADMIN}>
                        <ActionButton icon={<LockOutlined/>} title={'Bloquear avaliação'} ariaLabel={'bloquear'}
                                      onClick={this._setLock(avaliacao.id, LockOption.BLOQUEAR)}/>
                    </Gate>
                    <Gate role={Roles.ROLE_USER}>
                        <ActionButton icon={<LibraryAddCheckOutlined/>} title={'Notas'} ariaLabel={'notas'}
                                      onClick={this._grades(avaliacao.id)}/>
                    </Gate>
                    <Gate role={Roles.ROLE_USER}>
                        <ActionButton icon={<AssessmentOutlined/>} title={'Grelha'} ariaLabel={'grelha'}
                                      onClick={this._report(avaliacao.id)}/>
                    </Gate>
                    <Gate role={Roles.ROLE_USER}>
                        <ActionButton icon={<EditOutlined/>} title={'Editar'} ariaLabel={'editar'}
                                      onClick={this._edit(avaliacao.id)}/>
                    </Gate>
                    <Gate role={Roles.ROLE_USER}>
                        <ActionButton icon={<DeleteOutline/>}
                                      title={avaliacao.avaliacaoAlunos && avaliacao.avaliacaoAlunos.length > 0 ?
                                          'Não é possível eliminar esta avaliação pois tem notas lançadas' :
                                          'Eliminar'}
                                      disabled={avaliacao.avaliacaoAlunos && avaliacao.avaliacaoAlunos.length > 0}
                                      ariaLabel={'eliminar'}
                                      onClick={this._delete(avaliacao.id)}
                        />
                    </Gate>
                </ButtonGroup>
            );
        }
        return <></>;
    };

    private _create = (): void => {
        this.props.history.push(generateLink(EvaluationPaths.CREATE));
    };

    private _edit = (id: string) => (): void => {
        this.props.history.push(generateLink(EvaluationPaths.EDIT, {evaluationId: id}));
    };

    private _grades = (id: string) => (): void => {
        this.props.history.push(generateLink(StudentEvaluationsPaths.MANAGE, {evaluationId: id}));
    };

    private _report = (id: string) => (): void => {
        this.props.history.push(generateLink(GradesPaths.REPORT, {evaluationId: id}));
    };

    private _setLock = (id: string, status: LockOption) => (): void => {
        this.props.lock?.evaluation?.(id, status);
    };

    private _delete = (id: string) => (): void => {
        this.setState({
            alertOpen: true,
            confirmAction: async () => {
                if (this.props.session) {
                    const data = this.state.evaluations;
                    if (await this.props.evaluation?.delete?.(id)) {
                        this.setState({
                            evaluations: [...data?.filter(t => t.id !== id) ?? []],
                        });
                    }
                }
                this.setState({
                    alertOpen: false,
                    confirmAction: undefined,
                });
            },
        });
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };
}

export default withAll(ListContainer);
