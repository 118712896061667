import {FunctionComponent, ReactElement} from 'react';
import {Box, IconButton, Tooltip} from '@material-ui/core';
import {SaveOutlined} from '@material-ui/icons';
import {TableEditColumn as TableEditColumnBase} from '@devexpress/dx-react-grid';

export const SaveButton: FunctionComponent<TableEditColumnBase.CommandProps & { disabled?: boolean }> = (props: TableEditColumnBase.CommandProps & { disabled?: boolean }): ReactElement => {
    return (
        <Tooltip title={'Clique para guardar este registo'}>
            <Box component={'span'}>
                <IconButton disabled={props.disabled} onClick={props.onExecute}>
                    <SaveOutlined/>
                </IconButton>
            </Box>
        </Tooltip>
    );
};
