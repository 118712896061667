import {Route} from '../types';
import {ListAltOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {Create, Edit, Group, List} from '../../components/pages/Group';
import {ReactElement} from 'react';
import {GroupPaths} from './groupPaths';

export const groupPaths: Array<Route<BaseProps>> = [
    {
        key: GroupPaths.HOME,
        name: 'Grupos',
        path: '/groups',
        icon: ListAltOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Group/>,
    },
    {
        key: GroupPaths.LIST,
        parent: GroupPaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: GroupPaths.CREATE,
        parent: GroupPaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: GroupPaths.EDIT,
        parent: GroupPaths.HOME,
        path: '/edit/:groupId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

