import {Component, ComponentClass, FunctionComponent, ReactElement, ReactNode} from 'react';
import {
    Grid,
    PagingPanel,
    Table,
    TableColumnVisibility,
    TableEditColumn,
    TableEditRow,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {DataGridProps, DataGridState} from './types';
import {
    EditingState,
    Filter,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    Sorting,
    SortingState,
    Table as TableBase,
    TableColumnResizing,
    TableEditColumn as TableEditColumnBase,
    TableEditRow as TableEditRowBase,
} from '@devexpress/dx-react-grid';
import {BooleanTypeProvider, NumberTypeProvider} from './providers';
import {ColumnCellEditor, SelectEditor} from './editors';
import _ from 'lodash';
import {CommandComponent} from './buttons';
import {validateExistingRows, validateNewRows} from './validators';
import {Card, CardBody, CardHeader, Dialog, GridContainer, GridItem, RegularButton} from '../../atoms';
import {withStyles} from '@material-ui/core';
import {dataGridStyle} from '../../../styles';
import {AddCircleOutlined, ArrowBackIos} from '@material-ui/icons';
import {Actions, Getters, TemplateConnector} from '@devexpress/dx-react-core';

const PAGE_SIZE_DEFAULT = 10;

class DataGrid extends Component<DataGridProps, DataGridState> {
    public state: Readonly<DataGridState> = {
        addedRows: [],
        editingRowIds: [],
        previousRowState: [],
        rowChanges: {},
        errors: {},
        rows: [],
        currentPage: 0,
        pageSize: PAGE_SIZE_DEFAULT,
        pageSizes: [PAGE_SIZE_DEFAULT, PAGE_SIZE_DEFAULT * 2, PAGE_SIZE_DEFAULT * 5],
        sorting: [{columnName: this.props.initialSortColumn, direction: 'asc'}],
        filters: [],
    };

    private selectEditor: ComponentClass<TableEditRowBase.CellProps, Record<string, unknown>> | FunctionComponent<TableEditRowBase.CellProps> | undefined;

    public componentDidMount(): void {
        this._setRows();
        this.selectEditor = SelectEditor(this.props.availableRowValues);
    }

    public componentDidUpdate(prevProps: Readonly<DataGridProps>, prevState: Readonly<DataGridState>): void {
        if (prevProps.availableRowValues !== this.props.availableRowValues) {
            this.selectEditor = SelectEditor(this.props.availableRowValues);
        }

        if (prevProps.rows !== this.props.rows) {
            this._setRows();
        }

        if (prevState.rowChanges !== this.state.rowChanges) {
            this._updateRowChanges();
        }
    }

    public render(): ReactNode {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Dialog title={this.props.dialogTitle ?? ''}
                            open={!!this.props.dialogOpen}
                            content={this.props.dialogContent ?? ''}
                            onConfirm={this.props.onDialogConfirm}
                            onClose={this.props.onDialogCancel}
                    />
                </GridItem>
                {
                    this.props.onReturn &&
                    <GridItem xs={6} sm={6} md={6}
                              style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <RegularButton
                            id={'MuiCTTableListReturnButton'}
                            variant={'contained'}
                            color={'white'}
                            size={'lg'}
                            type={'button'}
                            startIcon={<ArrowBackIos/>}
                            onClick={this.props.onReturn}
                        >
                            Voltar
                        </RegularButton>
                    </GridItem>
                }
                {
                    this.props.onAction &&
                    <GridItem xs={this.props.onReturn ? 6 : 12} sm={this.props.onReturn ? 6 : 12}
                              md={this.props.onReturn ? 6 : 12}
                              style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <RegularButton
                            id={'MuiCTTableListAddButton'}
                            variant={'contained'}
                            color={'primary'}
                            size={'lg'}
                            type={'button'}
                            startIcon={this.props.onActionIcon ?? <AddCircleOutlined/>}
                            onClick={this.props.onAction}
                        >
                            {this.props.onActionMessage ?? 'Novo'}
                        </RegularButton>
                    </GridItem>
                }
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color={this.props.gridHeaderColor}>
                                    <GridContainer>
                                        <GridItem xs={8} sm={8} md={8}>
                                            <h4 className={this.props.classes.cardTitleWhite}>{this.props.title}</h4>
                                            <p className={this.props.classes.cardCategoryWhite}>
                                                {this.props.subtitle}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                                <CardBody>
                                    <Grid
                                        rows={this.state.rows}
                                        columns={this.props.columns ?? []}
                                        getRowId={this.props.getRowId}
                                    >
                                        {
                                            this.props.booleanColumns &&
                                            <BooleanTypeProvider
                                                for={this.props.booleanColumns}
                                            />
                                        }
                                        {
                                            this.props.numberColumns &&
                                            <NumberTypeProvider
                                                for={this.props.numberColumns}
                                            />
                                        }
                                        <SortingState
                                            sorting={this.state.sorting}
                                            onSortingChange={this._handleOnSortingChange}
                                        />
                                        <FilteringState
                                            filters={this.state.filters}
                                            onFiltersChange={this._handleOnFiltersChange}
                                        />
                                        <PagingState
                                            currentPage={this.state.currentPage}
                                            onCurrentPageChange={this._handleOnCurrentPageChange}
                                            pageSize={this.state.pageSize}
                                            onPageSizeChange={this._handleOnPageSizeChange}
                                        />
                                        <EditingState
                                            editingRowIds={this.state.editingRowIds}
                                            rowChanges={this.state.rowChanges}
                                            addedRows={this.state.addedRows}
                                            columnExtensions={this.props.columnExtensions}
                                            onAddedRowsChange={this._handleOnAddedRowsChange}
                                            onEditingRowIdsChange={this._handleEditingRowIdsChange}
                                            onRowChangesChange={this._handleOnRowChangesChange}
                                            onCommitChanges={this.props.onCommitChanges}
                                        />
                                        <IntegratedSorting/>
                                        <IntegratedFiltering/>
                                        <IntegratedPaging/>
                                        <Table
                                            rowComponent={(props: TableBase.DataRowProps): ReactElement =>
                                                <TemplateConnector>
                                                    {(_getters: Getters, actions: Actions): ReactElement =>
                                                        <Table.Row {...props}
                                                                   onDoubleClick={this._handleRowDoubleClick(props.row.id, actions)}>
                                                            {props.children}
                                                        </Table.Row>
                                                    }
                                                </TemplateConnector>
                                            }
                                        />
                                        {
                                            this.props.defaultColumnWidths &&
                                            <TableColumnResizing
                                                defaultColumnWidths={this.props.defaultColumnWidths}
                                                resizingMode={'nextColumn'}
                                            />
                                        }
                                        <TableHeaderRow
                                            showSortingControls
                                        />
                                        <TableEditRow
                                            cellComponent={this.selectEditor}
                                        />
                                        <TableEditColumn
                                            showAddCommand={!this.props.disableAdd}
                                            commandComponent={CommandComponent}
                                            showEditCommand
                                            showDeleteCommand
                                            cellComponent={(props: TableEditColumnBase.CellProps): ReactElement =>
                                                <ColumnCellEditor
                                                    errors={this.state.errors} {...props}/>}
                                        />
                                        <TableFilterRow/>
                                        {
                                            this.props.hiddenColumns &&
                                            <TableColumnVisibility
                                                defaultHiddenColumnNames={this.props.hiddenColumns}
                                            />
                                        }
                                        <PagingPanel
                                            pageSizes={this.state.pageSizes}
                                        />
                                    </Grid>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }

    private _handleEditingRowIdsChange = (editingRowIds: Array<number | string>): void => {
        this.setState({editingRowIds});
    };

    private _handleOnRowChangesChange = (rowChanges: { [key: number]: { [key: string]: string | number | boolean | Record<string, unknown> | null } }): void => {
        this.setState({
            errors: validateExistingRows(rowChanges, this.props.columns?.filter(v => v.required) ?? []),
            rowChanges,
        });
    };

    private _handleOnAddedRowsChange = (addedRows: Array<{ [key: string]: string | number | boolean | Record<string, unknown> | null }>): void => {
        this.setState({
            errors: validateNewRows(addedRows, this.props.columns?.filter(v => v.required) ?? []),
            addedRows,
        });
    };

    private _handleOnCurrentPageChange = (currentPage: number): void => {
        this.setState({currentPage});
    };

    private _handleOnPageSizeChange = (pageSize: number): void => {
        this.setState({pageSize});
    };

    private _handleOnSortingChange = (sorting: Array<Sorting>): void => {
        this.setState({sorting});
    };

    private _handleOnFiltersChange = (filters: Array<Filter>): void => {
        this.setState({filters});
    };

    private _setRows = (): void => {
        this.setState({
            rows: [..._.cloneDeep(this.props.rows ?? [])],
        });
    };

    private _handleRowDoubleClick = (rowId: string, actions: Actions) => (): void => {
        const row = this.state.rows.find(r => r.id === rowId);
        if (!this.state.editingRowIds.includes(rowId) && row?.editDisabled === false) {
            actions.startEditRows({rowIds: [rowId]});
        }
    };

    private _updateRowChanges = (): void => {
        const getRowId = this.props.getRowId;
        const rows = this.state.rows;
        const rowChanges = this.state.rowChanges;
        let previousRowState = this.state.previousRowState;

        Object.keys(rowChanges).forEach((key: string | number) => {
            if (!previousRowState.includes(key.toString())) {
                previousRowState.push(key.toString());
            }

            Object.keys(rowChanges[key]).forEach((column: string) => {
                if (getRowId) {
                    const index = rows.findIndex(v => getRowId(v) === key);
                    rows[index][column] = rowChanges[key][column];
                } else if (typeof key === 'number') {
                    rows[key][column] = rowChanges[key][column];
                }
            });
        });

        previousRowState.forEach((key: string | number) => {
            if (!(key in rowChanges) && this.props.rows) {
                if (getRowId) {
                    const currIndex = rows.findIndex(v => getRowId(v) === key);
                    const rowIndex = this.props.rows.findIndex(v => getRowId(v) === key);
                    rows[currIndex] = _.cloneDeep(this.props.rows[rowIndex]);
                } else if (typeof key === 'number') {
                    rows[key] = _.cloneDeep(this.props.rows[key]);
                }
                previousRowState = previousRowState.filter(v => v !== key);
            }
        });

        this.setState({
            rows: [...rows],
            previousRowState,
        });
    };
}

export default withStyles(dataGridStyle, {name: 'MuiCTDataGrid'})(DataGrid);
