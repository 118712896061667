import {Route} from '../types';
import {BaseProps, Roles} from '../../base';
import {List, Manage} from '../../components/pages/ClassStudents';
import {ClassStudentsPaths} from './classStudentsPaths';

export const classStudentsPaths: Array<Route<BaseProps>> = [
    {
        key: ClassStudentsPaths.LIST,
        parent: ClassStudentsPaths.HOME,
        path: '/',
        role: Roles.ROLE_USER,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: ClassStudentsPaths.MANAGE,
        parent: ClassStudentsPaths.HOME,
        path: '/manage',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Manage,
    },
];

