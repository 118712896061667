import {Route} from '../types';
import {DynamicFeedOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {CyclePaths} from './cyclePaths';
import {Create, Cycle, Edit, List} from '../../components/pages/Cycle';
import {ReactElement} from 'react';

export const cyclePaths: Array<Route<BaseProps>> = [
    {
        key: CyclePaths.HOME,
        name: 'Ciclos',
        path: '/cycles',
        icon: DynamicFeedOutlined,
        role: Roles.ROLE_CONFIG,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Cycle/>,
    },
    {
        key: CyclePaths.LIST,
        parent: CyclePaths.HOME,
        path: '/',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: List,
    },
    {
        key: CyclePaths.CREATE,
        parent: CyclePaths.HOME,
        path: '/new',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Create,
    },
    {
        key: CyclePaths.EDIT,
        parent: CyclePaths.HOME,
        path: '/edit/:cycleId',
        role: Roles.ROLE_CONFIG,
        exact: true,
        hidden: true,
        component: Edit,
    },
];

