import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {TurmaDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadClass();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar turma'}
                classProfile={this.state.clazz}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | Array<string> | number>>): Promise<void> => {
        const turmaDto: TurmaDto = extractObjectFromResult<string | boolean | Array<string> | number, TurmaDto>(result);
        this.setState({
            clazz: turmaDto,
        });

        if (this.props.session &&
            this.props.match.params.classId &&
            await this.props.class?.update?.(this.props.match.params.classId, turmaDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadClass = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const clazz: TurmaDto | undefined = await this.props.class?.get?.(this.props.match.params.classId);
            this.setState({clazz});
        }
    };
}

export default withAll(EditContainer);
