import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, Roles, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {ButtonGroup} from '@material-ui/core';
import {ActionButton} from '../../../../atoms';
import {DeleteOutline, EditOutlined} from '@material-ui/icons';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {DisciplinePaths} from '../../../../../router/paths/disciplinePaths';
import {Gate} from '../../../../organisms';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['nome', 'grupoDisciplinar'];

    public state: Readonly<ListState> = {
        listHeader: ['NOME', 'GRUPO', 'AÇÕES'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setDisciplinesList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.disciplines !== this.state.disciplines) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={this.state.alertOpen}
                dialogTitle={'Eliminar o disciplina?'}
                dialogContent={'Tem a certeza de que deseja eliminar este disciplina?'}
                tableHeaderColor={'primary'}
                title={'Lista de disciplinas'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onActionMessage={'Nova disciplina'}
                onAction={this.props.gate?.(Roles.ROLE_CONFIG, this._create)}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setDisciplinesList = async (): Promise<void> => {
        if (this.props.session) {
            const results = await Promise.all([
                this.props.discipline?.list?.(),
                this.props.group?.list?.(),
            ]);
            this.setState({
                disciplines: results[0]?.map(d => {
                    d.grupoDisciplinar = results[1]?.find(
                        g => g.id && d.grupoDisciplinar?.includes(g.id),
                    )?.numero?.toString() ?? d.grupoDisciplinar;
                    return d;
                }) ?? [],
            });
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.disciplines?.map(value => {
            const data: { [key: string]: string | boolean } = value as { [key: string]: string | boolean };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });

            if (value.id) {
                result.push(this._generateActionButtons(value.id));
            }
            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _generateActionButtons = (id: string): ReactElement => {
        return (
            <ButtonGroup>
                <Gate role={Roles.ROLE_CONFIG}>
                    <ActionButton icon={<EditOutlined/>} title={'Editar'} ariaLabel={'editar'}
                                  onClick={this._edit(id)}/>
                </Gate>
                <Gate role={Roles.ROLE_CONFIG}>
                    <ActionButton icon={<DeleteOutline/>} title={'Eliminar'} ariaLabel={'eliminar'}
                                  onClick={this._delete(id)}/>
                </Gate>
            </ButtonGroup>
        );
    };

    private _create = (): void => {
        this.props.history.push(generateLink(DisciplinePaths.CREATE));
    };

    private _edit = (id: string) => (): void => {
        this.props.history.push(generateLink(DisciplinePaths.EDIT, {disciplineId: id}));
    };

    private _delete = (id: string) => (): void => {
        this.setState({
            alertOpen: true,
            confirmAction: async () => {
                if (this.props.session) {
                    const data = this.state.disciplines;
                    if (await this.props.discipline?.delete?.(id)) {
                        this.setState({
                            disciplines: [...data?.filter(t => t.id !== id) ?? []],
                        });
                    }
                }
                this.setState({
                    alertOpen: false,
                    confirmAction: undefined,
                });
            },
        });
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };
}

export default withAll(ListContainer);
