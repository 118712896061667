import {IconButton, TextField, Tooltip} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import {Autocomplete, AutocompleteRenderInputParams, createFilterOptions} from '@material-ui/lab';
import {FilterOptionsState} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import {ChangeEvent, Component, Fragment, ReactElement} from 'react';
import {isSelectItem, ItemProps, SelectItem, SelectState} from '../types';

class Select<T extends string | number | boolean | Record<string, unknown> | Array<string | number | Record<string, unknown>> | null = string> extends Component<ItemProps<T>, SelectState> {
    public componentDidMount(): void {
        this._setInitialItem();
    }

    public shouldComponentUpdate(nextProps: Readonly<ItemProps<T>>, nextState: Readonly<SelectState>): boolean {
        return nextProps.item.options !== this.props.item.options
            || nextProps.item.state !== this.props.item.state
            || nextState !== this.state;
    }

    public componentDidUpdate(prevProps: Readonly<ItemProps<T>>, _prevState: Readonly<SelectState>): void {
        if (prevProps.item !== this.props.item) {
            this._setInitialItem();
        }
    }

    public render(): ReactElement {
        return (
            <Fragment>
                <Autocomplete
                    id={this.props.item.id}
                    limitTags={this.props.item.selectOptions?.limitTags}
                    options={this.props.item?.options?.filter((item: SelectItem) => item.active === undefined || item.active) ?? []}
                    autoComplete={this.props.item.selectOptions?.autoComplete}
                    openOnFocus={this.props.item.selectOptions?.openOnFocus}
                    selectOnFocus={this.props.item.selectOptions?.selectOnFocus}
                    clearOnBlur={this.props.item.selectOptions?.clearOnBlur}
                    multiple={this.props.item.selectOptions?.multiple}
                    freeSolo={this.props.item.selectOptions?.freeSolo}
                    disabled={this.props.item.disabled}
                    disableCloseOnSelect={this.props.item.selectOptions?.disableCloseOnSelect}
                    value={this.state?.item ?? (this.props.item.selectOptions?.multiple ? [] : null)}
                    renderOption={this.props.item.selectOptions?.renderOption}
                    onChange={this._onChangeHandler}
                    getOptionSelected={this._getOptionSelected}
                    getOptionLabel={this._getOptionLabel}
                    renderInput={this._renderInputHandler}
                    filterOptions={this._filterOptions}
                />
            </Fragment>
        );
    }

    private _filterOptions = (options: Array<SelectItem>, state: FilterOptionsState<SelectItem>): Array<SelectItem> => {
        const filter = createFilterOptions<SelectItem>();
        const filtered = filter(options, state);

        if (this.props.item.selectOptions?.onAdd && state.inputValue !== '') {
            filtered.push({
                key: state.inputValue,
                value: `${this.props.item.selectOptions?.onAddText ?? 'Add'} "${state.inputValue}"`,
                new: true,
            });
        }

        return filtered;
    };

    private _getOptionLabel = (option: SelectItem): string => {
        return option.value;
    };

    private _getOptionSelected = (option: SelectItem, value: SelectItem): boolean => {
        return option.key === value.key;
    };

    private _renderInputHandler = (params: AutocompleteRenderInputParams): ReactElement => {
        const endAdornment = Object(params.InputProps.endAdornment);
        return (
            <TextField
                {...params}
                InputProps={{
                    ref: params.InputProps.ref,
                    className: params.InputProps.className,
                    startAdornment: params.InputProps.startAdornment,
                    endAdornment: this.props.item.informationalText && endAdornment && 'props' in endAdornment
                        ?
                        <div className={endAdornment.props.className}>
                            {endAdornment.props.children}
                            <Tooltip title={this.props.item.informationalText}>
                                <IconButton style={{padding: 2, marginRight: -2}}
                                            aria-label={'informational-text'}
                                >
                                    <InfoOutlined style={{fontSize: '1.0rem'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        : endAdornment,
                }}
                inputRef={this.props.item.innerRef}
                required={this.props.item.required}
                label={this.props.item.label}
                fullWidth={this.props.item.fullWidth}
                helperText={this.props.item.helperText}
                margin={this.props.item.margin}
                variant={this.props.item.variant}
            />
        );
    };

    private _onChangeHandler = (_event: ChangeEvent<Record<string, unknown>>, item: string | SelectItem | Array<string | SelectItem> | null): void => {
        const value: T = this._getItemValue(item);

        if (this.props.item.selectOptions?.onAdd && isSelectItem(item) && item?.new && item?.key) {
            this.props.item.selectOptions.onAdd(item.key.toString());
        }

        if (this.props.onChange) {
            this.props.onChange(this.props.item.id, value);
        }
        this.setState({item});
    };

    private _getItemValue = (item: string | SelectItem | Array<string | SelectItem> | null): T => {
        let value: T;

        if (isSelectItem(item)) {
            value = item.key as T;
        } else if (Array.isArray(item)) {
            value = item.map(v => {
                return isSelectItem(v) ? v.key : v;
            }) as T;
        } else {
            value = item as T;
        }

        return value;
    };

    private _setInitialItem = (): void => {
        if (this.props.item.options) {
            let item: SelectItem | Array<SelectItem | string> | undefined;
            if (Array.isArray(this.props.item.state)) {
                item = this.props.item.state.map(state => this.props.item.options?.find((selectItem: SelectItem) => selectItem.key === state) ?? '');
            } else {
                item = this.props.item.options.find((selectItem: SelectItem) => selectItem.key === this.props.item.state);
            }
            this.setState({
                item: item,
            });
        }
    };
}

export default Select;
