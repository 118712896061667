import {FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {profileFormDefinition} from './profile.form.definition';
import {Cancel, Save} from '@material-ui/icons';
import _ from 'lodash';
import {FormBuilder, FormItem} from '../../../../organisms/Builder';
import {ProfileProps} from '../../types';


const ProfileContainer: FunctionComponent<ProfileProps> = (props: PropsWithChildren<ProfileProps>): ReactElement => {
    const _formDefinition: Array<FormItem> = _.cloneDeep(profileFormDefinition);
    return (
        <FormBuilder
            title={props.title}
            autoComplete={'on'}
            onSubmit={props.onSubmit}
            onSubmitButtonText={'Guardar'}
            onSubmitButtonIcon={<Save/>}
            onCancelButtonText={'Cancelar'}
            onCancelButtonIcon={<Cancel/>}
            onCancel={props.onCancel}
            items={_formDefinition}
        />
    );
};

export default ProfileContainer;

