import {Component, ReactElement, ReactNode} from 'react';
import {BaseProps, Roles, withAll} from '../../../../../base';
import {ListState} from '../../types';
import {generateLink} from '../../../../../router';
import {PaginatedTableListWithSearch} from '../../../../organisms/PaginatedTableListWithSearch';
import {ClassStudentsPaths} from '../../../../../router/paths/classStudentsPaths';
import {DeleteOutline, EditOutlined} from '@material-ui/icons';
import {ButtonGroup} from '@material-ui/core';
import {Gate} from '../../../../organisms';
import {ActionButton} from '../../../../atoms';

class ListContainer extends Component<BaseProps, ListState> {
    private _columns: Array<string> = ['numero', 'nomeAluno'];

    public state: Readonly<ListState> = {
        listHeader: ['NÚMERO', 'NOME', 'AÇÕES'],
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setClassStudentList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ListState>): void {
        if (prevState.studentClass !== this.state.studentClass) {
            this._setListData();
        }
    }

    public render(): ReactNode {
        return (
            <PaginatedTableListWithSearch
                onDialogConfirm={this.state.confirmAction}
                dialogOpen={this.state.alertOpen}
                dialogTitle={'Eliminar o aluno?'}
                dialogContent={'Tem a certeza de que deseja eliminar este aluno da turma? As avaliações também serão removidas!'}
                tableHeaderColor={'primary'}
                title={'Lista de alunos da turma'}
                listHeader={this.state.listHeader}
                listData={this.state.listData ?? []}
                onReturn={this._onReturn}
                onActionMessage={'Editar alunos'}
                onActionIcon={<EditOutlined/>}
                onAction={this.props.gate?.(Roles.ROLE_CONFIG, this._manage)}
                onDialogCancel={this._cancel}
            />
        );
    }

    private _setClassStudentList = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const result = await Promise.all([
                this.props.student?.list?.(),
                this.props.studentClass?.listByClass?.(this.props.match.params.classId),
            ]);

            this.setState({
                studentClass: result[1]?.map(v => {
                    v.nomeAluno = result[0]?.find(s => s.id && v.aluno.toString().includes(s.id))?.nome ?? v.aluno;
                    return v;
                }) ?? [],
            });
        }
    };

    private _setListData = (): void => {
        const listData: Array<Array<string | ReactElement>> = this.state.studentClass?.map(value => {
            const data: { [key: string]: string | number | boolean } = value as { [key: string]: number | string | boolean };
            const result: Array<string | ReactElement> = this._columns.map(columnHeader => {
                return data[columnHeader]?.toString() ?? '';
            });

            if (value.id) {
                result.push(this._generateActionButtons(value.aluno.split('/')[2]));
            }

            return result;
        }) ?? [];
        this.setState({listData});
    };

    private _manage = (): void => {
        this.props.history.push(generateLink(ClassStudentsPaths.MANAGE, {classId: this.props.match.params.classId}));
    };

    private _generateActionButtons = (id: string): ReactElement => {
        return (
            <ButtonGroup>
                <Gate role={Roles.ROLE_ADMIN}>
                    <ActionButton icon={<DeleteOutline/>} title={'Eliminar'} ariaLabel={'eliminar'}
                                  onClick={this._delete(id)}/>
                </Gate>
            </ButtonGroup>
        );
    };

    private _delete = (id: string) => (): void => {
        this.setState({
            alertOpen: true,
            confirmAction: async () => {
                if (this.props.session && this.props.match.params.classId) {
                    const data = this.state.studentClass;
                    if (await this.props.class?.studentDelete?.(this.props.match.params.classId, id)) {
                        this.setState({
                            studentClass: [...data?.filter(t => !t.aluno.includes(id)) ?? []],
                        });
                    }
                }
                this.setState({
                    alertOpen: false,
                    confirmAction: undefined,
                });
            },
        });
    };

    private _cancel = (): void => {
        this.setState({
            alertOpen: false,
            confirmAction: undefined,
        });
    };

    private _onReturn = (): void => {
        this.props.history.goBack();
    };
}

export default withAll(ListContainer);
