import {SvgIconComponent} from '@material-ui/icons';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {Notification} from '../../base';
import {DISMISS, NOTIFY} from '../constants';
import {Store} from '../types';
import {Action} from './index';

export type Notify = {
    type: NOTIFY | DISMISS;
    notification?: Notification;
};

export const notify = (
    message: string,
    color?: 'info' | 'warning' | 'danger' | 'primary' | 'success',
    icon?: SvgIconComponent,
    place?: 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc',
    close?: boolean,
    progress?: boolean,
): ThunkAction<void, Store, unknown, Action> => {
    return (dispatch: ThunkDispatch<Store, unknown, Action>): void => {
        dispatch({
            type: NOTIFY,
            notification: {
                message,
                color,
                icon,
                place,
                close,
                progress,
                open: true,
            },
        });
    };
};

export const dismiss = (): ThunkAction<void, Store, unknown, Action> => {
    return (dispatch: ThunkDispatch<Store, unknown, Action>): void => {
        dispatch({
            type: DISMISS,
        });
    };
};
