import {Component, ReactNode} from 'react';
import {BaseProps, extractObjectFromResult, withAll} from '../../../../../base';
import {Item} from '../../../../organisms';
import {GrupoDto} from '../../../../../api/types';
import {Profile} from '../Profile';
import {EditState} from '../../types';

class EditContainer extends Component<BaseProps, EditState> {
    public state: Readonly<EditState> = {};

    public async componentDidMount(): Promise<void> {
        await this.loadGroup();
    }

    public render(): ReactNode {
        return (
            <Profile
                title={'Editar grupo disciplinar'}
                groupProfile={this.state.group}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
            />
        );
    }

    private _onSubmit = async (result: Array<Item<string | boolean | Array<string> | number>>): Promise<void> => {
        const grupoDto: GrupoDto = extractObjectFromResult<string | boolean | Array<string> | number, GrupoDto>(result);
        this.setState({
            group: grupoDto,
        });

        if (this.props.session &&
            this.props.match.params.groupId &&
            await this.props.group?.update?.(this.props.match.params.groupId, grupoDto)
        ) {
            this.props.history.goBack();
        }
    };

    private _onCancel = (): void => {
        this.props.history.goBack();
    };

    private loadGroup = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.groupId) {
            const group: GrupoDto | undefined = await this.props.group?.get?.(this.props.match.params.groupId);
            this.setState({group});
        }
    };
}

export default withAll(EditContainer);
