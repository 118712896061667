import {Route} from '../types';
import {LockOutlined} from '@material-ui/icons';
import {BaseProps, Roles} from '../../base';
import {LockPaths} from './lockPaths';
import {Lock, Set} from '../../components/pages/Lock';
import {ReactElement} from 'react';

export const lockPaths: Array<Route<BaseProps>> = [
    {
        key: LockPaths.HOME,
        name: 'Bloquear',
        path: '/lock',
        icon: LockOutlined,
        role: Roles.ROLE_ADMIN,
        exact: false,
        hidden: false,
        component: (): ReactElement => <Lock/>,
    },
    {
        key: LockPaths.SET,
        parent: LockPaths.HOME,
        path: '/',
        role: Roles.ROLE_ADMIN,
        exact: true,
        hidden: true,
        component: Set,
    },
];

