import {
    DeleteException,
    GetException,
    IException,
    PostException,
    PutException,
    SigninException,
    SignoutException,
    UnauthorizedException,
    UriException,
} from '../exceptions';
import {Action, ContentType, HttpStatus, LockOption, Scheme, Type} from './enums';
import {routes} from './constants';
import {
    AlunoDto,
    AlunoTurmaDto,
    AnoDto,
    AnoLetivoDto,
    AvaliacaoAlunoDto,
    AvaliacaoDto,
    CicloDto,
    DatetimeDto,
    DisciplinaDto,
    DisciplinaIdDto,
    DominioDto,
    EscolaDto,
    GrupoDto,
    NotasDto,
    ParametroDto,
    ParametroTurmaProfessorDto,
    PeriodoDto,
    ProfessorDto,
    ProfessorIdDto, ResetDto,
    SessionDto,
    TurmaDto,
    UserDto,
} from './types';

export const DEFAULT_ERROR_CODE = 0;

export class GesAvaliaApi {
    private _abortController: AbortController;
    private readonly _type: Scheme | null;
    private readonly _accessToken: string | null;

    /**
     * Class constructor for the GesAvaliaApi
     * @constructor
     *
     * @param {Scheme | null} type - the type of authentication to use
     * @param {string | null} accessToken - the API access token
     *
     * @returns {GesAvaliaApi} - A new instance of the GesAvaliaApi
     */
    public constructor(type?: Scheme, accessToken?: string) {
        this._abortController = new AbortController();
        this._type = type ?? null;
        this._accessToken = accessToken ?? null;
    }

    /**
     * Builds the necessary headers for the API calls made by this class
     * @function
     *
     * @param type {string | null} - The type of authorization
     * @param token {string | null} - The token
     * @param contentType {ContentType} - The type of content. Defaults to 'application/json'
     *
     * @returns {Record} - The headers
     * @private
     */
    private _buildHeaders(type: string | null, token: string | null, contentType: ContentType = ContentType.JSON): Record<string, string> {
        const result: { [key: string]: string } = {
            'Content-Type': contentType,
            'Accept': 'application/json',
        };
        if (type) {
            result.Authorization = `${type} ${token}`;
        }

        if (contentType === ContentType.FORM_DATA) {
            delete result['Content-Type'];
        }

        return result as Record<string, string>;
    }

    /**
     * Builds the necessary content for the API calls made by this class
     *
     * @param method {Action} - The method call
     * @param headers {Record} - The provided headers
     * @param body {Record} - The optional body
     *
     * @returns {Record} - The request content
     * @private
     */
    private _buildContent(method: Action, headers: Record<string, unknown>, body?: Record<string, unknown> | FormData | null): Record<string, unknown> {
        const parsedBody = body !== null ? body instanceof FormData ? body : JSON.stringify(body) : null;
        return {
            mode: 'cors',
            method: method,
            headers: headers,
            body: parsedBody,
            signal: this._abortController.signal,
        };
    }

    /**
     * Generic function used to perform all fetch requests.
     *
     * @param {string} endpoint - The endpoint to be called
     * @param {Action} method - The called action method
     * @param {Scheme | null} type - The type of authentication, if applicable
     * @param {string | null} token - The authentication token, if applicable
     * @param {Record} body - The body, if any
     * @param contentType {ContentType} - The content type. Defaults to 'application/json'
     *
     * @returns {Promise} - The result of the fetch request, as a promise to be resolved
     */
    public async fetchRequest<T extends Record<string, unknown> | Array<Record<string, unknown>>>(endpoint: string, method: Action, type: Scheme | null, token: string | null, body?: Record<string, unknown> | FormData | null, contentType: ContentType = ContentType.JSON): Promise<T> {
        const response: Response = await fetch(endpoint, this._buildContent(method, this._buildHeaders(type, token, contentType), body));
        if (response.ok) {
            let result: T;

            if (response.status === HttpStatus.NO_CONTENT) {
                result = {} as T;
            } else {
                result = await response.json() as T;
            }

            return result;
        } else {
            throw new UriException(response.statusText, response.status);
        }
    }

    /**
     * Aborts any pending request
     */
    public abort(): void {
        this._abortController.abort();
    }

    public handleErr(err: unknown | Error | UriException, Exception: IException, message: string): UriException {
        if (err instanceof UriException) {
            switch (err.getCode()) {
                case HttpStatus.UNAUTHORIZED:
                    return new UnauthorizedException(`${message}, pois a sua autenticação expirou. Por favor autentique-se novamente.`, err.getCode());
                case HttpStatus.FORBIDDEN:
                    return new UnauthorizedException(`${message}, pois não tem acesso ao recurso solicitado. Contacte o administrador para mais informações.`, err.getCode());
                default:
                    return new Exception(`${message}. Por favor verifique se os dados fornecidos são válidos`, err.getCode());
            }
        }
        return new Exception(`${message}. Por favor tente mais tarde`, DEFAULT_ERROR_CODE);
    }

    /**
     * Performs the sign in operation
     *
     * @param username {string} - The username to use on sign in
     * @param password {string} - The password for sign in
     *
     * @return {Promise<SessionDto>} - A promise that resolves to the UserSessionDto object
     */
    public async login(username: string, password: string): Promise<SessionDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.login}`;
            return await this.fetchRequest<SessionDto>(endpoint, Action.GET, Scheme.Basic, btoa(`${username}:${password}`));
        } catch (err) {
            throw this.handleErr(err, SigninException, 'Não foi possível iniciar sessão');
        }
    }

    public async logout(): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.logout}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, SignoutException, 'Não foi possível terminar sessão');
        }
    }

    public async studentClassCreate(alunoTurmaDto: AlunoTurmaDto): Promise<AlunoTurmaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentClasses}`;
            return await this.fetchRequest<AlunoTurmaDto>(endpoint, Action.POST, this._type, this._accessToken, alunoTurmaDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível associar o aluno à turma');
        }
    }

    public async studentClassUpdate(id: string, alunoTurmaDto: AlunoTurmaDto): Promise<AlunoTurmaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentClasses}/${id}`;
            return await this.fetchRequest<AlunoTurmaDto>(endpoint, Action.PUT, this._type, this._accessToken, alunoTurmaDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível atualizar o aluno da turma');
        }
    }

    public async studentClassDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentClasses}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o aluno da turma');
        }
    }

    public async studentClassList(): Promise<Array<AlunoTurmaDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentClasses}?order[numero]=asc`;
            return await this.fetchRequest<Array<AlunoTurmaDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a lista de alunos da turma');
        }
    }

    public async studentClassListByClass(id: string): Promise<Array<AlunoTurmaDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentClassesByClass}`.replace(':id', id);
            return await this.fetchRequest<Array<AlunoTurmaDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a lista de alunos da turma');
        }
    }

    public async studentCreate(alunoDto: AlunoDto): Promise<AlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.students}`;
            return await this.fetchRequest<AlunoDto>(endpoint, Action.POST, this._type, this._accessToken, alunoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o aluno indicado');
        }
    }

    public async studentGet(id: string): Promise<AlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.students}/${id}`;
            return await this.fetchRequest<AlunoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o aluno indicado');
        }
    }

    public async studentUpdate(id: string, alunoDto: AlunoDto): Promise<AlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.students}/${id}`;
            return await this.fetchRequest<AlunoDto>(endpoint, Action.PUT, this._type, this._accessToken, alunoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o perfil de aluno indicado');
        }
    }

    public async studentDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.students}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o aluno');
        }
    }

    public async studentList(): Promise<Array<AlunoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.students}?order[nome]=asc`;
            return await this.fetchRequest<Array<AlunoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a lista de alunos');
        }
    }

    public async schoolYearCreate(anoLetivoDto: AnoLetivoDto): Promise<AnoLetivoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolYears}`;
            return await this.fetchRequest<AnoLetivoDto>(endpoint, Action.POST, this._type, this._accessToken, anoLetivoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o ano letivo indicado');
        }
    }

    public async schoolYearGet(id: string): Promise<AnoLetivoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolYears}/${id}`;
            return await this.fetchRequest<AnoLetivoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o ano letivo indicado');
        }
    }

    public async schoolYearUpdate(id: string, anoLetivoDto: AnoLetivoDto): Promise<AnoLetivoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolYears}/${id}`;
            return await this.fetchRequest<AnoLetivoDto>(endpoint, Action.PUT, this._type, this._accessToken, anoLetivoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o ano letivo indicado');
        }
    }

    public async schoolYearDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolYears}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o ano letivo indicado');
        }
    }

    public async schoolYearList(): Promise<Array<AnoLetivoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolYears}?order[nome]=asc`;
            return await this.fetchRequest<Array<AnoLetivoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a lista de anos letivos');
        }
    }

    public async yearCreate(anoDto: AnoDto): Promise<AnoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.years}`;
            return await this.fetchRequest<AnoDto>(endpoint, Action.POST, this._type, this._accessToken, anoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o ano indicado');
        }
    }

    public async yearGet(id: string): Promise<AnoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.years}/${id}`;
            return await this.fetchRequest<AnoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o ano indicado');
        }
    }

    public async yearUpdate(id: string, anoDto: AnoDto): Promise<AnoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.years}/${id}`;
            return await this.fetchRequest<AnoDto>(endpoint, Action.PUT, this._type, this._accessToken, anoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o ano indicado');
        }
    }

    public async yearDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.years}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o ano indicado');
        }
    }

    public async yearList(): Promise<Array<AnoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.years}?order[nome]=asc`;
            return await this.fetchRequest<Array<AnoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de anos');
        }
    }

    public async studentEvaluationCreate(avaliacaoAlunoDto: AvaliacaoAlunoDto): Promise<AvaliacaoAlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}`;
            return await this.fetchRequest<AvaliacaoAlunoDto>(endpoint, Action.POST, this._type, this._accessToken, avaliacaoAlunoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar a avaliação de aluno indicada');
        }
    }

    public async studentEvaluationGet(id: string): Promise<AvaliacaoAlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}/${id}`;
            return await this.fetchRequest<AvaliacaoAlunoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a avaliação de aluno indicada');
        }
    }

    public async studentEvaluationUpdate(id: string, avaliacaoAlunoDto: AvaliacaoAlunoDto): Promise<AvaliacaoAlunoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}/${id}`;
            return await this.fetchRequest<AvaliacaoAlunoDto>(endpoint, Action.PUT, this._type, this._accessToken, avaliacaoAlunoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar a avaliação de aluno indicada');
        }
    }

    public async studentEvaluationDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a avaliação de aluno indicada');
        }
    }

    public async studentEvaluationList(): Promise<Array<AvaliacaoAlunoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}`;
            return await this.fetchRequest<Array<AvaliacaoAlunoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de avaliações');
        }
    }

    public async studentEvaluationsLock(period: DatetimeDto, lockOption: LockOption): Promise<Array<AvaliacaoAlunoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.studentEvaluations}/${lockOption.toLowerCase()}`;
            return await this.fetchRequest<Array<AvaliacaoAlunoDto>>(endpoint, Action.GET, this._type, this._accessToken, period);
        } catch (err) {
            throw this.handleErr(err, GetException, `Não foi possível ${lockOption.toLowerCase()} as avaliações para o período indicado`);
        }
    }

    public async evaluationCreate(avaliacaoDto: AvaliacaoDto): Promise<AvaliacaoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}`;
            return await this.fetchRequest<AvaliacaoDto>(endpoint, Action.POST, this._type, this._accessToken, avaliacaoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar a avaliação indicada');
        }
    }

    public async evaluationGet(id: string): Promise<AvaliacaoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}/${id}`;
            return await this.fetchRequest<AvaliacaoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a avaliação indicada');
        }
    }

    public async evaluationUpdate(id: string, avaliacaoDto: AvaliacaoDto): Promise<AvaliacaoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}/${id}`;
            return await this.fetchRequest<AvaliacaoDto>(endpoint, Action.PUT, this._type, this._accessToken, avaliacaoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar a avaliação indicada');
        }
    }

    public async evaluationDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a avaliação indicada');
        }
    }

    public async evaluationList(): Promise<Array<AvaliacaoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}`;
            return await this.fetchRequest<Array<AvaliacaoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de avaliações');
        }
    }

    public async evaluationStudentList(id: string): Promise<Array<AlunoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluationStudents}`.replace(':id', id);
            return await this.fetchRequest<Array<AlunoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de avaliações');
        }
    }

    public async evaluationParameterList(id: string): Promise<Array<ParametroDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluationParameters}`.replace(':id', id);
            return await this.fetchRequest<Array<ParametroDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de parâmetros');
        }
    }

    public async evaluationStudentEvaluationList(id: string): Promise<Array<AvaliacaoAlunoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluationStudentEvaluations}`.replace(':id', id);
            return await this.fetchRequest<Array<AvaliacaoAlunoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de avaliações');
        }
    }

    public async evaluationGrades(id: string): Promise<NotasDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluationGrades}`.replace(':id', id);
            return await this.fetchRequest<NotasDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a informação solicitada');
        }
    }

    public async evaluationLock(id: string, lockOption: LockOption): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.evaluations}/${id}/${lockOption.toLowerCase()}`;
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, {});
        } catch (err) {
            throw this.handleErr(err, PostException, `Não foi possível ${lockOption.toLowerCase()} a avaliação indicada`);
        }
    }

    public async cycleCreate(cicloDto: CicloDto): Promise<CicloDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.cycles}`;
            return await this.fetchRequest<CicloDto>(endpoint, Action.POST, this._type, this._accessToken, cicloDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o ciclo indicado');
        }
    }

    public async cycleGet(id: string): Promise<CicloDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.cycles}/${id}`;
            return await this.fetchRequest<CicloDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o ciclo indicado');
        }
    }

    public async cycleUpdate(id: string, cicloDto: CicloDto): Promise<CicloDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.cycles}/${id}`;
            return await this.fetchRequest<CicloDto>(endpoint, Action.PUT, this._type, this._accessToken, cicloDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o ciclo indicado');
        }
    }

    public async cycleDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.cycles}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o ciclo indicado');
        }
    }

    public async cycleList(): Promise<Array<CicloDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.cycles}?order[nome]=asc`;
            return await this.fetchRequest<Array<CicloDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de ciclos');
        }
    }

    public async domainCreate(dominioDto: DominioDto): Promise<DominioDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.domains}`;
            return await this.fetchRequest<DominioDto>(endpoint, Action.POST, this._type, this._accessToken, dominioDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o dominio indicado');
        }
    }

    public async domainGet(id: string): Promise<DominioDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.domains}/${id}`;
            return await this.fetchRequest<DominioDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o dominio indicado');
        }
    }

    public async domainUpdate(id: string, dominioDto: DominioDto): Promise<DominioDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.domains}/${id}`;
            return await this.fetchRequest<DominioDto>(endpoint, Action.PUT, this._type, this._accessToken, dominioDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o dominio indicado');
        }
    }

    public async domainDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.domains}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o dominio indicado');
        }
    }

    public async domainList(): Promise<Array<DominioDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.domains}?order[nome]=asc`;
            return await this.fetchRequest<Array<DominioDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de domínios');
        }
    }

    public async periodCreate(periodoDto: PeriodoDto): Promise<PeriodoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.periods}`;
            return await this.fetchRequest<PeriodoDto>(endpoint, Action.POST, this._type, this._accessToken, periodoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o periodo indicado');
        }
    }

    public async periodGet(id: string): Promise<PeriodoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.periods}/${id}`;
            return await this.fetchRequest<PeriodoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o periodo indicado');
        }
    }

    public async periodUpdate(id: string, periodoDto: PeriodoDto): Promise<PeriodoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.periods}/${id}`;
            return await this.fetchRequest<PeriodoDto>(endpoint, Action.PUT, this._type, this._accessToken, periodoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o periodo indicado');
        }
    }

    public async periodDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.periods}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o periodo indicado');
        }
    }

    public async periodList(): Promise<Array<PeriodoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.periods}?order[nome]=asc`;
            return await this.fetchRequest<Array<PeriodoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de períodos');
        }
    }

    public async teacherCreate(professorDto: ProfessorDto): Promise<ProfessorDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.teachers}`;
            return await this.fetchRequest<ProfessorDto>(endpoint, Action.POST, this._type, this._accessToken, professorDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o professor indicado');
        }
    }

    public async teacherGet(id: string): Promise<ProfessorDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.teachers}/${id}`;
            return await this.fetchRequest<ProfessorDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o professor indicado');
        }
    }

    public async teacherUpdate(id: string, professorDto: ProfessorDto): Promise<ProfessorDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.teachers}/${id}`;
            return await this.fetchRequest<ProfessorDto>(endpoint, Action.PUT, this._type, this._accessToken, professorDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o professor indicado');
        }
    }

    public async teacherDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.teachers}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o professor indicado');
        }
    }

    public async teacherList(): Promise<Array<ProfessorDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.teachers}?order[nome]=asc`;
            return await this.fetchRequest<Array<ProfessorDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de professores');
        }
    }

    public async disciplineCreate(disciplinaDto: DisciplinaDto): Promise<DisciplinaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.disciplines}`;
            return await this.fetchRequest<DisciplinaDto>(endpoint, Action.POST, this._type, this._accessToken, disciplinaDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar a disciplina indicada');
        }
    }

    public async disciplineGet(id: string): Promise<DisciplinaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.disciplines}/${id}`;
            return await this.fetchRequest<DisciplinaDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a disciplina indicada');
        }
    }

    public async disciplineUpdate(id: string, disciplinaDto: DisciplinaDto): Promise<DisciplinaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.disciplines}/${id}`;
            return await this.fetchRequest<DisciplinaDto>(endpoint, Action.PUT, this._type, this._accessToken, disciplinaDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar a disciplina indicada');
        }
    }

    public async disciplineDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.disciplines}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a disciplina indicada');
        }
    }

    public async disciplineList(): Promise<Array<DisciplinaDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.disciplines}?order[nome]=asc`;
            return await this.fetchRequest<Array<DisciplinaDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de disciplinas');
        }
    }

    public async schoolCreate(escolaDto: EscolaDto): Promise<EscolaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schools}`;
            return await this.fetchRequest<EscolaDto>(endpoint, Action.POST, this._type, this._accessToken, escolaDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar a escola indicada');
        }
    }

    public async schoolGet(id: string): Promise<EscolaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schools}/${id}`;
            return await this.fetchRequest<EscolaDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a escola indicada');
        }
    }

    public async schoolUpdate(id: string, escolaDto: EscolaDto): Promise<EscolaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schools}/${id}`;
            return await this.fetchRequest<EscolaDto>(endpoint, Action.PUT, this._type, this._accessToken, escolaDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar a escola indicada');
        }
    }

    public async schoolDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schools}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a escola indicada');
        }
    }

    public async schoolList(): Promise<Array<EscolaDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schools}`;
            return await this.fetchRequest<Array<EscolaDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de escolas');
        }
    }

    public async schoolDataImport(id: string, data: FormData, type: Type): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.schoolImport}/${type.toLowerCase()}`.replace(':id', id);
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, data, ContentType.FORM_DATA);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível importar a informação indicada');
        }
    }

    public async groupCreate(grupoDto: GrupoDto): Promise<GrupoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groups}`;
            return await this.fetchRequest<GrupoDto>(endpoint, Action.POST, this._type, this._accessToken, grupoDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o grupo indicado');
        }
    }

    public async groupGet(id: string): Promise<GrupoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groups}/${id}`;
            return await this.fetchRequest<GrupoDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o grupo indicado');
        }
    }

    public async groupUpdate(id: string, grupoDto: GrupoDto): Promise<GrupoDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groups}/${id}`;
            return await this.fetchRequest<GrupoDto>(endpoint, Action.PUT, this._type, this._accessToken, grupoDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o grupo indicado');
        }
    }

    public async groupDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groups}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o grupo indicado');
        }
    }

    public async groupList(): Promise<Array<GrupoDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groups}?order[nome]=asc`;
            return await this.fetchRequest<Array<GrupoDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de grupos');
        }
    }

    public async groupTeacherCreate(id: string, professorIdDto: ProfessorIdDto): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groupTeachers}`.replace(':id', id);
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, professorIdDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível associar o professor indicado');
        }
    }

    public async groupTeacherDelete(groupId: string, teacherId: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.groupTeachers}/${teacherId}`.replace(':id', groupId);
            await this.fetchRequest(endpoint, Action.PUT, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o professor associado');
        }
    }

    public async classCreate(turmaDto: TurmaDto): Promise<TurmaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classes}`;
            return await this.fetchRequest<TurmaDto>(endpoint, Action.POST, this._type, this._accessToken, turmaDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar a turma indicada');
        }
    }

    public async classGet(id: string): Promise<TurmaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classes}/${id}`;
            return await this.fetchRequest<TurmaDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a turma indicada');
        }
    }

    public async classUpdate(id: string, turmaDto: TurmaDto): Promise<TurmaDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classes}/${id}`;
            return await this.fetchRequest<TurmaDto>(endpoint, Action.PUT, this._type, this._accessToken, turmaDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar a turma indicada');
        }
    }

    public async classDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classes}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a turma indicada');
        }
    }

    public async classList(): Promise<Array<TurmaDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classes}?order[nome]=asc`;
            return await this.fetchRequest<Array<TurmaDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de turmas');
        }
    }

    public async classTeacherCreate(id: string, professorIdDto: ProfessorIdDto): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classTeachers}`.replace(':id', id);
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, professorIdDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível associar o professor indicado');
        }
    }

    public async classTeacherDelete(classId: string, teacherId: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classTeachers}/${teacherId}`.replace(':id', classId);
            await this.fetchRequest(endpoint, Action.PUT, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o professor associado');
        }
    }

    public async classDisciplineCreate(id: string, disciplinaIdDto: DisciplinaIdDto): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classDisciplines}`.replace(':id', id);
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, disciplinaIdDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível associar a disciplina indicada');
        }
    }

    public async classDisciplineDelete(classId: string, disciplineId: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classDisciplines}/${disciplineId}`.replace(':id', classId);
            await this.fetchRequest(endpoint, Action.PUT, this._type, this._accessToken, {});
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar a disciplina indicada');
        }
    }

    public async classStudentDelete(classId: string, studentId: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classStudents}/${studentId}`.replace(':id', classId);
            await this.fetchRequest(endpoint, Action.PUT, this._type, this._accessToken, {});
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o aluno associado');
        }
    }

    public async classParameterList(id: string): Promise<Array<ParametroDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classParameters}`.replace(':id', id);
            return await this.fetchRequest<Array<ParametroDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de parâmetros');
        }
    }

    public async classParameterListByTeacher(id: string): Promise<Array<ParametroDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classTeacherParameters}`.replace(':id', id);
            return await this.fetchRequest<Array<ParametroDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a informação solicitada');
        }
    }

    public async classParameterClassTeacherListByTeacher(id: string): Promise<Array<ParametroTurmaProfessorDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.classTeacherParameterClassTeachers}`.replace(':id', id);
            return await this.fetchRequest<Array<ParametroTurmaProfessorDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a informação solicitada');
        }
    }

    public async parameterCreate(parametroDto: ParametroDto): Promise<ParametroDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameters}`;
            return await this.fetchRequest<ParametroDto>(endpoint, Action.POST, this._type, this._accessToken, parametroDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o parametro indicado');
        }
    }

    public async parameterGet(id: string): Promise<ParametroDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameters}/${id}`;
            return await this.fetchRequest<ParametroDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o parametro indicado');
        }
    }

    public async parameterUpdate(id: string, parametroDto: ParametroDto): Promise<ParametroDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameters}/${id}`;
            return await this.fetchRequest<ParametroDto>(endpoint, Action.PUT, this._type, this._accessToken, parametroDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o parametro indicado');
        }
    }

    public async parameterDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameters}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o parametro indicado');
        }
    }

    public async parameterList(): Promise<Array<ParametroDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameters}?order[nome]=asc`;
            return await this.fetchRequest<Array<ParametroDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de parâmetros');
        }
    }

    public async parameterClassTeacherCreate(parametroTurmaProfessorDto: ParametroTurmaProfessorDto): Promise<ParametroTurmaProfessorDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameterClassTeachers}`;
            return await this.fetchRequest<ParametroTurmaProfessorDto>(endpoint, Action.POST, this._type, this._accessToken, parametroTurmaProfessorDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível associar o parâmetro indicaodo');
        }
    }

    public async parameterClassTeacherGet(id: string): Promise<ParametroTurmaProfessorDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameterClassTeachers}/${id}`;
            return await this.fetchRequest<ParametroTurmaProfessorDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o parâmetro indicado');
        }
    }

    public async parameterClassTeacherDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.parameterClassTeachers}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o parâmetro indicado');
        }
    }

    public async userCreate(userDto: UserDto): Promise<UserDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.users}`;
            return await this.fetchRequest<UserDto>(endpoint, Action.POST, this._type, this._accessToken, userDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível criar o utilizador indicado');
        }
    }

    public async userGet(id: string): Promise<UserDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.users}/${id}`;
            return await this.fetchRequest<UserDto>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter o utilizador indicado');
        }
    }

    public async userUpdate(id: string, userDto: UserDto): Promise<UserDto> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.users}/${id}`;
            return await this.fetchRequest<UserDto>(endpoint, Action.PUT, this._type, this._accessToken, userDto);
        } catch (err) {
            throw this.handleErr(err, PutException, 'Não foi possível atualizar o utilizador indicado');
        }
    }

    public async userDelete(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.users}/${id}`;
            await this.fetchRequest(endpoint, Action.DELETE, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, DeleteException, 'Não foi possível eliminar o utilizador indicado');
        }
    }

    public async userList(): Promise<Array<UserDto>> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.users}?order[username]=asc`;
            return await this.fetchRequest<Array<UserDto>>(endpoint, Action.GET, this._type, this._accessToken);
        } catch (err) {
            throw this.handleErr(err, GetException, 'Não foi possível obter a listagem de utilizadores');
        }
    }

    public async userResendConfirmationEmail(id: string): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.userResendConfirmation}`.replace(':id', id);
            await this.fetchRequest(endpoint, Action.POST, this._type, this._accessToken, {});
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível reenviar o email de confirmação');
        }
    }

    public async resetRequest(resetDto: ResetDto): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.resetRequest}`;
            await this.fetchRequest(endpoint, Action.POST, null, null, resetDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível solicitar o código de confirmação');
        }
    }

    public async resetConfirm(resetDto: ResetDto): Promise<void> {
        try {
            const endpoint = `${process.env.REACT_APP_API_URL}${routes.resetConfirm}`;
            await this.fetchRequest(endpoint, Action.POST, null, null, resetDto);
        } catch (err) {
            throw this.handleErr(err, PostException, 'Não foi possível confirmar a reposição da palavra passe');
        }
    }
}
