import {Component, ReactNode} from 'react';
import {BaseProps, withAll} from '../../../../../base';
import {ManageState} from '../../types';
import {Column, DataGrid, isSelectable, Selectable} from '../../../../organisms';
import {ChangeSet, EditingState, TableColumnWidthInfo} from '@devexpress/dx-react-grid';
import {AlunoDto, AlunoTurmaDto} from '../../../../../api/types';
import {routes} from '../../../../../api/constants';

class ManageContainer extends Component<BaseProps, ManageState> {
    public state: Readonly<ManageState> = {
        alertOpen: false,
    };

    public async componentDidMount(): Promise<void> {
        await this._setSelectItems();
        await this._setClassStudentList();
    }

    public componentDidUpdate(_prevProps: Readonly<BaseProps>, prevState: Readonly<ManageState>): void {
        if (prevState.studentClasses !== this.state.studentClasses) {
            this._setRowsData();
        }
    }

    public render(): ReactNode {
        return (
            <DataGrid
                title={'Lista de alunos'}
                gridHeaderColor={'primary'}
                columns={this._getColumns()}
                getRowId={this._getRowId}
                columnExtensions={this._getColumnExtensions()}
                defaultColumnWidths={this._getDefaultColumnWidths()}
                numberColumns={['numero']}
                rows={this.state.rows}
                availableRowValues={this.state.availableRowValues}
                initialSortColumn={'numero'}
                hiddenColumns={['id']}
                onReturn={this._onReturn}
                onCommitChanges={this._onCommitChanges}
            />
        );
    }

    private _getColumns = (): Array<Column> => {
        return [
            {
                name: 'id',
                title: 'ID',
            },
            {
                name: 'numero',
                title: 'Número',
                required: true,
            },
            {
                name: 'aluno',
                title: 'Aluno',
                required: true,
                getCellValue: (row: { [key: string]: Selectable }): string | number | boolean => {
                    return row.aluno?.value;
                },
            },
        ];
    };

    private _getColumnExtensions = (): Array<EditingState.ColumnExtension> => {
        return [
            {
                columnName: 'id',
            },
            {
                columnName: 'numero',
            },
            {
                columnName: 'aluno',
                createRowChange: (_row: { [key: string]: Selectable }, value: Selectable): { [key: string]: Selectable } => ({
                    aluno: {
                        ...value,
                    },
                }),
            },
        ];
    };

    private _getDefaultColumnWidths = (): Array<TableColumnWidthInfo> => {
        return [
            {
                columnName: 'id',
                width: 0,
            },
            {
                columnName: 'numero',
                width: '15%',
            },
            {
                columnName: 'aluno',
                width: '50%',
            },
        ];
    };

    private _setClassStudentList = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const results = await Promise.all([
                this.props.studentClass?.listByClass?.(this.props.match.params.classId),
            ]);
            this.setState({studentClasses: results[0] ?? []});
        }
    };

    private _setSelectItems = async (): Promise<void> => {
        if (this.props.session && this.props.match.params.classId) {
            const results = await Promise.all([
                this.props.student?.list?.(),
            ]);

            const studentOptions: Array<Selectable> = results?.[0]?.map((student: AlunoDto) => {
                return {
                    key: `${routes.students}/${student.id ?? 0}`,
                    value: student.nome ?? '',
                };
            }) ?? [];

            this.setState({
                availableRowValues: {
                    aluno: studentOptions,
                },
            });
        }
    };

    private _setRowsData = (): void => {
        const studentClasses = this.state.studentClasses;
        const availableRowValues = this.state.availableRowValues;

        this.setState({
            rows: studentClasses?.map(studentClass => {
                return {
                    id: studentClass.id ?? '',
                    numero: studentClass.numero ?? 0,
                    aluno: availableRowValues?.aluno?.find(v => v.key === studentClass.aluno) ?? null,
                };
            }),
        });
    };

    private _getRowId = (row: { [key: string]: string | number | boolean | Record<string, unknown> | null }): number | string => {
        return 'id' in row ? row.id?.toString() ?? -1 : -1;
    };

    private _onCommitChanges = async (changes: ChangeSet): Promise<void> => {
        await this._handleDelete(changes.deleted);
        await this._handleChanged(changes.changed);
        await this._handleAdded(changes.added);
    };

    private _handleAdded = async (data?: ReadonlyArray<{ [key: string]: string | number | boolean | Selectable | null }>): Promise<void> => {
        const classId = this.props.match.params.classId;

        if (classId && data) {
            const rows = this.state.rows;
            this.setState({
                rows: [
                    ...rows ?? [],
                    ...data.map(d => {
                        return {
                            id: 'not-available',
                            numero: d.numero ?? 0,
                            aluno: d.aluno,
                        };
                    }),
                ],
            });

            const result = await Promise.all(data.map(d => {
                if (d.numero && isSelectable(d.aluno)) {
                    return this.props.studentClass?.create?.({
                        numero: parseInt(d.numero.toString(), 10),
                        aluno: d.aluno.key.toString(),
                        turma: `${routes.classes}/${classId}`,
                    });
                }
                return undefined;
            }));

            if (result.includes(undefined)) {
                this.setState({
                    rows: [...rows ?? []],
                });
            } else {
                this.setState({
                    studentClasses: [
                        ...this.state.studentClasses ?? [],
                        ...result as Array<AlunoTurmaDto>,
                    ],
                });
            }
        }
    };

    private _handleChanged = async (data?: { [key: string]: { [key: string]: string | number | boolean | Selectable } }): Promise<void> => {
        if (data) {
            const changedRows: Array<{ [key: string]: string | number | boolean | Selectable | null }> = [];
            const rows = this.state.rows ?? [];
            const updateRowsIds: Array<string> = [];

            Object.keys(data).forEach((key: string) => {
                const row = rows.find(r => r.id === key) ?? {};
                updateRowsIds.push(key);

                Object.keys(data[key]).forEach((column: string) => {
                    row[column] = data[key][column];
                });
                changedRows.push(row);
            });

            this.setState({
                rows: [...rows],
            });

            const result = await Promise.all(changedRows.map(d => {
                if (d.id && d.numero && isSelectable(d.aluno)) {
                    return this.props.studentClass?.update?.(d.id.toString(), {
                        numero: parseInt(d.numero.toString(), 10),
                        aluno: d.aluno.key.toString(),
                    });
                }
                return undefined;
            }));

            if (result.includes(undefined)) {
                this.setState({
                    rows: [...rows ?? []],
                });
            } else {
                this.setState({
                    studentClasses: [
                        ...(this.state.studentClasses ?? []).filter(e => e.id && !updateRowsIds.includes(e.id)),
                        ...result as Array<AlunoTurmaDto>,
                    ],
                });
            }
        }
    };

    private _handleDelete = async (data?: ReadonlyArray<number | string>): Promise<void> => {
        if (data) {
            const studentClasses = this.state.studentClasses;
            this.setState({
                studentClasses: [...studentClasses?.filter(s => s.id && !data?.includes(s.id)) ?? []],
            });

            const result = await Promise.all(data.map(id => this.props.studentClass?.delete?.(id.toString())));
            if (result.includes(undefined)) {
                this.setState({
                    studentClasses: [...studentClasses ?? []],
                });
            }
        }
    };

    private _onReturn = (): void => {
        this.props.history.goBack();
    };

}

export default withAll(ManageContainer);
